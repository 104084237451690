import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Slider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from "@mui/icons-material";

import {
  FormatAlignJustify,
  VerticalAlignBottom,
  VerticalAlignCenter,
  VerticalAlignTop,
  Lock,
  LockOpen,
} from "@mui/icons-material";

import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import { CanvasContext } from "../CanvasContext";
import TextProperties from "./TextProperties";
import TextTransformationComponent from "./TextTransformationComponent";
import TextShadingComponent from "./TextShadingComponent";
import TextBorderComponent from "./TextBorderComponent";
import { fabric } from "fabric";
import { backgroundRemover } from "./design-processing-tools";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button } from "@mui/material";

const ImageSettings = ({
  // currentSettings,
  onChange,
  selectedObject,
  fabricCanvasGlobal,
  setLoading,
}) => {
  const {
    artBoardSettings,
    setClippingObject,
    clippingObject,
    setSelctedObject,
    mask,
    setMask,
    clippingCheckbox,
    setClippingCheckbox,
    lockCheckBox,
    setLockCheckBox,
    maskArray,
    setMaskArray,
    selectedMaskId,
    setSelectedMaskId,
  } = useContext(CanvasContext);
  const [textSettings, setTextSettings] = React.useState({
    // fontSize: selectedObject.fontSize || 16,
    // fontFamily: selectedObject.fontFamily || "Arial",
    // fontWeight: selectedObject.fontWeight || "normal",
    backgroundColor: selectedObject.backgroundColor || "",
    // textAlign: selectedObject.textAlign || "left",
    stroke: selectedObject.stroke || "",
    strokeWidth: selectedObject.strokeWidth || 0,
    opacity: (selectedObject.opacity || 1) * 100,
    width: selectedObject.getScaledWidth() || 0,
    height: selectedObject.getScaledHeight() || 0,
    // fontStyle: selectedObject.fontStyle || "normal",
    // underline: selectedObject.underline || false,
    // charSpacing: selectedObject.charSpacing || 0,
    // lineHeight: selectedObject.lineHeight || 1,
    // fontStyle: [
    //   ...(selectedObject.fontWeight === "bold" ? ["bold"] : []),
    //   ...(selectedObject.fontStyle === "italic" ? ["italic"] : []),
    //   ...(selectedObject.underline ? ["underline"] : []),
    // ],
  });
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isLocked, setIsLocked] = useState(true);

  // Local state for input fields
  const [dimensions, setDimensions] = useState({
    width: selectedObject?.getScaledWidth() || 0,
    height: selectedObject?.getScaledHeight() || 0,
  });
  const [isChildListenerActive, setIsChildListenerActive] = useState(false);
  // const [selectedMaskId, setSelectedMaskId] = useState(null);
  // const [clippingCheckbox, setClippingCheckbox] = React.useState(
  //   selectedObject.isClippingMask ? true : false
  // );
  // const [mask, setMask] = useState(selectedObject.clipPath ? true : false);

  React.useEffect(() => {
    setTextSettings({
      //   fontSize: selectedObject.fontSize || 16,
      //   fontFamily: selectedObject.fontFamily || "Arial",
      //   fontWeight: selectedObject.fontWeight || "normal",
      backgroundColor: selectedObject.backgroundColor || "",
      //   textAlign: selectedObject.textAlign || "left",
      stroke: selectedObject.stroke || "",
      strokeWidth: selectedObject.strokeWidth || 0,
      opacity: (selectedObject.opacity || 1) * 100,
      width: selectedObject.getScaledWidth() || 0,
      height: selectedObject.getScaledHeight() || 0,
      //   fontStyle: selectedObject.fontStyle || "normal",
      //   underline: selectedObject.underline || false,
      //   charSpacing: selectedObject.charSpacing || 0,
      //   lineHeight: selectedObject.lineHeight || 1,
    });
  }, [selectedObject]);

  React.useEffect(() => {
    if (selectedObject) {
      setDimensions({
        width: selectedObject.getScaledWidth(),
        height: selectedObject.getScaledHeight(),
      });
      const strokeWidth = selectedObject.strokeWidth || 0;

      setWidth(Math.round(selectedObject.getScaledWidth() * 100) / 100);
      setHeight(Math.round(selectedObject.getScaledHeight() * 100) / 100);
      //console.log(
      //   "BREAKPOINT selectedObject.width= " + selectedObject.getScaledWidth()
      // );
      //console.log(
      //   "BREAKPOINT selectedObject.height= " + selectedObject.getScaledHeight()
      // );
      //console.log(
      //   "BREAKPOINT selectedObject.strokeWidth= " + selectedObject.strokeWidth
      // );
    }
  }, [selectedObject]);

  // Update Fabric.js object when text settings change
  const handleTextSettingsChange = (settings) => {
    if (!selectedObject) return;
    const {
      //   fontSize,
      //   fontFamily,
      //   fontWeight,
      //   fontStyle,
      backgroundColor,
      //   textAlign,
      // stroke,
      // strokeWidth,
      opacity,
      // width,
      // height,
      //   underline,
      //   charSpacing,
      //   lineHeight,
    } = settings;

    selectedObject.set({
      //   fontSize,
      //   fontFamily,
      //   fontWeight,
      //   fontStyle,
      backgroundColor,
      //   textAlign,
      // stroke,
      // strokeWidth,
      opacity: opacity / 100, // Convert percentage to decimal
      // width,
      // height,
      //   underline,
      //   charSpacing,
      //   lineHeight,
    });

    fabricCanvasGlobal.requestRenderAll();
  };
  const handleChange = (key, value) => {
    //console.log("BREAKPOINT ----key= " + key + "---value=" + value);
    // Update local state
    setTextSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  const handleInputChange = (key, value) => {
    // Update input state directly without applying it immediately to the object
    if (key === "width") {
      handleApplyDimension(key, value);
      setWidth(value);
    } else if (key === "height") {
      handleApplyDimension(key, value);
      setHeight(value);
    }
  };
  const handleApplyDimension = (key, value) => {
    if (!selectedObject || !fabricCanvasGlobal) return;

    // Get the current canvas scaling factor
    const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

    // Get the current bounding rectangle dimensions
    const boundingRect = selectedObject.getBoundingRect();
    const currentWidth = boundingRect.width / scaleFactor;
    const currentHeight = boundingRect.height / scaleFactor;

    // Define the new dimensions based on the key
    let newWidth = currentWidth;
    let newHeight = currentHeight;

    if (key === "width") {
      newWidth = value;
      if (isLocked) {
        // Adjust height proportionally if locked
        newHeight = newWidth / (currentWidth / currentHeight);
      }
    } else if (key === "height") {
      newHeight = value;
      if (isLocked) {
        // Adjust width proportionally if locked
        newWidth = newHeight * (currentWidth / currentHeight);
      }
    }

    // Calculate scale factors for both width and height
    const widthScaleFactor = newWidth / currentWidth;
    const heightScaleFactor = newHeight / currentHeight;

    // Update the object's scale
    selectedObject.set({
      scaleX: selectedObject.scaleX * widthScaleFactor,
      scaleY: isLocked
        ? selectedObject.scaleX * widthScaleFactor // Match scaleY with scaleX if locked
        : selectedObject.scaleY * heightScaleFactor, // Use independent scaleY if unlocked
    });

    // Update object's position and refresh canvas
    selectedObject.setCoords();
    fabricCanvasGlobal.requestRenderAll();
  };

  React.useEffect(() => {
    if (!fabricCanvasGlobal) return;

    let timeout;

    const handleObjectScaling = (e) => {
      //console.log("BREAKPOINT parent listener");
      if (isChildListenerActive) return; // Skip if child listener is active

      const activeObject = e.target;

      if (activeObject && activeObject.type === "image") {
        clearTimeout(timeout);

        // Debounce to avoid multiple updates
        timeout = setTimeout(() => {
          const boundingRect = activeObject.getBoundingRect();
          const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

          const updatedWidth = boundingRect.width / scaleFactor;
          const updatedHeight = boundingRect.height / scaleFactor;

          setDimensions({
            width: updatedWidth,
            height: updatedHeight,
          });
          setWidth(updatedWidth);
          setHeight(updatedHeight);

          fabricCanvasGlobal.requestRenderAll();
        }, 100); // Debounce interval (100ms)
      }
    };

    // Add the scaling event listener
    if (!isChildListenerActive)
      fabricCanvasGlobal.on("object:scaling", handleObjectScaling);

    // Cleanup on component unmount
    return () => {
      clearTimeout(timeout);
      if (!isChildListenerActive)
        fabricCanvasGlobal.off("object:scaling", handleObjectScaling);
    };
  }, [fabricCanvasGlobal, isChildListenerActive]);

  React.useEffect(() => {
    handleTextSettingsChange(textSettings);
  }, [textSettings]);

  const alignObject = (alignment) => {
    // const activeObject = fabricCanvasGlobal.getActiveObject();

    if (!selectedObject) {
      alert("No object selected!");
      return;
    }

    const canvasWidth = fabricCanvasGlobal.width;
    const canvasHeight = fabricCanvasGlobal.height;
    // //console.log("BREAKPOINT width = " + canvasWidth);
    // //console.log("BREAKPOINT height = " + canvasHeight);

    switch (alignment) {
      case "horizontal-left":
        selectedObject.left = 0; // Align to left
        break;

      case "horizontal-center":
        selectedObject.left =
          artBoardSettings.width / 2 - selectedObject.getScaledWidth() / 2; // Center horizontally
        break;

      case "horizontal-right":
        selectedObject.left =
          artBoardSettings.width - selectedObject.getScaledWidth(); // Align to right
        break;

      case "vertical-top":
        selectedObject.top = 0; // Align to top
        break;

      case "vertical-center":
        selectedObject.top =
          artBoardSettings.height / 2 - selectedObject.getScaledHeight() / 2; // Center vertically
        break;

      case "vertical-bottom":
        selectedObject.top =
          artBoardSettings.height - selectedObject.getScaledHeight(); // Align to bottom
        break;

      default:
        break;
    }

    // Update the canvas and re-render
    selectedObject.setCoords(); // Update the object's coordinates
    fabricCanvasGlobal.requestRenderAll(); // Re-render the canvas
  };

  // ✅ Handle "Make Clipping Mask" Checkbox
  const handleClippingChange = (event) => {
    // const activeObject = fabricCanvasGlobal.getActiveObject();
    // if (activeObject) {
    //   if (event.target.checked) {
    //     // ✅ Remove previous clipping mask before setting a new one
    //     // if (clippingObject) {
    //     //   clippingObject.set({ isClippingMask: false });
    //     // }

    //     // ✅ Create a new clipping object
    //     const clipObj = new fabric.Image(activeObject.getElement(), {
    //       scaleX: activeObject.scaleX,
    //       scaleY: activeObject.scaleY,
    //       left: activeObject.left,
    //       top: activeObject.top,
    //       absolutePositioned: true, // Keeps clipping position static
    //     });

    //     // ✅ Set the new clipping object
    //     activeObject.set({ isClippingMask: true });
    //     setClippingObject(clipObj);
    //   } else {
    //     // ✅ Remove clipping object
    //     // if (clippingObject) {
    //     //   clippingObject.set({ isClippingMask: false });
    //     // }
    //     setClippingObject(null);
    //     activeObject.set({ isClippingMask: false });
    //   }

    //   fabricCanvasGlobal.renderAll();
    // }

    setClippingCheckbox(event.target.checked);
  };

  // ✅ Handle Selecting an Object from Dropdown as Clipping Mask
  const handleMaskSelection = (event) => {
    // const selectedObjectId = event.target.value;
    // setSelectedMask(selectedObjectId);

    // const maskObject = clippingObject.find(
    //   (obj) => obj.id === selectedObjectId
    // );
    // const activeObject = fabricCanvasGlobal.getActiveObject();
    // if (mask) {
    //   if (clippingObject && activeObject) {
    //     // ✅ Apply the selected object as a clipping mask
    //     activeObject.clipPath = clippingObject;
    //     fabricCanvasGlobal.renderAll();
    //   }
    // } else {
    //   activeObject.clipPath = null;
    // }
    setMask(event.target.checked);
  };

  useEffect(() => {
    // const activeObject = fabricCanvasGlobal.getActiveObject();
    if (selectedObject && selectedObject.id) {
      if (clippingCheckbox) {
        // ✅ Remove previous clipping mask before setting a new one
        // if (clippingObject) {
        //   clippingObject.set({ isClippingMask: false });
        // }
        const alreadyInArray = maskArray.some(
          (item) => item.id === selectedObject.id
        );
        if (!alreadyInArray) {
          // ✅ Create a new clipping object
          const clipObj = new fabric.Image(selectedObject.getElement(), {
            scaleX: selectedObject.scaleX,
            scaleY: selectedObject.scaleY,
            left: selectedObject.left,
            top: selectedObject.top,
            absolutePositioned: true, // Keeps clipping position static
          });
          const objectId =
            selectedObject.id || selectedObject.__uid || Date.now();
          clipObj.id = objectId;

          // ✅ Set the new clipping object
          selectedObject.set({ isClippingMask: true });
          // Assign a custom id to track this object

          setClippingObject(clipObj);
          setMaskArray((prevArray) => [...prevArray, clipObj]);
          // console.log("BREAKPOINT clipObj= " + JSON.stringify(clipObj, null, 2));
          // console.log(
          //   "BREAKPOINT selectedObject= " +
          //     JSON.stringify(selectedObject, null, 2)
          // );
          // console.log("BREAKPOINT selectedObject.id= " + selectedObject.id);
        }
      } else {
        // ✅ Remove clipping object
        // if (clippingObject) {
        //   clippingObject.set({ isClippingMask: false });
        // }
        // setClippingObject(null);
        selectedObject.set({ isClippingMask: false });
        setMaskArray((prev) =>
          prev.filter((obj) => obj.id !== selectedObject.id)
        );
      }

      fabricCanvasGlobal.renderAll();
    }
  }, [clippingCheckbox]);

  useEffect(() => {
    // const activeObject = fabricCanvasGlobal.getActiveObject();
    if (mask) {
      selectedObject.set({ clipPath: clippingObject });
      // selectedObject.set({ clippingObject: clippingObject });
      // //console.log("BREAKPOINT clippingObject= " + clippingObject);
    } else {
      selectedObject.set({ clipPath: null });
      // selectedObject.set({ isclippingObject: null });
    }
    fabricCanvasGlobal.renderAll();
  }, [mask]);

  // const handleLockCheckBoxChange = () => {
  //   setLockCheckBox(!lockCheckBox);
  // };

  // useEffect(() => {
  //   if (lockCheckBox) {
  //     selectedObject.set({
  //       selectable: false, // Cannot be selected
  //       evented: false, // Ignores events like clicks or drags
  //       hasControls: false, // Hides resize/rotation controls
  //       lockMovementX: true, // Prevents horizontal movement
  //       lockMovementY: true, // Prevents vertical movement
  //       lockScalingX: true, // Prevents horizontal scaling
  //       lockScalingY: true, // Prevents vertical scaling
  //       lockRotation: true, // Prevents rotation
  //     });
  //   } else {
  //     selectedObject.set({
  //       selectable: true,
  //       evented: true,
  //       hasControls: true,
  //       lockMovementX: false,
  //       lockMovementY: false,
  //       lockScalingX: false,
  //       lockScalingY: false,
  //       lockRotation: false,
  //     });
  //   }
  // }, [lockCheckBox]);
  const handleRemoveBackground = () => {
    backgroundRemover(fabricCanvasGlobal, selectedObject, setLoading);
  };

  const handleMaskArraySelectionChange = (event) => {
    const selectedId = event.target.value;

    // Find the clip object directly from the maskArray
    const selectedClip = maskArray.find((obj) => obj.id === selectedId);

    if (selectedObject && selectedClip) {
      selectedObject.clipPath = selectedClip;
      selectedObject.dirty = true;
      fabricCanvasGlobal.requestRenderAll();
    }

    setSelectedMaskId(selectedId);
    // setMaskArray((prevArray) => [...prevArray, event.target.value]);
    // setSelectedMaskId(event.target.value.id);
    // selectedObject.set({ clipPath: event.target.value });
    // selectedObject.set({ clippingObject: clippingObject });
    // //console.log("BREAKPOINT clippingObject= " + clippingObject);

    // selectedObject.set({ clipPath: null });
    // selectedObject.set({ isclippingObject: null });

    fabricCanvasGlobal.renderAll();
  };

  useEffect(() => {
    const selectedClip = maskArray.find((obj) => obj.id === selectedMaskId);
    if (selectedObject && selectedClip) {
      selectedObject.clipPath = selectedClip;
      selectedObject.dirty = true;
      fabricCanvasGlobal.requestRenderAll();
    }
  }, [selectedMaskId]);

  return (
    <Box
      sx={{
        padding: 2,
        width: "400px",
        maxHeight: "60vh",
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
        overflowY: "auto",
        overflowX: "hidden",
        zIndex: 1,
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Text Settings
      </Typography>

      {/* Alignment Controls */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: 2,
        }}
      >
        {/* Horizontal Alignment */}
        <IconButton onClick={() => alignObject("horizontal-left")}>
          <AlignHorizontalLeftIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("horizontal-center")}>
          <AlignHorizontalCenterIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("horizontal-right")}>
          <AlignHorizontalRightIcon />
        </IconButton>

        {/* Vertical Alignment */}
        <IconButton onClick={() => alignObject("vertical-top")}>
          <AlignVerticalTopIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("vertical-center")}>
          <AlignVerticalCenterIcon />
        </IconButton>
        <IconButton onClick={() => alignObject("vertical-bottom")}>
          <AlignVerticalBottomIcon />
        </IconButton>
      </Box>

      {/* Dimensions Controls */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          marginBottom: 2,
        }}
      >
        <TextField
          label="W"
          value={Math.floor(width)}
          onChange={(e) => handleInputChange("width", e.target.value)} // Update input state
          // onBlur={(e) => handleApplyDimension("width", e.target.value)} // Apply value on blur
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          size="small"
          sx={{ width: 100 }}
        />
        <TextField
          label="H"
          value={Math.floor(height)}
          onChange={(e) => handleInputChange("height", e.target.value)} // Update input state
          // onBlur={(e) => handleApplyDimension("height", e.target.value)} // Apply value on blur
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          size="small"
          sx={{ width: 100 }}
        />

        {/* Lock Icon */}
        <IconButton onClick={() => setIsLocked(!isLocked)}>
          {isLocked ? <Lock /> : <LockOpen />}
        </IconButton>
      </Box>

      {/* <TextProperties
        currentSettings={textSettings} // Pass the current settings
        setCurrentSetting={setTextSettings}
        onSettingChange={handleTextSettingsChange} // Pass the function to update Fabric.js
        onInputChange={handleChange} // Pass the function to update local state
        selectedObject={selectedObject}
      ></TextProperties> */}
      {/* Font */}
      {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel>Font</InputLabel>
        <Select
          value={textSettings.fontFamily}
          onChange={(e) => handleChange("fontFamily", e.target.value)}
        >
          <MenuItem value="Arial">Arial</MenuItem>
          <MenuItem value="Roboto">Roboto</MenuItem>
          <MenuItem value="Times New Roman">Times New Roman</MenuItem>
        </Select>
      </FormControl> */}

      {/* Font Size */}
      {/* <Typography variant="subtitle1">Font Size</Typography>
      <TextField
        type="number"
        value={textSettings.fontSize}
        onChange={(e) => handleChange("fontSize", parseInt(e.target.value))}
        fullWidth
        sx={{ marginBottom: 2 }}
      /> */}

      {/* Alignment */}
      {/* <Typography variant="subtitle1">Alignment</Typography>
      <ToggleButtonGroup
        value={textSettings.textAlign}
        exclusive
        onChange={(e, value) => handleChange("textAlign", value)}
        sx={{ marginBottom: 2 }}
        size="small"
      >
        <ToggleButton value="left">
          <FormatAlignLeft />
        </ToggleButton>
        <ToggleButton value="center">
          <FormatAlignCenter />
        </ToggleButton>
        <ToggleButton value="right">
          <FormatAlignRight />
        </ToggleButton>
      </ToggleButtonGroup> */}

      {/* <ToggleButtonGroup
        value={currentSettings.textTransform}
        exclusive
        onChange={(e, value) => handleChange("textTransform", value)}
        sx={{ marginBottom: 2 }}
        size="small"
      >
        <ToggleButton value="uppercase">UPPERCASE</ToggleButton>
        <ToggleButton value="lowercase">lowercase</ToggleButton>
        <ToggleButton value="capitalize">Capitalize</ToggleButton>
      </ToggleButtonGroup> */}

      {/* Colors */}
      <Typography variant="subtitle1">Background Color</Typography>
      <TextField
        type="color"
        value={textSettings.backgroundColor}
        onChange={(e) => handleChange("backgroundColor", e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      />

      {/* <Typography variant="subtitle1">Stroke Color</Typography>
      <TextField
        type="color"
        value={textSettings.stroke}
        onChange={(e) => handleChange("stroke", e.target.value)}
        fullWidth
        sx={{ marginBottom: 2 }}
      /> */}

      {/* Stroke Width */}
      {/* <Typography variant="subtitle1">Stroke Width</Typography>
      <Slider
        aria-labelledby="input-slider"
        value={textSettings.strokeWidth || 0}
        onChange={(e, value) => handleChange("strokeWidth", value)}
        min={0}
        max={10}
        step={0.1}
        sx={{ marginBottom: 2 }}
        valueLabelDisplay="auto"
      /> */}

      {/* Opacity */}
      <Typography variant="subtitle1">Opacity</Typography>
      <Slider
        value={textSettings.opacity || 100}
        onChange={(e, value) => handleChange("opacity", value)}
        min={0}
        max={100}
        step={1}
        valueLabelDisplay="auto"
        sx={{ marginBottom: 2 }}
      />
      <Stack direction={"column"}>
        <Button
          onClick={handleRemoveBackground}
          color="primary"
          variant={"outlined"}
          // sx={{
          //   backgroundColor: "white",
          //   "&:hover": { backgroundColor: "#f0f0f0" },
          // }}
        >
          Remove Background
        </Button>

        {/* ✅ Clipping mask Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={clippingCheckbox}
              onChange={handleClippingChange}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Make clipping mask"
          sx={{ marginBottom: 2 }}
        />

        {/* ✅ use clipping mask Checkbox */}
        <FormControlLabel
          control={
            <Checkbox
              checked={mask}
              onChange={handleMaskSelection}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Use clipping mask"
          sx={{ marginBottom: 2 }}
        />
      </Stack>

      {/* <FormControlLabel
        control={
          <Checkbox
            checked={lockCheckBox}
            onChange={handleLockCheckBoxChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Lock Image"
        sx={{ marginBottom: 2 }}
      /> */}
      {/* ✅ Dropdown to Select Clipping Mask */}
      <FormControl fullWidth sx={{ marginBottom: 2 }}>
        <InputLabel id="mask-select-label">Use Clipping Mask</InputLabel>
        <Select
          labelId="mask-select-label"
          value={selectedMaskId}
          onChange={handleMaskArraySelectionChange}
          disabled={maskArray.length === 0} // Disable if no masks available
        >
          {maskArray.map((obj, index) => (
            <MenuItem key={obj.id} value={obj.id}>
              Mask {index + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Text Transformations */}
      {/* <Typography variant="subtitle1">Text Transformations</Typography> */}
      <TextBorderComponent
        selectedObject={selectedObject}
        fabricCanvasGlobal={fabricCanvasGlobal}
        setIsChildListenerActive={setIsChildListenerActive}
        isChildListenerActive={isChildListenerActive}
      />
      {/* <TextTransformationComponent
        selectedObject={selectedObject}
        fabricCanvasGlobal={fabricCanvasGlobal}
        setIsChildListenerActive={setIsChildListenerActive}
        isChildListenerActive={isChildListenerActive}
      /> */}
      <TextShadingComponent
        selectedObject={selectedObject}
        fabricCanvasGlobal={fabricCanvasGlobal}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          marginBottom: 2,
          alignItems: "center",
        }}
      ></Box>
    </Box>
  );
};

export default ImageSettings;
