import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
// import Checkbox from "@mui/material/Checkbox";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormHelperText from "@mui/material/FormHelperText";
// import FormControl from "@mui/material/FormControl";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
// import { setBackMenu } from "./redux-slices/BackMenuSlice";
// import { setGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { fabric } from "fabric";
import { enhanceImage } from "./image-processing-tools";
import { setImageURL } from "./redux-slices/ImageSlice";
import Slider from "@mui/material/Slider";
import { CanvasContext } from "./CanvasContext";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const ImageEnhancePanel = (props) => {
  const { open, setOpen } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  // const [aspectRatio, SetAspectRatio] = React.useState(true);
  // dispatch(setBackMenu());
  // dispatch(setGridMenuClicked());
  const [scaleWidth, setScaleWidth] = useState(Number(0));
  const [scaleHeight, setScaleHeight] = useState(0);
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  const originalImageURL = useSelector((state) => state.image.originalImageURL); // Access the image URL from the Redux store
  const [showImg, setShowImg] = useState(false);
  const [processedImg, setProcessedImg] = useState("");
  // const label = { inputProps: { "aria-label": "Checkbox demo" } };
  useEffect(() => {
    if (props.imageURL) {
      fabric.Image.fromURL(props.imageURL, (img) => {
        setWidth(img.width);
        setHeight(img.height);
        if (img.width >= img.height) setScaleWidth(img.width / img.height);
        else setScaleHeight(img.height / img.width);
      });
    }
  }, [props.imageURL]);

  const handleChange = () => {
    setShowImg(!showImg);
    if (showImg) dispatch(setImageURL(processedImg));
    else if (!showImg) dispatch(setImageURL(originalImageURL));
  };

  return (
    <Box>
      <Button
        onClick={() => {
          // dispatch(setBackMenu());
          dispatch(unsetGridMenuClicked());
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>
      <Box style={{ padding: "5%" }}>
        <Stack direction="column" spacing={2}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  // value={showImg}
                  // defaultChecked
                  onChange={handleChange}
                  // disabled={showImg}
                />
              }
              label="Show Original Image"
            />
          </FormGroup>
          <Button
            onClick={() => {
              setOpen(true);
              enhanceImage(props.imageURL)
                .then((url) => {
                  dispatch(setImageURL(url));
                  setOpen(false);
                  setProcessedImg(url);
                })
                .catch((error) => {
                  //console.log("Error processing the image: " + error);
                  setOpen(false);
                });
            }}
            variant="contained"
          >
            Apply
          </Button>
          <Button
            onClick={() => {
              dispatch(unsetGridMenuClicked());
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
export default ImageEnhancePanel;
