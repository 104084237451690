import React, { useContext, useRef } from "react";
import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setOriginalImageUR,
  setImgTemplateURL,
  setImageURL,
} from "../redux-slices/ImageSlice";
import { CanvasContext } from "../CanvasContext";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const CustomImageUploaderV2 = () => {
  const { selectedObject, fabricCanvasGlobal } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [disableImageButton, setDisableImageButton] = React.useState(true);

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   if (file) {
  //     // Handle the file upload
  //     const url = URL.createObjectURL(file);
  //     dispatch(setImgTemplateURL(url)); // Dispatch action to save URL
  //     //   dispatch(setOriginalImageURL(url));

  //     // Create an Image object
  //   }
  // };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        // Create a FormData object to hold the image file
        const formData = new FormData();
        formData.append("image", file);

        // Send the image to the Django endpoint
        const response = await fetch(`${baseURL}/api/resize-image-upload/`, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.resized_image) {
          throw new Error(
            "The response does not contain the resized image data."
          );
        }

        // Convert the hex string back to a Blob
        const resizedImageBuffer = new Uint8Array(
          data.resized_image.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
        );
        const blob = new Blob([resizedImageBuffer], { type: "image/jpeg" });

        // Create a URL from the Blob and dispatch it
        const resizedImageUrl = URL.createObjectURL(blob);
        // //console.log(
        //   "BREAPOINT selectedObject= " + JSON.stringify(selectedObject, null, 2)
        // );
        if (selectedObject && selectedObject.type === "image") {
          selectedObject.setSrc(resizedImageUrl, () => {
            fabricCanvasGlobal.renderAll(); // ✅ Re-render to reflect the change
          });
        } else {
          console.error("No image selected or object is not an image.");
        }

        // dispatch(setImageURL(resizedImageUrl)); // Dispatch action to save URL
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  React.useEffect(() => {
    setDisableImageButton(true);
    if (selectedObject && selectedObject.type === "image") {
      setDisableImageButton(false);
    }
  }, [selectedObject]);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          fileInputRef.current.click();
        }}
        color="primary"
        size="small"
        disabled={disableImageButton}
      >
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept="image/*"
        />
        Replace Image
      </Button>
    </>
  );
};

export default CustomImageUploaderV2;
