import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CanvasContext } from "../CanvasContext";
import { fabric } from "fabric";
import { debounce } from "lodash";

const TextBorderComponent = ({ selectedObject, fabricCanvasGlobal }) => {
  const {
    isOpenTextBorder,
    setIsOpenTextBorder,
    strokeSize,
    setStrokeSize,
    strokeColor,
    setStrokeColor,
  } = useContext(CanvasContext);

  React.useEffect(() => {
    if (!selectedObject) return;
    if (isOpenTextBorder) updateShadow(strokeSize, strokeColor);

    // fabricCanvasGlobal.requestRenderAll();

    // fabricCanvasGlobal.on("object:moving", handleObjectMoving);
    return () => {
      // fabricCanvasGlobal.off("object:moving", handleObjectMoving);
    };
  }, [strokeColor, strokeSize]);

  const handleToggle = () => {
    setIsOpenTextBorder(!isOpenTextBorder);
  };

  // React.useEffect(() => {
  //   if (isOpenTextShading) handleShadingClick(activeShading);
  // }, [activeShading]);

  React.useEffect(() => {
    if (!isOpenTextBorder) {
      selectedObject.set({
        // path: null,
        // skewX: 0,
        // skewY: 0,
        // scaleX: 1,
        // scaleY: 1,
        stroke: "",
        strokeWidth: 0,
        border: null,
      });

      // setIsChildListenerActive(false);
    } else {
      updateShadow(strokeSize, strokeColor);
    }
    fabricCanvasGlobal.requestRenderAll();
  }, [isOpenTextBorder]);

  const updateShadow = debounce((strokeSize, strokeColor) => {
    selectedObject.set({ stroke: strokeColor, strokeWidth: strokeSize });
    //custom shading properties of selected object
    selectedObject.set({
      border: {
        strokeSize: strokeSize,
        strokeColor: strokeColor,
        isOpenTextBorder: true,
      },
    });
    fabricCanvasGlobal.requestRenderAll();
  }, 100); // Update every 100ms

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 400,
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      {/* Header with Toggle */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Stroke</Typography>
        <IconButton onClick={handleToggle}>
          {isOpenTextBorder ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>

      {/* Content (conditionally rendered) */}
      {isOpenTextBorder && (
        <>
          {/* Color Picker */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              type="color"
              value={strokeColor}
              onChange={(e) => setStrokeColor(e.target.value)}
              sx={{ width: 60 }}
            />
            <Typography>{strokeColor}</Typography>
            {/* <Typography>{opacity}%</Typography> */}
          </Box>

          {/* Offset Slider */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            Stroke Size
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Slider
              value={strokeSize}
              onChange={(e, value) => setStrokeSize(value)}
              min={0}
              max={10}
              step={0.1}
              sx={{ flex: 1 }}
            />
            <TextField
              type="number"
              value={strokeSize}
              onChange={(e) => setStrokeSize(parseInt(e.target.value) || 0)}
              InputProps={{
                endAdornment: <InputAdornment position="end">°</InputAdornment>,
              }}
              size="small"
              sx={{ width: 80 }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TextBorderComponent;
