import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CanvasContext } from "../CanvasContext";
import api from "../axiosConfig";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const Designs = () => {
  const { setArtBoardSettings, artBoardSettings, setProjectName } =
    useContext(CanvasContext);
  const navigate = useNavigate();
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [responseTracker, SetResponseTracker] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchDesigns = async (page = 1) => {
    try {
      const response = await api.get("/api/designs/", {
        params: { page },
      });

      setDesigns(response.data.results); // Paginated designs
      setTotalPages(Math.ceil(response.data.count / 9)); // Assuming 9 designs per page
    } catch (error) {
      console.error("❌ Failed to fetch designs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch designs from the backend
    // const fetchDesigns = async (page = 1) => {
    //   try {
    //     // const response = await fetch(`${baseURL}/api/designs/`, {
    //     //   headers: {
    //     //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //     //   },
    //     // });
    //     // if (response.ok) {
    //     //   const data = await response.json();
    //     //   //console.log("BREAKPOINT data= " + JSON.stringify(data, null, 2));
    //     //   setDesigns(data);
    //     // } else {
    //     //   console.error("Failed to fetch designs");
    //     // }
    //     try {
    //       const response = await api.get("/api/designs/", {
    //         params: { page },
    //       });

    //       // //console.log(
    //       //   "BREAKPOINT data= ",
    //       //   JSON.stringify(response.data, null, 2)
    //       // );
    //       setDesigns(response.data.results); // Axios handles JSON parsing automatically
    //       setTotalPages(Math.ceil(response.data.count / 9)); // Assuming 9 designs per page
    //     } catch (error) {
    //       // console.error(
    //       //   "❌ Failed to fetch designs:",
    //       //   error.response?.data || error
    //       // );
    //     }
    //   } catch (error) {
    //     console.error("Error fetching designs:", error);
    //   } finally {
    //     setLoading(false);
    //   }
    // };

    fetchDesigns();
  }, [responseTracker]);

  const handleCardClick = (design) => {
    // Parse the canvas_json to extract properties
    const canvasData = JSON.parse(design.canvas_json);
    // Navigate or take action with the clicked design
    // //console.log("BREAKPOINT design= ", JSON.stringify(design, null, 2));
    // //console.log(
    //   "BREAKPOINT design.canvas_json.canvasWidth= " +
    //     canvasData.extraProperties.canvasWidth
    // );
    setArtBoardSettings({
      ...artBoardSettings,
      width: canvasData.extraProperties.canvasWidth,
      height: canvasData.extraProperties.canvasHeight,
      backgroundColor: canvasData.background != "" ? canvasData.background : "",
      transparentBackground: canvasData.background != "" ? false : true,
    });
    setProjectName(design.name);
    navigate(`/design/${design.id}`, {
      state: { canvasJson: design.canvas_json, designId: design.id },
    });
    // Example: Navigate to a details page (requires React Router)
    // navigate(`/design/${designId}`);
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" align="center">
          Loading designs...
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (designs.length === 0) {
    return (
      <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        No designs found.
      </Typography>
    );
  }

  const handleMenuClick = (event, design) => {
    event.stopPropagation(); // ⛔ Prevent card click from triggering
    setAnchorEl(event.currentTarget);
    setSelectedDesign(design);
  };

  const handleMenuClose = (event) => {
    // event.stopPropagation(); // ⛔ Prevent card click from triggering
    setAnchorEl(null);
    setSelectedDesign(null);
  };

  const handleDeleteDesign = async (event) => {
    event.stopPropagation(); // ⛔ Prevent card click from triggering
    handleMenuClose();
    try {
      await api.delete(`/api/designs/${selectedDesign.id}/`);
      setDesigns((prev) => prev.filter((d) => d.id !== selectedDesign.id));
      SetResponseTracker((prev) => !prev);
      handleMenuClose();
    } catch (error) {
      console.error("Failed to delete design:", error);
    }
  };

  const handleDuplicateDesign = async (event, design) => {
    event.stopPropagation(); // ⛔ Prevent card click from triggering
    handleMenuClose();
    try {
      const response = await api.post(`/api/designs/${design.id}/duplicate/`);
      setDesigns((prev) => [...prev, response.data]);
      SetResponseTracker((prev) => !prev);
      handleMenuClose();
    } catch (error) {
      console.error("Failed to duplicate design:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Your Designs
      </Typography>
      <Grid container spacing={3}>
        {designs.map((design) => (
          <Grid item xs={12} sm={6} md={4} key={design.id}>
            <Card
              sx={{
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => handleCardClick(design)}
            >
              <CardMedia
                component="img"
                // height="140"
                src={design.image_preview}
                alt={design.name}
              />
              <CardContent sx={{ position: "relative" }}>
                <Typography variant="h6">{design.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Created on: {new Date(design.created_at).toLocaleDateString()}
                </Typography>

                <IconButton
                  onClick={(e) => handleMenuClick(e, design)}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem
                    onClick={(e) => {
                      handleDuplicateDesign(e, selectedDesign);
                    }}
                  >
                    Duplicate
                  </MenuItem>
                  <MenuItem onClick={handleDeleteDesign}>Delete</MenuItem>
                </Menu>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(e, page) => {
            setCurrentPage(page);
            fetchDesigns(page);
          }}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default Designs;
