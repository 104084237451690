import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
// import { setBackMenu } from "./redux-slices/BackMenuSlice";
// import { setGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { fabric } from "fabric";
// import { enhanceImage } from "./image-processing-tools";
import { setImageURL } from "./redux-slices/ImageSlice";
import Slider from "@mui/material/Slider";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CanvasContext } from "./CanvasContext";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const ImageFiltersGeneralPanel = (props) => {
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  const originalImageURL = useSelector((state) => state.image.originalImageURL); // Access the image URL from the Redux store

  const [processedImg, setProcessedImg] = useState("");
  const { open, setOpen } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  //   const [aspectRatio, SetAspectRatio] = React.useState(true);
  // dispatch(setBackMenu());
  // dispatch(setGridMenuClicked());
  const [scaleWidth, setScaleWidth] = useState(Number(0));
  const [scaleHeight, setScaleHeight] = useState(0);
  const [showImg, setShowImg] = useState(false);
  // const [open, setOpen] = useState(false);

  //   const label = { inputProps: { "aria-label": "Checkbox demo" } };
  // useEffect(() => {
  //   if (props.imageURL) {
  //     fabric.Image.fromURL(props.imageURL, (img) => {
  //       setWidth(img.width);
  //       setHeight(img.height);
  //       if (img.width >= img.height) setScaleWidth(img.width / img.height);
  //       else setScaleHeight(img.height / img.width);
  //     });
  //   }
  // }, [props.imageURL]);

  const handleApplyClick = () => {
    setOpen(true);
    props
      .handleFunction(props.imageURL)
      .then((url) => {
        setOpen(false);
        dispatch(setImageURL(url));
        setProcessedImg(url);

        // dispatch(unsetGridMenuClicked());
      })
      .catch((error) => {
        //console.log("Error processing the image: " + error);
        setOpen(false);
      });
  };

  const handleChange = () => {
    setShowImg(!showImg);
    if (showImg) dispatch(setImageURL(processedImg));
    else if (!showImg) dispatch(setImageURL(originalImageURL));
  };

  return (
    <Box>
      <Button
        onClick={() => {
          // dispatch(setBackMenu());
          dispatch(unsetGridMenuClicked());
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>
      <Box style={{ padding: "5%" }}>
        <Stack direction="column" spacing={2}>
          {/* <Box sx={{ m: 1, paddingTop: "20%" }}>
            <Slider
              disabled={false}
              marks={true}
              max={100}
              min={0}
              //   size="medium"
              valueLabelDisplay="on"
              defaultValue={0}
              onChange={(e) => {}}
            />
          </Box> */}
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  // value={showImg}
                  // defaultChecked
                  onChange={handleChange}
                  // disabled={showImg}
                />
              }
              label="Show Original Image"
            />
          </FormGroup>
          <Button onClick={handleApplyClick} variant="contained">
            Apply
          </Button>
          <Button
            onClick={() => {
              dispatch(unsetGridMenuClicked());
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
export default ImageFiltersGeneralPanel;
