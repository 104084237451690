import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "@mui/material/ToggleButton";

import {
  Box,
  Grid,
  Stack,
  Typography,
  Divider,
  ToggleButtonGroup,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import ShareIcon from "@mui/icons-material/Share";

import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  setBasketProduct,
  initializeProduct,
  clearUpProduct,
  setListing,
} from "./redux-slices/ProductSlice";
import { useFetcher, useNavigate } from "react-router-dom";
import { addItem } from "./redux-slices/BasketSlice";
import { fetchProductDetails } from "./fetchProductDetails";
import {
  CircularProgress,
  Alert,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import ListItemIcon from "@mui/material/ListItemIcon";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import CustomerReviews from "./CustomerReviews";
import { useMediaQuery } from "@mui/material";

const ProductComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const imgMockupURL = useSelector((state) => state.image.imgMockupURL);
  const product = useSelector((state) => state.product.product);
  const listing = useSelector((state) => state.product.listing);
  const items = useSelector((state) => state.basket.items);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const [alignment, setAlignment] = React.useState("S");
  const [price, setPrice] = React.useState(0);
  const [quantity, setQuantity] = React.useState(1); // Start with a default value of 1
  const [totalPrice, setTotalPrice] = React.useState(price * quantity);
  const navigate = useNavigate();

  const [productFetched, setProductFetched] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [sizes, setSizes] = React.useState([]);

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const data = await fetchProductDetails(listing.listingId);
        setProductFetched(data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    getProductDetails();
  }, [listing.listingId]);

  // if (loading) {
  //   return <CircularProgress />;
  // }

  // if (error) {
  //   return (
  //     <Alert severity="error">
  //       Error fetching product details: {error.message}
  //     </Alert>
  //   );
  // }

  const handleBuyNow = () => {
    // dispatch(initializeProduct()); // Initialize the product with a unique ID
    dispatch(addItem(product));
    navigate("/purchase");
  };

  const updateProduct = (name, value) => {
    dispatch(setBasketProduct({ name, value }));
  };

  const updateListing = (name, value) => {
    dispatch(setListing({ name, value }));
  };

  const handleChangeToggleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  // const children = [
  //   <ToggleButton value="S" key="S">
  //     <Typography>Small</Typography>
  //   </ToggleButton>,
  //   <ToggleButton value="M" key="M">
  //     <Typography>Medium</Typography>
  //   </ToggleButton>,
  //   <ToggleButton value="L" key="L">
  //     <Typography>Large</Typography>
  //   </ToggleButton>,
  //   <ToggleButton value="XL" key="XL">
  //     <Typography>X-Large</Typography>
  //   </ToggleButton>,
  //   <ToggleButton value="2XL" key="2XL">
  //     <Typography>2X-Large</Typography>
  //   </ToggleButton>,
  //   <ToggleButton value="3XL" key="3XL">
  //     <Typography>3X-Large</Typography>
  //   </ToggleButton>,
  // ];

  // var children = [];

  // useEffect(() => {
  //   ["s", "m"].forEach((element) => {
  //     children.push(
  //       <ToggleButton value={element} key={element}>
  //         <Typography>{element}</Typography>
  //       </ToggleButton>
  //     );
  //   });
  // }, [product.listing.sizes]);
  const [sizesArray, setSizesArray] = React.useState([]);

  useEffect(() => {
    if (productFetched) {
      updateListing("title", productFetched.title);
      updateListing("description", productFetched.description);
      updateListing("brand", productFetched.brand);
      updateListing("listingId", productFetched.listingId);
      updateListing("unitPrice", productFetched.unitPrice);
      updateListing("sizes", productFetched.sizes);
      updateListing("shippingRates", productFetched.shippingRates);

      // updateProduct("mockupImageURL", productFetched.images[0].img);

      // setImages(productFetched.images);
      // //console.log("BREAKPOINT images= " + productFetched.images);
    }
  }, [productFetched]);

  useEffect(() => {
    setSizesArray(product.listing.sizes);
  }, [product.listing.sizes]);

  // const children = product.listing.sizes.map((element) => {
  //   <ToggleButton value={element} key={element}>
  //     <Typography>{element}</Typography>
  //   </ToggleButton>;
  // });

  const control = {
    value: alignment,
    onChange: handleChangeToggleAlignment,
    exclusive: true,
  };

  const handleIncrement = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => Math.max(1, prevQuantity - 1)); // Prevent going below 1
  };

  //initial effect for image ulr and listing details
  useEffect(() => {
    updateProduct("mockupImageURL", imgMockupURL);
    //console.log("BREAKPOINT imgMockupURL= " + imgMockupURL);
    updateProduct("listing", listing);
    return () => {
      dispatch(clearUpProduct());
    };
  }, []);

  //quantity change effect
  useEffect(() => {
    updateProduct("quantity", quantity);
    setTotalPrice(quantity * price);
    updateProduct("totalPrice", quantity * price);
  }, [quantity, price]);

  //size change effect
  useEffect(() => {
    updateProduct("size", alignment);
  }, [alignment]);

  //set the sizes and prices of product in product landing page.
  useEffect(() => {
    if (productFetched && alignment) {
      setSizes(productFetched.sizes);
      setPrice(
        productFetched.sizes.find((element) => element.size === alignment).price
      );
    }
  }, [productFetched, alignment]);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setQuantity(newValue);
      // updateProduct("quantity", quantity);
    } else {
      // updateProduct("quantity", quantity);
      setQuantity(1); // Reset to 1 if invalid input
    }
  };

  const handleAddToCart = () => {
    dispatch(initializeProduct()); // Initialize the product with a unique ID
  };

  //adds product to basket items state
  useEffect(() => {
    if (product.id !== "") {
      dispatch(addItem(product));
    }

    // //console.log("BREAKPOINT checking uuid= " + JSON.stringify(product.listing));
    //console.log("BREAKPOINT checking product= " + product.size);
    //console.log("BREAKPOINT checking items= " + JSON.stringify(items));
  }, [product.id]);

  return (
    <ThemeProvider theme={theme}>
      {productFetched ? (
        <Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item
              xs={4}
              sm={6}
              md={8}
              sx={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "end",
                // backgroundColor: "yellow",
                marginTop: "5%",
              }}
              //   sx={{ backgroundColor: "red" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={imgMockupURL}
                  width={"75%"}
                  height={"auto"}
                  alt="mockup image of custom order"
                />
              </Box>
              {!matchesXS && (
                <Box>
                  <CustomerReviews listingId={listing.listingId} />
                </Box>
              )}

              {/* <Typography>text</Typography> */}
            </Grid>
            <Grid
              item
              xs={4}
              sm={2}
              md={4}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "gray",
                marginTop: "5%",
                ...(matchesXS ? { ml: 2, mr: 2 } : { ml: "auto", mr: "auto" }),
              }}
            >
              <Stack direction={"column"} spacing={"5%"}>
                <Typography variant="h2" gutterBottom>
                  {productFetched.title}
                </Typography>
                <Typography variant="h6" gutterBottom>
                  ${totalPrice}
                </Typography>
                <Divider />
                <Stack direction={"column"}>
                  <Typography
                    sx={{
                      ...(matchesXS
                        ? { ml: 5, mr: 2 }
                        : { ml: "auto", mr: "auto" }),
                    }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    Choose Size
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      ...(matchesXS
                        ? { ml: 5, mr: 2 }
                        : { ml: "auto", mr: "auto" }),
                      // justifyContent: "center",
                      marginBottom: "25%",
                    }}
                  >
                    <ToggleButtonGroup
                      size="large"
                      {...control}
                      aria-label="Small sizes"
                    >
                      {/* {children} */}
                      {sizes.map((element) => (
                        <ToggleButton value={element.size} key={element.size}>
                          <Typography>{element.size}</Typography>
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>
                  </Box>
                </Stack>
                <Typography
                  sx={{
                    ...(matchesXS
                      ? { ml: 5, mr: 2 }
                      : { ml: "auto", mr: "auto" }),
                  }}
                  variant="subtitle1"
                  gutterBottom
                >
                  Quantity
                </Typography>
                <Stack
                  sx={{
                    ...(!matchesXS
                      ? { ml: 2, mr: 2 }
                      : { ml: "auto", mr: "auto" }),
                  }}
                  direction="row"
                  spacing={1}
                >
                  <IconButton variant="outlined" onClick={handleDecrement}>
                    <RemoveCircleOutlineIcon />
                  </IconButton>
                  <TextField
                    size="small"
                    value={quantity}
                    type="number"
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        min: 1, // HTML5 constraint to ensure only positive values
                      },
                    }}
                  />
                  <IconButton variant="outlined" onClick={handleIncrement}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Stack>
                <Box
                  sx={{
                    ...(matchesXS
                      ? { ml: 5, mr: 2 }
                      : { ml: "auto", mr: "auto" }),
                  }}
                >
                  <Button
                    startIcon={<ShoppingCartIcon />}
                    variant="outlined"
                    sx={{ mt: 1 }}
                    onClick={handleAddToCart}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    startIcon={<PaymentIcon />}
                    variant="contained"
                    color="primary"
                    sx={{ mt: 1, ml: 1 }}
                    onClick={handleBuyNow}
                  >
                    Buy It Now
                  </Button>
                </Box>

                <Typography variant="body2" sx={{ mt: 2 }}>
                  {productFetched.description}
                </Typography>
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Product Properties
                </Typography>
                <List dense>
                  {productFetched.properties.map((property, index) => (
                    <ListItem key={index} dense disablePadding>
                      <ListItemIcon>
                        <HorizontalRuleIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={property} />
                    </ListItem>
                  ))}
                </List>
                {matchesXS && (
                  <Box>
                    <CustomerReviews listingId={listing.listingId} />
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: 1,
                    mt: 1,
                  }}
                >
                  <IconButton aria-label="share on social media">
                    <ShareIcon />
                  </IconButton>
                  {/* More icons can be added for specific social media */}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Typography variant="body1">Product not found</Typography>
      )}
    </ThemeProvider>
  );
};

export default ProductComponent;
