import { Box, Stack } from "@mui/material";
import React from "react";
import { CanvasContext } from "../CanvasContext";
import CustomProductPreview from "./CustomProductPreview";
import { useParams } from "react-router-dom";
import MockupGeneratorProductPage from "./MockupGeneratorProductPage";

const CustomizeProductCanvasV2 = ({
  canvasImage,
  designRef,
  confirmDesign,
}) => {
  const { productBrand } = useParams();
  const { productCanvasRef, productColorOption, productOptions } =
    React.useContext(CanvasContext);

  const [selectedProduct, setSelectedProduct] = React.useState(
    productOptions.find((product) => product.brand === productBrand)
  );
  const [approvedProductImg, setApprovedProductImg] = React.useState([]);

  return (
    <>
      <Stack
        direction={"column"}
        spacing={2}
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   mt: "2%",
          //   mb: "2%",
        }}
      >
        <Box
          // ref={props.modalRef}
          sx={{
            //   position: "absolute",
            //   left: "50%",
            //   top: canvasTop,
            //   transform: "translate(-50%, -50%)",
            zIndex: 10,
            backgroundColor: "gray",
            border: "2px dashed gray", // Dashed border
          }}
        >
          <canvas id={"product-canvas"} ref={productCanvasRef}></canvas>
        </Box>
        <MockupGeneratorProductPage
          canvasImage={canvasImage}
          designRef={designRef}
          selectedProduct={selectedProduct}
          approvedProductImg={approvedProductImg}
          setApprovedProductImg={setApprovedProductImg}
          confirmDesign={confirmDesign}
        />

        {/* <Button variant="contained" color="primary" onClick={saveCombinedImage}>
          Save Combined Image
        </Button>
        <canvas ref={combinedCanvasRef} style={{ display: "none" }}></canvas> */}
      </Stack>
      {/* <Box
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: -1,
          // backgroundColor: "pink",
          // border: "2px dashed gray", // Dashed border
        }}
      >
        <CustomProductPreview
          closeButton={props.closeButton}
          confirmDesign={props.confirmDesign}
          setConfirmDesign={props.setConfirmDesign}
          previewRatio={props.previewRatio}
        />
      </Box> */}

      {/* {productColor.img} */}
    </>
  );
};

export default CustomizeProductCanvasV2;
