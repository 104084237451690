import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Pagination,
  Container,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../axiosConfig";

const UserProducts = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalProducts, setTotalProducts] = useState(0); // ✅ Store total product count

  useEffect(() => {
    fetchProducts(currentPage);
  }, []);

  const fetchProducts = async (pageNumber = 1) => {
    try {
      setLoading(true);

      const response = await api.get(`/api/user-products/`, {
        params: { page: pageNumber }, // ✅ Pass page number as query param
      });

      setProducts(response.data.results); // ✅ Use paginated results
      setTotalPages(Math.ceil(response.data.count / 10)); // ✅ Update total pages
      setTotalProducts(response.data.count); // ✅ Store total number of products
    } catch (error) {
      console.error("❌ Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchProducts(value);
  };

  const handleCardClick = (product) => {
    navigate(`/edit-product/${product.listing_id}`, {
      state: { productData: product },
    });
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" align="center">
          Loading products...
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (products.length === 0) {
    return (
      <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        No products found.
      </Typography>
    );
  }

  return (
    <Box>
      {/* ✅ Display Total Product Count */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        Your Products ({totalProducts})
      </Typography>

      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.listing_id}>
            <Card
              sx={{
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
              onClick={() => handleCardClick(product)}
            >
              <CardMedia
                component="img"
                src={product.images[0]?.img}
                alt={product.title}
              />
              <CardContent>
                <Typography variant="h6">{product.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Brand: {product.brand}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created on:{" "}
                  {new Date(product.created_at).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* ✅ Pagination Component */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default UserProducts;
