import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem("accessToken") ? true : false; // Check if token exists in storage
  });

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    // Optionally, add more rigorous checks or token validation here
    if (accessToken) {
      setIsAuthenticated(true);
    }
  }, []);

  //   useEffect(() => {
  //     const token = localStorage.getItem("access");
  //     if (token) {
  //         setIsAuthenticated(true);
  //     }
  // }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
