import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Pagination,
  Container,
  Button,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../axiosConfig";

const AdminProducts = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchProducts(currentPage);
  }, []);

  const fetchProducts = async (pageNumber = 1) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/approved-products/`, {
        params: { page: pageNumber }, // ✅ Get unapproved products
      });

      setProducts(response.data.results); // ✅ Store paginated products
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error("❌ Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleApproveProduct = async (productId) => {
    try {
      const response = await api.put(`/api/approved-products/${productId}/`, {
        is_approved: true, // ✅ Approve the product
      });

      if (response.status === 200) {
        alert("✅ Product approved successfully!");
        fetchProducts(currentPage); // ✅ Refresh products after approval
      }
    } catch (error) {
      console.error("❌ Error approving product:", error);
      alert("❌ Failed to approve product.");
    }
  };

  const handleRejectProduct = async (productId) => {
    try {
      const response = await api.put(`/api/approved-products/${productId}/`, {
        is_approved: false, // ✅ Reject the product
      });

      if (response.status === 200) {
        alert("❌ Product rejected!");
        fetchProducts(currentPage); // ✅ Refresh after rejection
      }
    } catch (error) {
      console.error("❌ Error rejecting product:", error);
      alert("❌ Failed to reject product.");
    }
  };

  const handleDeleteProduct = async (productId) => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;

    try {
      const response = await api.delete(`/api/approved-products/${productId}/`);

      if (response.status === 200) {
        alert("🗑️ Product deleted successfully!");
        fetchProducts(currentPage); // ✅ Refresh products after deletion
      }
    } catch (error) {
      console.error("❌ Error deleting product:", error);
      alert("❌ Failed to delete product.");
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    fetchProducts(value);
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Typography variant="h5" align="center">
          Loading products...
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (products.length === 0) {
    return (
      <Typography variant="h6" align="center" sx={{ mt: 4 }}>
        No unapproved products found.
      </Typography>
    );
  }

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Pending Product Approvals
      </Typography>
      <Grid container spacing={3}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} md={4} key={product.listing_id}>
            <Card
              sx={{
                cursor: "pointer",
                transition: "transform 0.2s, box-shadow 0.2s",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              <CardMedia
                component="img"
                src={product.images[0]?.img}
                alt={product.title}
              />
              <CardContent>
                <Typography variant="h6">{product.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  Brand: {product.brand}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Created on:{" "}
                  {new Date(product.created_at).toLocaleDateString()}
                </Typography>

                {/* ✅ Approve, Reject & Delete Buttons */}
                <Stack spacing={1} sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => handleApproveProduct(product.id)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    fullWidth
                    onClick={() => handleRejectProduct(product.id)}
                  >
                    Reject
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => handleDeleteProduct(product.id)}
                  >
                    Delete
                  </Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* ✅ Pagination Component */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </Box>
  );
};

export default AdminProducts;
