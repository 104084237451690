// import axios from "axios";

// const baseURL = process.env.REACT_APP_API_BASE_URL;
// let retryCount = 0;
// const maxRetries = 3;

// // Create an Axios instance
// const api = axios.create({
//   // baseURL: "http://127.0.0.1:8000", // Change this to your API base URL
//   baseURL: baseURL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// export const logoutUser = () => {
//   // Remove tokens from local storage
//   localStorage.removeItem("accessToken");
//   localStorage.removeItem("refreshToken");
//   localStorage.removeItem("userType");

//   // Redirect user to login page or display a logout message
//   window.location.href = "/login"; // Adjust to your app’s login route
// };

// // Function to refresh the token
// async function refreshToken() {
//   const refreshToken = localStorage.getItem("refreshToken");
//   if (!refreshToken) {
//     throw new Error("No refresh token available");
//   }

//   try {
//     const response = await api.post("/auth/jwt/refresh/", {
//       refresh: refreshToken,
//     });
//     retryCount = 0; // Reset retry counter on success
//     const { access } = response.data;
//     localStorage.setItem("accessToken", access);
//     return access;
//   } catch (error) {
//     if (retryCount >= maxRetries) {
//       // setIsAuthenticated(false);
//       logoutUser(); // Optional logout or error handling here
//       return;
//     }
//     retryCount++;
//     //console.log(`Retry attempt ${retryCount}`);
//     await new Promise((res) => setTimeout(res, 1000)); // 1 second delay
//     return refreshToken(); // Recursive retry
//   }
// }

// // api.interceptors.request.use(
// //   (request) => {
// //     const accessToken = localStorage.getItem("accessToken");
// //     if (accessToken) {
// //       request.headers["Authorization"] = `Bearer ${accessToken}`;
// //     }
// //     return request;
// //   },
// //   (error) => {
// //     return Promise.reject(error);
// //   }
// // );

// // Axios interceptor to handle token expiration
// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       !originalRequest._retry
//     ) {
//       originalRequest._retry = true;
//       try {
//         const newAccessToken = await refreshToken();
//         api.defaults.headers.common["Authorization"] =
//           "Bearer " + newAccessToken;
//         originalRequest.headers["Authorization"] = "Bearer " + newAccessToken;
//         return api(originalRequest);
//       } catch (error) {
//         console.error("Token refresh failed:", error);
//         logoutUser();
//         // Redirect to login page or handle as needed
//         // window.location.href = "/login"; // Uncomment to redirect to login on failure
//         return Promise.reject(error);
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// // Set the Authorization header if the token exists
// const accessToken = localStorage.getItem("accessToken");
// if (accessToken) {
//   api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
// }

// export default api;

////////////////////
import axios from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;
let retryCount = 0;
const maxRetries = 3;

// Create an Axios instance
const api = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Function to log out the user
export const logoutUser = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("userType");
  window.location.href = "/login"; // Redirect to login page
};

// Function to refresh the access token
async function refreshToken() {
  const refreshToken = localStorage.getItem("refreshToken");

  if (!refreshToken) {
    // console.error("❌ No refresh token available, logging out.");
    logoutUser();
    return null;
  }

  try {
    const response = await axios.post(`${baseURL}/auth/jwt/refresh/`, {
      refresh: refreshToken,
    });

    retryCount = 0; // Reset retry counter on success
    const { access } = response.data;

    if (!access) {
      // console.error("❌ Received invalid access token, logging out.");
      logoutUser();
      return null;
    }

    localStorage.setItem("accessToken", access);
    return access;
  } catch (error) {
    // console.error("❌ Token refresh failed:", error.response?.data || error);

    // Stop retrying if refresh token is expired (401 Unauthorized)
    if (error.response?.status === 401) {
      // console.warn("🚨 Refresh token expired! Logging out.");
      logoutUser();
      return null;
    }

    retryCount++;
    if (retryCount >= maxRetries) {
      // console.error("❌ Max retry attempts reached, logging out.");
      logoutUser();
      return null;
    }

    // //console.log(`🔄 Retry attempt ${retryCount}`);
    await new Promise((res) => setTimeout(res, 1000)); // Delay before retrying

    return refreshToken(); // Recursive retry (with a limit)
  }
}

// Axios response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check for 401 Unauthorized and ensure this request hasn't already been retried
    if (error.response?.status === 401 && !originalRequest._retry) {
      // console.warn(
      //   "⚠️ Unauthorized request detected, attempting token refresh..."
      // );
      originalRequest._retry = true;

      const newAccessToken = await refreshToken(); // Attempt to refresh the token

      if (!newAccessToken) {
        // console.warn("🚨 Token refresh failed, logging out.");
        return Promise.reject(error); // Stop retrying if refresh fails
      }

      // Set the new access token and retry the original request
      api.defaults.headers.common["Authorization"] = `Bearer ${newAccessToken}`;
      originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
      return api(originalRequest);
    }

    return Promise.reject(error);
  }
);

// Set the Authorization header if the token exists
const accessToken = localStorage.getItem("accessToken");
if (accessToken) {
  api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
}

export default api;
