import React, { useRef, useState, useContext } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Grid,
  Slider,
  TextField,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { fabric } from "fabric";
import { CanvasContext } from "../CanvasContext";

const TextTransformationComponent = ({
  selectedObject,
  fabricCanvasGlobal,
  setIsChildListenerActive,
  isChildListenerActive,
}) => {
  const {
    isOpenTextTransformation,
    setIsOpenTextTransformation,
    activeTransformation,
    setActiveTransformation,
    artBoardSettings,
    angleCurve,
    setAngleCurve,
    skewXValue,
    setSkewXValue,
    offset,
    setOffset,
    transformationConfirmed,
    setTransformationConfirmed,
    // controlPoints,
    // setControlPoints,
  } = useContext(CanvasContext);
  // const [isOpen, setIsOpen] = useState(false); // State to toggle open/close
  // const [angleCurve, setAngleCurve] = useState(80); // State for slider value
  // const [activeTransformation, setActiveTransformation] = useState(null); // Tracks active button
  // const [pathText, setPathText] = useState(null);
  const pathRef = useRef(selectedObject.path); // Use ref for path

  const handleToggle = () => {
    setIsOpenTextTransformation(!isOpenTextTransformation);
  };

  // React.useEffect(() => {
  //   setSkewXValue(angleCurve / 4);
  // }, [angleCurve]);

  // React.useEffect(() => {
  //   //console.log("BREAKPOINT skewXValue= " + skewXValue);
  //   selectedObject.set({ skewX: -skewXValue });
  // }, [skewXValue]);

  const handleAngleCurveChange = (event, newValue) => {
    setAngleCurve(newValue);
  };

  const handleOffsetChange = (event, newValue) => {
    setOffset(newValue);
  };

  // function calculatePathLength(fabricPath) {
  //   // Use SVG to calculate the path length
  //   const svgPath = document.createElementNS(
  //     "http://www.w3.org/2000/svg",
  //     "path"
  //   );
  //   svgPath.setAttribute("d", fabricPath.path.toString());
  //   return svgPath.getTotalLength();
  // }

  // function truncateTextToFitPath(text, fabricTextObject, fabricPath) {
  //   const charSpacing = fabricTextObject.charSpacing || 0; // Character spacing
  //   const fontSize = fabricTextObject.fontSize || 16; // Font size
  //   const scaleFactor = fabricTextObject.scaleX || 1; // Scale factor (if scaled)

  //   // Approximate the length of the text
  //   const textLength = text.length * (fontSize + charSpacing) * scaleFactor;

  //   // Calculate the path length
  //   const pathLength = calculatePathLength(fabricPath);

  //   // If text length exceeds path length, truncate
  //   if (textLength > pathLength) {
  //     const maxChars = Math.floor((pathLength / textLength) * text.length);
  //     return text.slice(0, maxChars) + "..."; // Truncate and add ellipsis
  //   }
  //   return text; // Text fits the path
  // }
  const getPathData = (fabricPath) => {
    if (!fabricPath || fabricPath.type !== "path") {
      console.error("The provided object is not a valid Fabric.js path.");
      return null;
    }

    // Extract the raw path array
    const rawPathData = fabricPath.path;

    // Convert it into a valid SVG path data string
    const svgPathData = rawPathData
      .map((segment) => segment.join(" "))
      .join(" ");

    return svgPathData;
  };

  const calculatePathLength = (svgPathData) => {
    // Create a temporary SVG element
    const svgNamespace = "http://www.w3.org/2000/svg";
    const svg = document.createElementNS(svgNamespace, "svg");
    const path = document.createElementNS(svgNamespace, "path");

    // Set the path's 'd' attribute
    path.setAttribute("d", svgPathData);

    // Append the path to the SVG element
    svg.appendChild(path);
    document.body.appendChild(svg); // Add it temporarily to the DOM

    // Measure the total length of the path
    const totalLength = path.getTotalLength();

    // Clean up by removing the SVG element
    document.body.removeChild(svg);

    return totalLength;
  };

  const centerTextAlongPath = (textObject, pathObject) => {
    if (!textObject || !pathObject) return;

    // Get the length of the path
    // Get the path length (use width as an approximation if getTotalLength is unavailable)
    // const pathLength = pathObject.getTotalLength
    //   ? pathObject.getTotalLength()
    //   : pathObject.getBoundingRect().width;
    // const pathLength = pathObject.width;
    const pathLength = calculatePathLength(getPathData(pathObject));

    // Measure the total width of the text
    const ctx = fabricCanvasGlobal.getContext();
    // Set the canvas context's font to match the Fabric.js text object
    ctx.font = `${textObject.fontWeight || "normal"} ${textObject.fontSize}px ${
      textObject.fontFamily || "Arial"
    }`;
    const textWidth = ctx.measureText(textObject.text).width;
    // const textWidth = textObject.width;

    // Calculate the offset to center the text
    const centerOffset = (pathLength - textWidth) / 2;
    //console.log("BREAKPOINT textWidth= " + textWidth);
    //console.log("BREAKPOINT pathLength= " + pathLength);
    //console.log("BREAKPOINT centerOffset= " + centerOffset);

    // Update the text object's pathStartOffset
    textObject.set({
      pathStartOffset: centerOffset,
    });

    textObject.setCoords();

    fabricCanvasGlobal.requestRenderAll();
  };

  const renderTextWithBoundingRect = (textObject, pathObject) => {
    const ctx = fabricCanvasGlobal.getContext();
    const pathBoundingBox = pathObject.getBoundingRect();

    // Set the canvas context's font to match the Fabric.js text object
    ctx.font = `${textObject.fontWeight || "normal"} ${textObject.fontSize}px ${
      textObject.fontFamily || "Arial"
    }`;

    let currentText = "";
    let currentWidth = 0;
    //console.log(
    //   "BREAKPOINT selectedObject.fontSize= " + selectedObject.fontSize
    // );
    //console.log("BREAKPOINT selectedObject.width= " + selectedObject.width);
    //console.log("BREAKPOINT pathObject.width= " + pathObject.width);
    const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

    for (let char of textObject.text) {
      // const charWidth = ctx.measureText(char).width;
      const intrinsicCharWidth = ctx.measureText(char).width;
      const scaledCharWidth = intrinsicCharWidth * scaleFactor;

      //console.log(
      //   "BREAKPOINT char= " + char + ", scaledCharWidth= " + scaledCharWidth
      // );
      // Check if adding the character exceeds the bounding rect
      if (currentWidth + scaledCharWidth > pathObject.width) break;

      currentText += char;
      currentWidth += scaledCharWidth;
    }
    //console.log(
    //   "BREAKPOINT currentText= " +
    //     currentText +
    //     ", currentWidth= " +
    //     currentWidth +
    //     ", pathBoundingBox.width= " +
    //     pathBoundingBox.width
    // );

    textObject.text = currentText; // Update the text
    fabricCanvasGlobal.requestRenderAll();
  };

  React.useEffect(() => {
    if (isOpenTextTransformation) {
      centerTextAlongPath(selectedObject, pathRef.current);
      //console.log("BREAKPOINT useEffect pathRef.current=" + pathRef.current);
    }
  }, [selectedObject.fontSize, selectedObject.fontFamily, pathRef.current]);

  React.useEffect(() => {
    handleTransformationClick(activeTransformation);
  }, [activeTransformation, angleCurve]);

  const handleTransformationClick = (option) => {
    //console.log("COUNTER HANDLE CLICK");
    setActiveTransformation(option); // Set the clicked transformation as active
    // Apply transformation logic here based on the selected option
    if (option === "Circle") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });

      const generateCirclePath = (cx, cy, angleCurve) => {
        // Convert angleCurve to radius (control the strength of the arc)
        // Ensure a minimum radius to prevent weird rendering
        const minRadius = 10;
        const maxRadius = 500;
        const normalized = Math.max(-100, Math.min(100, angleCurve)); // clamp
        const r =
          minRadius + (Math.abs(normalized) / 100) * (maxRadius - minRadius);

        return `
          M ${cx - r}, ${cy}
          a ${r},${r} 0 1,0 ${r * 2},0
          a ${r},${r} 0 1,0 ${-r * 2},0
        `;
      };

      // Create the circular path
      const centerX = 200;
      const centerY = 200;
      const angleCurve = 70; // between -100 and 100
      const pathString = generateCirclePath(centerX, centerY, angleCurve);

      const circularPath = new fabric.Path(pathString, {
        fill: "",
        stroke: "transparent",
        visible: false,
      });

      // `M 150 150 m -100, 0 a ${angleCurve * 2},${
      //   angleCurve * 2
      // } 0 1,0 200,0 a ${angleCurve * 2},${angleCurve * 2} 0 1,0 -200,0`
      // Make the path invisible but usable
      circularPath.set({
        fill: "",
        // stroke: "#87CEEB",
        stroke: "",
        selectable: false,
      });
      //   setPathText(circularPath);
      // fabricCanvasGlobal.add(circularPath);

      selectedObject.set({
        path: circularPath, // Attach the path
        // pathStartOffset: 0, // Adjust the starting position along the path
        pathSide: "left", // 'left' or 'right' for positioning on the path
        pathAlign: "center", // 'center', 'baseline', or 'top'
        selectable: true,
      });
      centerTextAlongPath(selectedObject, circularPath);
      pathRef.current = selectedObject.path; // Assign path to the ref
      // if (selectedObject) selectedObject.path.stroke = "#87CEEB";
      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Arc") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });

      const centerX = 200; // X-coordinate of the circle center
      const centerY = 200; // Y-coordinate of the circle center
      const generateArcPath = (radius, direction = "up") => {
        const sweepFlag = direction === "up" ? 0 : 1;
        const startX = centerX - radius;
        const endX = centerX + radius;

        return `M ${startX} ${centerY}
                A ${radius},${radius} 0 0,${sweepFlag} ${endX},${centerY}`;
      };
      const radius = Math.abs(angleCurve) * 5; // Adjust multiplier as needed
      const direction = angleCurve >= 0 ? "up" : "down";

      const arcPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        generateArcPath(radius, direction)
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      arcPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: arcPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, arcPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Arch") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });
      // Define an arch path using SVG path data
      function mapSliderValue(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = -50;
        const newMax = 700;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: mapSliderValue(angleCurve) };
      let point_f = { x: temp, y: angleCurve * 1.5 };
      // let temp = selectedObject.getScaledWidth();
      //console.log("BREAKPOINT temp= " + temp);
      const archPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M 0 350 Q 400 ${point_i.y} ${temp} 350`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      archPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: archPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, archPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Angle") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });
      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: angleCurve * 3 };
      let point_f = { x: temp, y: angleCurve * 1.5 };
      // let temp = selectedObject.getScaledWidth();
      //console.log("BREAKPOINT temp= " + temp);
      const risingPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${point_i.x} ${point_i.y} L ${point_f.x} ${point_f.y}`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      risingPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: risingPath,
        pathAlign: "center",
        pathStartOffset: offset,
        pathSide: "left",
        skewY: angleCurve / 5,
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, risingPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Rise") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleX: 1, scaleY: 1 });
      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: 300 };
      let point_f = { x: temp, y: angleCurve + 300 };
      // let temp = selectedObject.getScaledWidth();
      //console.log("BREAKPOINT temp= " + temp);
      const archPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${point_i.x} ${point_i.y} C 400 300 400 ${point_f.y} ${point_f.x} ${point_f.y}`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      archPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: archPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
        skewX: angleCurve / 5,
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, archPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Wave") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleY: 1, scaleX: 1 });
      function mapSliderValue(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 100;
        const newMax = 600;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }
      function mapSliderValue2(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 600;
        const newMax = 100;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }

      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 0, y: mapSliderValue(angleCurve) };
      let point_f = { x: temp, y: angleCurve + 300 };

      let controlPoints = [
        { x: 50, y: 300 }, // Starting point
        { x: 150, y: mapSliderValue(angleCurve) }, // Control point for first curve
        { x: 600, y: mapSliderValue2(angleCurve) }, // End spoint of first curve, start of next
        // { x: 350, y: 350 }, // Control point for second curve
        { x: temp, y: 300 }, // End point of second curve
      ];
      // let temp = selectedObject.getScaledWidth();
      //console.log("BREAKPOINT temp= " + temp);
      let wavePath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${controlPoints[0].x} ${controlPoints[0].y} ` +
          `C ${controlPoints[1].x} ${controlPoints[1].y} ${controlPoints[2].x} ${controlPoints[2].y} ` +
          `${controlPoints[3].x} ${controlPoints[3].y}`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      wavePath.set({
        fill: "",
        stroke: "",
        selectable: false,
        left: selectedObject.left,
        top: selectedObject.top,
      });

      // fabricCanvasGlobal.add(wavePath);

      // Set the path and add to canvas
      selectedObject.set({
        path: wavePath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
        skewX: -angleCurve / 4,
        scaleY: 1.5,
      });

      let difLeftX = selectedObject.left - controlPoints[0].x;
      let difLeftY = selectedObject.top - controlPoints[0].y;

      //console.log(
      //   "BREAKPOINT selectedObject.path= " +
      //     JSON.stringify(selectedObject.path, null, 2)
      // );

      //console.log(
      //   "BREAKPOINT selectedObject= " + JSON.stringify(selectedObject, null, 2)
      // );

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, wavePath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Flag") {
      // selectedObject.set({ skewX: 0, skewY: 0, scaleX: 1, scaleY: 1 });
      function mapSliderValue(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 50;
        const newMax = 550;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }
      function mapSliderValue2(x) {
        const oldMin = -100;
        const oldMax = 100;
        const newMin = 550;
        const newMax = 50;

        // Map the slider value
        return newMin + ((x - oldMin) / (oldMax - oldMin)) * (newMax - newMin);
      }

      // Define an arch path using SVG path data
      let temp = artBoardSettings.width / 2;
      let point_i = { x: 50, y: 300 };
      let point_f = {
        x: mapSliderValue2(angleCurve),
        y: mapSliderValue(angleCurve),
      };
      // let temp = selectedObject.getScaledWidth();
      //console.log("BREAKPOINT temp= " + temp);
      const archPath = new fabric.Path(
        // "M 0 450 A 50 50 0 1 1 700 450  " // SVG path for an arch
        // "M 100 300 Q 400 100 700 300"
        `M ${point_i.x} ${point_i.y} C 150 ${point_f.x} 600 ${point_f.y} 700 300`
        // `M 0 450 A 50 50 0 1 1 ${temp} 450`
      );
      // Make the path invisible but usable
      archPath.set({
        fill: "",
        stroke: "",
        selectable: false,
      });

      // Set the path and add to canvas
      selectedObject.set({
        path: archPath,
        pathAlign: "center",
        // pathStartOffset: 0,
        pathSide: "left",
        skewX: angleCurve / 5,
      });

      // renderTextWithBoundingRect(selectedObject, archPath);
      centerTextAlongPath(selectedObject, archPath);

      // Update the text object to reflect changes
      selectedObject.setCoords();

      pathRef.current = selectedObject.path; // Assign path to the ref

      fabricCanvasGlobal.requestRenderAll();
    }
  };

  // Track the `pathText` state update
  // React.useEffect(() => {
  //   if (pathText) {
  //     //console.log("PathText state updated:", pathText);
  //   }
  // }, [pathText]);

  React.useEffect(() => {
    if (activeTransformation == null) handleTransformationClick("Circle");
  }, []);

  React.useEffect(() => {
    if (!isOpenTextTransformation) {
      selectedObject.set({
        path: null,
        skewX: 0,
        skewY: 0,
        // scaleX: 1,
        // scaleY: 1,
      });
      pathRef.current = null; // Reset the path in the ref
      setActiveTransformation(null);
      setIsChildListenerActive(false);
    } else if (isOpenTextTransformation && activeTransformation == null) {
      setIsChildListenerActive(false);
    } else if (isOpenTextTransformation && activeTransformation != null) {
      setIsChildListenerActive(true);
      if (fabricCanvasGlobal.getActiveObject() == null)
        selectedObject.path.set({ stroke: "" });
    }
    fabricCanvasGlobal.requestRenderAll();
  }, [isOpenTextTransformation, activeTransformation]);

  React.useEffect(() => {
    // setIsChildListenerActive(true);
    if (!fabricCanvasGlobal) return;

    let timeout;

    const handleObjectScaling = (e) => {
      if (!isChildListenerActive) return;
      //console.log("BREAKPOINT child listener");
      const activeObject = e.target;
      // //console.log("BREAKPOINT pathText= " + pathText);

      if (activeObject && activeObject.type === "i-text") {
        clearTimeout(timeout);

        // Debounce to avoid multiple updates
        timeout = setTimeout(() => {
          const boundingRect = activeObject.getBoundingRect();
          const scaleFactor = fabricCanvasGlobal.getZoom() || 1;

          const updatedWidth = boundingRect.width / scaleFactor;
          const updatedHeight = boundingRect.height / scaleFactor;

          // Calculate the updated fontSize based on the object's scale
          const updatedFontSize = activeObject.fontSize * activeObject.scaleX;

          // If the text object has a path, synchronize the path scaling

          const path = pathRef.current; // Access the path via the ref
          //console.log("BREAKPOINT path= " + path);
          if (path) {
            const pathBoundingRect = path.getBoundingRect();
            const pathScaleFactorX =
              boundingRect.width / pathBoundingRect.width;
            const pathScaleFactorY =
              boundingRect.height / pathBoundingRect.height;

            path.set({
              scaleX: pathScaleFactorX,
              scaleY: pathScaleFactorY,
            });

            const textCenterX = boundingRect.left + boundingRect.width / 2;
            const textCenterY = boundingRect.top + boundingRect.height / 2;

            path.set({
              left: textCenterX,
              top: textCenterY,
            });

            path.setCoords();

            // fabricCanvasGlobal.add(path);

            activeObject.set({
              path: path,
            });

            //console.log(
            //   "activeObject Bounding Rect:",
            //   activeObject.path.getBoundingRect()
            // );
            //console.log("Path Bounding Rect:", path.getBoundingRect());

            // //console.log(
            //   `Path Scaled to: ${pathScaleFactorX}, ${pathScaleFactorY}`
            // );
          }

          // Re-render the canvas
          fabricCanvasGlobal.requestRenderAll();
        }, 100); // Debounce interval (100ms)
      }
    };

    // Add the scaling event listener
    if (isChildListenerActive)
      fabricCanvasGlobal.on("object:scaling", handleObjectScaling);

    // Cleanup on component unmount
    return () => {
      //console.log("EXITING COMPONENT");
      clearTimeout(timeout);
      if (isChildListenerActive)
        fabricCanvasGlobal.off("object:scaling", handleObjectScaling);
    };
  }, [fabricCanvasGlobal, setIsChildListenerActive]);

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 400,
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      {/* Header with Toggle Button */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Transformation</Typography>
        <IconButton onClick={handleToggle}>
          {isOpenTextTransformation ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>

      {/* Content (conditionally rendered) */}
      {isOpenTextTransformation && (
        <>
          {/* Transformation Options */}
          <Grid container spacing={1} sx={{ marginBottom: 2 }}>
            {[
              // "Custom",
              // "Distort",
              "Circle",
              "Arc",
              "Angle",
              "Arch",
              "Rise",
              "Wave",
              "Flag",
            ].map((option) => (
              <Grid item xs={3} key={option}>
                <Button
                  variant={
                    activeTransformation === option ? "text" : "outlined"
                  }
                  disabled={activeTransformation === option ? true : false}
                  //   variant="outlined"
                  //   color={activeTransformation === option ? "success" : "error"}
                  onClick={() => handleTransformationClick(option)}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    padding: "8px",
                    fontSize: "12px",
                  }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>

          {/* Slider and Angle Curve */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            {activeTransformation} Curve
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Slider
              value={angleCurve}
              onChange={handleAngleCurveChange}
              min={-100}
              max={100}
              sx={{ flex: 1 }}
            />
            <TextField
              value={angleCurve}
              onChange={(e) => setAngleCurve(parseInt(e.target.value) || 0)}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
              sx={{ width: 80 }}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TextTransformationComponent;
