import React, { useState, useContext } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
// import { Container } from "@mui/material";
import Box from "@mui/material/Box";
// import TextField from "@mui/material/TextField";
// import SearchIcon from "@mui/icons-material/Search";
import "./css/canvas.css";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
// import TitleIcon from "@mui/icons-material/Title";

//Sample images import
import image1 from "../assets/text1.jpg";
import image2 from "../assets/text2.jpg";
import image3 from "../assets/text3.jpg";
import image4 from "../assets/text4.jpg";
import image5 from "../assets/text5.jpg";
import image6 from "../assets/text6.jpg";

import { fabric } from "fabric";
import { setText } from "./redux-slices/TextSlice";
import { useDispatch } from "react-redux";
import TextEditingInterface from "./TextEditingInterface";
import { useMediaQuery } from "@mui/material";
import { CanvasContext } from "./CanvasContext";

const itemData = [
  { id: 1, img: image1, title: "example image 1" },
  { id: 2, img: image2, title: "example image 2" },
  { id: 3, img: image3, title: "example image 3" },
  {
    id: 4,
    img: image4,
    title: "example image 4",
  },
  {
    id: 5,
    img: image5,
    title: "example image 5",
  },
  { id: 6, img: image6, title: "example image 6" },
];

const CanvasMenuItemTypography = () => {
  const { fabricCanvasGlobal } = useContext(CanvasContext);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const addText = () => {
    const text = new fabric.IText("Test Text", {
      left: 100,
      top: 100,
      fontFamily: "anton",
      fill: "black",
      fontSize: 60,
    });
    // //console.log("Type of text in addText= " + typeof text);
    // dispatch(setText());
    fabricCanvasGlobal.add(text);
    fabricCanvasGlobal.renderAll();
  };

  const handleimageClick = () => {};

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ color: "white" }}>
        <h3 style={{ textAlign: "center" }}>Text</h3>
        <Stack
          direction="column"
          spacing={2}
          sx={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Button onClick={addText} variant="contained">
            Add Text
          </Button>
          {/* <TextEditingInterface></TextEditingInterface> */}
        </Stack>
        <ImageList
          sx={{ width: "100%", height: "auto" }}
          cols={!matchesXS ? 2 : 6}
        >
          {/* {itemData.map((item, index) => (
            <ImageListItem key={item.index}>
              <img
                srcSet={`${item.img} 2x`} // Simplified for testing
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                onClick={handleimageClick}
                className="image-hover-effect"
              />
            </ImageListItem>
          ))} */}
        </ImageList>
      </Box>
    </ThemeProvider>
  );
};
export default CanvasMenuItemTypography;
