import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Tooltip from "@mui/material/Tooltip";
import {
  CircularProgress,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setProductType } from "../redux-slices/TemplateSlice";
import { useNavigate } from "react-router-dom";
import { setBasketProduct, setListing } from "../redux-slices/ProductSlice";
import CustomizeProductCanvas from "./CustomizeProductCanvas";
import { CanvasContext } from "../CanvasContext";
import CustomTextPopover from "./CustomTextPopover";
import CustomImageUploader from "./CustomImageUploader";

import { backgroundRemoverREMBGGoogle } from "../image-processing-tools";
import { setImgTemplateURL } from "../redux-slices/ImageSlice";
import ProductColorOptions from "./ProductColorOptions";
import { useParams } from "react-router-dom";
import { fabric } from "fabric";
import { setDesignFileURL } from "../redux-slices/ImageSlice";
import { Backdrop } from "@mui/material";
import DesignLoader from "./Designs/DesignLoader";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../../theme";
import { Helmet } from "react-helmet";
import CustomProductPreview from "./CustomProductPreview";
import { removeBG } from "../Custom Hooks/CommonEffectsDesign";
import CustomizeProductCanvasV2 from "./CustomizeProductCanvasV2";
import DesignLoaderV2 from "./Designs/DesignLoaderV2";
import { Grid } from "@mui/material";
import CustomTextPopoverV2 from "./CustomTextPopoverV2";
import { setProductPreviewMockupURL } from "../redux-slices/ImageSlice";
import CustomImageUploaderV from "./CustomImageUploaderV2";
import CustomImageUploaderV2 from "./CustomImageUploaderV2";

// const style = {
//   position: "absolute",
//   //   position: "relative",
//   //   top: "50%",
//   //   left: "50%",
//   //   transform: "translate(-50%, -50%)",
//   width: "80%",
//   height: "60%",
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   padding: "5%",
//   m: 10,
//   //   display: "flex",
//   //   flexDirection: "column",
//   //   alignItems: "center",
//   //   justifyContent: "center",
//   overflow: "auto",
// };

export default function CustomizeProductComponentV2() {
  const { listingId, productBrand, designId } = useParams();

  const {
    fabricCanvasGlobal,
    productColorOption,
    productColor,
    fabricCanvasPreview,
    selectedObject,
  } = React.useContext(CanvasContext);
  // const downloadImageCanvas = useDesignCanvasDownloader(
  //   productCanvasRef.current
  // );
  // const downloadImageURL = useImageDownloader();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const imgTemplateURL = useSelector((state) => state.image.imgTemplateURL);
  const [closeButton, setCloseButton] = useState(false);
  const [confirmDesign, setConfirmDesign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // //console.log("BREAKPOINT inside ViewMockup productType= " + productType);
  const [open, setOpen] = React.useState(true);
  const [product, setProduct] = useState(null);
  const [viewMockup, setViewMockup] = useState(false);
  const designRef = React.useRef(null);
  const [localCanvas, setLocalCanvas] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null);
  const [disableRemoverButton, setDisableRemoverButton] = useState(true);

  let canvasWidth,
    canvasHeight,
    canvasZoomRationDesktop,
    canvasZoomRationTablet,
    canvasZoomRationDesktopXL,
    previewRatio,
    canvasZoomRationMobile;
  if (productBrand === "Bella Canvas 3001") {
    canvasHeight = 4800;
    canvasWidth = 3951;
    canvasZoomRationDesktop = 0.4;
    canvasZoomRationMobile = 0.3;
    canvasZoomRationDesktopXL = 0.55;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "Gildan 18000") {
    canvasHeight = 5100;
    canvasWidth = 4500;
    canvasZoomRationDesktop = 0.4;
    canvasZoomRationDesktopXL = 0.55;
    canvasZoomRationMobile = 0.3;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "Gildan 18500") {
    canvasHeight = 3000;
    canvasWidth = 4500;
    canvasZoomRationDesktop = 0.3;
    canvasZoomRationDesktopXL = 0.36;
    canvasZoomRationMobile = 0.3;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "Bella Canvas 3480") {
    canvasHeight = 3600;
    canvasWidth = 3000;
    canvasZoomRationDesktop = 0.3;
    canvasZoomRationDesktopXL = 0.45;
    canvasZoomRationMobile = 0.2;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "iPhone 15 Phone Case") {
    canvasHeight = 2085;
    canvasWidth = 1246;
    canvasZoomRationDesktop = 0.5;
    canvasZoomRationDesktopXL = 0.65;
    canvasZoomRationMobile = 0.2;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "iPhone 15 Pro Phone Case") {
    canvasHeight = 2085;
    canvasWidth = 1246;
    canvasZoomRationDesktop = 0.5;
    canvasZoomRationDesktopXL = 0.65;
    canvasZoomRationMobile = 0.2;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "iPhone 16 Phone Case") {
    canvasHeight = 2085;
    canvasWidth = 1246;
    canvasZoomRationDesktop = 0.5;
    canvasZoomRationDesktopXL = 0.65;
    canvasZoomRationMobile = 0.2;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  } else if (productBrand === "iPhone 16 Pro Phone Case") {
    canvasHeight = 2085;
    canvasWidth = 1246;
    canvasZoomRationDesktop = 0.5;
    canvasZoomRationDesktopXL = 0.65;
    canvasZoomRationMobile = 0.2;
    canvasZoomRationTablet = 0.2;
    previewRatio = 1.8;
  }

  const generateImageFromCanvas = async () => {
    if (fabricCanvasGlobal) {
      // ✅ Remove viewport transformations (prevents scaling issues)
      const originalTransform = fabricCanvasGlobal.viewportTransform;
      fabricCanvasGlobal.viewportTransform = [1, 0, 0, 1, 0, 0];

      // ✅ Ensure canvas is set to full size
      fabricCanvasGlobal.setZoom(1); // Reset zoom before export
      fabricCanvasGlobal.renderAll(); // Force refresh

      const dataURL = fabricCanvasGlobal.toDataURL({
        format: "png",
        multiplier: 1, // Keep at 1 to preserve original resolution
        // width: 3900, // Ensure full width
        // height: 4800, // Ensure full height
        width: fabricCanvasGlobal.extraProperties.canvasWidth,
        height: fabricCanvasGlobal.extraProperties.canvasHeight,
        enableRetinaScaling: false,
      });

      // ✅ Restore the original viewport transform after export
      fabricCanvasGlobal.viewportTransform = originalTransform;
      fabricCanvasGlobal.renderAll(); // Re-render to restore UI

      // ✅ Debugging Logs
      // //console.log("Canvas Export Width:", fabricCanvasGlobal.width);
      // //console.log("Canvas Export Height:", fabricCanvasGlobal.height);
      // //console.log("Exported Data URL:", dataURL.substring(0, 100)); // Check first 100 chars

      // ✅ Trigger download
      // const link = document.createElement("a");
      // link.href = dataURL;
      // link.download = "canvas-full-size.png";
      // link.click();

      return dataURL;
    } else {
      console.error("Canvas is not available.");
    }
  };

  const generateProductPreviewFromCanvas = async () => {
    if (fabricCanvasPreview) {
      // Generate the image as a Data URL (Base64 string)
      const dataURL = fabricCanvasPreview.toDataURL({
        format: "jpeg", // Output format: "png" or "jpeg"
        // quality: 1.0, // Quality for "jpeg" (0-1), not applicable for "png"
        multiplier: 1, // Scale factor (e.g., 2 for double size)
      });

      // setImg(dataURL);
      // //console.log("Canvas width:", localCanvas.width);
      // //console.log("Canvas height:", localCanvas.height);
      // //console.log("Retina scaling factor:", localCanvas.getRetinaScaling());
      // //console.log("BREAKPOINT dataURL= " + dataURL);

      // Optional: Download the image
      // const link = document.createElement("a");
      // link.href = dataURL;
      // link.download = "canvas-image";
      // link.click();
      // if (approvedProductImg.length < 3)
      //   setApprovedProductImg((prevApprovedProductImg) => [
      //     ...prevApprovedProductImg,
      //     { imgURL: dataURL, imgTitle: productColor.title },
      //   ]);
      return dataURL; // You can use this URL to display or store the image
    } else {
      console.error("Canvas is not available.");
    }
  };

  // const handleRemoveBackground = async () => {
  //   setLoading(true); // Set loading to true before starting the background removal
  //   // //console.log("BREAKPOINT=" + imgTemplateURL);
  //   removeBG(imgTemplateURL, setLoading)
  //     .then((url) => {
  //       dispatch(setImgTemplateURL(url));
  //       setLoading(false); // Set loading to false after the operation is complete
  //       setError(false);
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       // //console.log("Error processing the image: " + error);
  //       setLoading(false); // Set loading to false if there is an error
  //     })
  //     .finally(() => {
  //       setLoading(false); // Stop the spinner
  //     });
  // };

  // const handleRemoveBackground = async () => {
  //   setLoading(true);

  //   if (!selectedObject || selectedObject.type !== "image") {
  //     console.error("No image selected or object is not an image.");
  //     setLoading(false);
  //     return;
  //   }

  //   let imageUrl = selectedObject.getSrc();

  //   try {
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();

  //     const formData = new FormData();
  //     formData.append("image", blob, "image.png");
  //     // ✅ Send to background remover
  //     const newImageURL = await removeBG(formData, setLoading);

  //     if (newImageURL) {
  //       selectedObject.setSrc(newImageURL, () => {
  //         fabricCanvasGlobal.renderAll();
  //       });
  //     } else {
  //       console.error("Failed to remove background.");
  //     }
  //   } catch (error) {
  //     console.error("Error processing image:", error);
  //     setError(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleRemoveBackground = async (retries = 10, delay = 2000) => {
    setLoading(true);

    if (!selectedObject || selectedObject.type !== "image") {
      console.error("No image selected or object is not an image.");
      setLoading(false);
      return;
    }

    let imageUrl = selectedObject.getSrc();

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const formData = new FormData();
      formData.append("image", blob, "image.png");

      const result = await fetch(
        `https://rembg-app-lh3hdxifra-ue.a.run.app/remove-bg`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!result.ok) {
        throw new Error(`HTTP error! Status: ${result.status}`);
      }

      const outputBlob = await result.blob();
      const newImageURL = URL.createObjectURL(outputBlob);

      selectedObject.setSrc(newImageURL, () => {
        fabricCanvasGlobal.renderAll();
      });

      setLoading(false);
    } catch (error) {
      if (retries > 0) {
        console.warn(
          `Retrying background removal... (${retries} retries left)`
        );

        // Wait before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));

        // Recursive retry with reduced attempts
        return handleRemoveBackground(retries - 1, delay); // Increases delay slightly to avoid rate limit
      } else {
        console.error("Background removal failed after all retries.", error);
        setError(true);
        setLoading(false);
      }
    }
  };

  // const handleRemoveBackground = async () => {
  //   setLoading(true);

  //   if (!selectedObject || selectedObject.type !== "image") {
  //     console.error("No image selected or object is not an image.");
  //     setLoading(false);
  //     return;
  //   }

  //   let imageUrl = selectedObject.getSrc();

  //   try {
  //     const response = await fetch(imageUrl);
  //     const blob = await response.blob();

  //     const formData = new FormData();
  //     formData.append("image", blob, "image.png");

  //     const result = await fetch(
  //       `https://rembg-app-lh3hdxifra-ue.a.run.app/remove-bg`,
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     if (!result.ok) {
  //       throw new Error(`HTTP error! Status: ${result.status}`);
  //     }

  //     const outputBlob = await result.blob();
  //     const newImageURL = URL.createObjectURL(outputBlob);

  //     selectedObject.setSrc(newImageURL, () => {
  //       fabricCanvasGlobal.renderAll();
  //     });
  //   } catch (error) {
  //     console.error("Error removing background:", error);
  //     setError(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  function downloadCanvasAsImage(fabricCanvas) {
    return new Promise((resolve, reject) => {
      // Create a temporary canvas
      const tempCanvas = new fabric.StaticCanvas(null, {
        width: canvasWidth,
        height: canvasHeight,
        enableRetinaScaling: false,
      });

      const clonePromises = fabricCanvas.getObjects().map((obj) => {
        return new Promise((resolve) => {
          obj.clone((clone) => {
            resolve(clone);
          });
        });
      });

      Promise.all(clonePromises)
        .then((clonedObjects) => {
          clonedObjects.forEach((clone) => {
            tempCanvas.add(clone);
          });

          tempCanvas.renderAll();

          setTimeout(() => {
            const dataURL = tempCanvas.toDataURL({
              format: "png",
              multiplier: 0.5,
            });

            dispatch(setDesignFileURL(dataURL));

            tempCanvas.dispose();
            resolve(); // Resolve the promise after the operations are complete
          }, 500); // Adjust timeout as necessary to ensure canvas has finished rendering
        })
        .catch((error) => {
          // //console.log("BREAKPOINT");
          reject(error); // Reject the promise if there is an error
        });
    });
  }

  const handleClose = () => {
    // downloadCanvasAsImage(fabricCanvasGlobal);
    setCloseButton(true);
    setOpen(false);
    setImgTemplateURL("");
    dispatch(setProductType("T-shirt"));
    navigate(-1);
  };

  const handleConfirmDesignClick = async () => {
    // //console.log("BREAKPOINT productColorOption = " + productColorOption);
    // //console.log("BREAKPOINT productColor.img = " + productColor.img);
    if (productColorOption === productColor.img) {
      try {
        // await downloadCanvasAsImage(fabricCanvasGlobal);
        const dataURL = await generateImageFromCanvas();
        dispatch(setDesignFileURL(dataURL));
        const previewURL = await generateProductPreviewFromCanvas();
        dispatch(setProductPreviewMockupURL(previewURL));
        // //console.log("BREAKPOINT dataURL= " + dataURL);
        // ✅ Trigger download
        // const link = document.createElement("a");
        // link.href = dataURL;
        // link.download = "canvas-full-size.png";
        // link.click();
        setConfirmDesign(true);
        setCloseButton(true);
        setOpen(false);
        navigate(-1);
      } catch (error) {
        // console.error("Error downloading canvas as image:", error);
        // Handle the error if necessary
      }
    }
  };
  let colorOptions;
  if (matchesXS) {
    colorOptions = {
      overflow: "auto",
      width: "100%",
      zIndex: 2,
    };
  } else {
    colorOptions = {
      position: "absolute",
      left: "3%",
      top: "10%",
      overflow: "auto",
      height: "50%",
      zIndex: 2,
      //   backgroundColor: "green",
    };
  }

  React.useEffect(() => {
    setDisableRemoverButton(true);
    if (selectedObject && selectedObject.type === "image") {
      setDisableRemoverButton(false);
    }
  }, [selectedObject]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Helmet>
          <title>Customize Your Product | Okay Artist</title>
          <meta
            name="description"
            content={`Design your custom ${productBrand} with ${designId} design at Okay Artist. Create your unique product with our design editor.`}
          />
          <meta
            name="keywords"
            content={`custom product, ${productBrand}, design editor, ${designId}, personalized clothing, okay artist`}
          />
          <meta
            property="og:title"
            content="Customize Your Product | Okay Artist"
          />
          <meta
            property="og:description"
            content={`Use Okay Artist to customize your ${productBrand} with the ${designId} design template. Perfect for creating a one-of-a-kind product.`}
          />
          <meta
            property="og:url"
            content="https://www.okayartist.com/customize"
          />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content="Customize Your Product | Okay Artist"
          />
          <meta
            name="twitter:description"
            content={`Create and customize your own ${productBrand} with our ${designId} design template.`}
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        {/* <Button onClick={handleOpen}>Open modal</Button> */}
        {/* <Tooltip title="View Mockup" placement="bottom" arrow disableInteractive>
        <IconButton onClick={handleOpen} color="primary">
          <PreviewIcon />
        </IconButton>
      </Tooltip> */}
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: {
              backgroundColor: "rgba(54, 176, 226, 0.5)",
              //   overflow: "auto",

              //   position: "relative",
              //   display: "flex",
              //   flexDirection: "column",
              //   alignItems: "center",
              //   justifyContent: "center",
            }, // Customize backdrop color
          }}
          disableEnforceFocus // ✅ Allow canvas focus for keyboard input
          disableAutoFocus // ✅ Prevent modal from stealing focus
        >
          <Grid
            container
            spacing={2}
            sx={{
              width: "80vw",
              height: "80vh",
              bgcolor: "white",
              p: 3,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              boxShadow: 24,
              overflow: "auto",
              borderRadius: "12px",
            }}
          >
            <Grid
              item
              xs={matchesXS ? 12 : 1}
              sx={{
                alignItems: "center",
                height: "70%",
                width: "auto",
                overflow: "auto",
                // backgroundColor: "green",
              }}
            >
              <Box
              // sx={{
              //   height: "60%",
              //   width: "auto",
              //   overflow: "auto",
              //   backgroundColor: "green",
              // }}
              >
                <ProductColorOptions
                  listingId={listingId}
                  productBrand={productBrand}
                />
              </Box>
            </Grid>
            {/* <DesignShirt1></DesignShirt1> */}
            {/* <FloatingToolbar></FloatingToolbar> */}

            <Grid
              item
              xs={matchesXS ? 12 : 10}
              sx={{
                display: "flex",
                justifyContent: "center",
                // backgroundColor: "gray",
                // alignItems: "center",
              }}
            >
              <Box
              // ref={modalRef}

              //   sx={{ width: "100%", height: "100%", position: "relative" }}
              >
                <DesignLoaderV2
                  localCanvas={localCanvas}
                  setCanvasImage={setCanvasImage}
                  setLocalCanvas={setLocalCanvas}
                  designName={designId}
                  productBrand={productBrand}
                  canvasWidth={canvasWidth}
                  canvasHeight={canvasHeight}
                  canvasZoomRationDesktop={canvasZoomRationDesktop}
                  canvasZoomRationMobile={canvasZoomRationMobile}
                  canvasZoomRationDesktopXL={canvasZoomRationDesktopXL}
                  canvasZoomRationTablet={canvasZoomRationTablet}
                  setLoading={setLoading}
                  // modalRef={modalRef}
                ></DesignLoaderV2>
                <CustomizeProductCanvasV2
                  designRef={designRef}
                  viewMockup={viewMockup}
                  setViewMockup={setViewMockup}
                  closeButton={closeButton}
                  confirmDesign={confirmDesign}
                  setConfirmDesign={setConfirmDesign}
                  previewRatio={previewRatio}
                  canvasImage={canvasImage}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={matchesXS ? 12 : 1}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                // backgroundColor: "red",
                alignItems: "center",
              }}
            >
              <Stack direction={"column"} spacing={2} sx={{ mt: "5%" }}>
                {/* <CustomImageUploader /> */}
                <CustomImageUploaderV2 />
                {/* <CustomTextPopover /> */}
                <CustomTextPopoverV2 />
                <Button
                  onClick={handleRemoveBackground}
                  variant="outlined"
                  // disabled={loading}
                  disabled={disableRemoverButton}
                  size="small"
                  //   sx={{ padding: 3 }}
                >
                  Remove Background
                </Button>
                {error && <Typography>Please try again!</Typography>}
                {/* <Button
                  onClick={() => {
                    setViewMockup(!viewMockup);
                  }}
                  variant="outlined"
                  // disabled={loading}
                  // size="small"
                >
                  View Mockup
                </Button> */}

                <Button
                  variant="outlined"
                  onClick={handleConfirmDesignClick}
                  size="small"
                >
                  Confirm Design
                </Button>
                <Button variant="text" onClick={handleClose}>
                  <Tooltip title="Close">
                    <CloseIcon />
                  </Tooltip>
                </Button>
              </Stack>
            </Grid>
            {/* <CustomProductPreview /> */}
          </Grid>
        </Modal>
      </Box>
      <Backdrop
        sx={{
          color: "#000000",
          position: "fixed", // Covers entire screen
          top: 0,
          left: 0,
          // width: "100vw", // Cover viewport width
          height: "100vh", // Cover viewport height
          zIndex: 2300, // Ensure it overlays everything
          backgroundColor: "rgba(210, 242,255, 0.5)",
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}
