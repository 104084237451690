// CanvasContext.js
import React, { createContext, useRef, useState } from "react";
import TShirtIcon from "@mui/icons-material/Checkroom";

export const CanvasContext = createContext();

export const CanvasProvider = ({ children }) => {
  const canvasRef = useRef(null);
  const productCanvasRef = useRef(null);
  const [canvasRefTemplate, setCanvasRefTemplate] = useState(null);
  const [fabricCanvas, setFabricCanvas] = useState(null);
  const [temp, setTemp] = useState("");
  const [canvasAspectRatio, setCanvasAspectRatio] = useState(1);
  const [imgAspectRatio, setImgAspectRatio] = useState(1);
  const [scaleGlobal, setScaleGlobal] = useState(1);
  const [open, setOpen] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const [imgURL, setImgURL] = useState("");
  const [fontSizing, setFontSizing] = React.useState(500);
  const [text, setText] = React.useState("");
  const [productColorOption, setProductColorOption] = useState(""); //to change the image source for product color
  const [saveClickProduct, setSaveClickProduct] = useState(false);
  const [fabricCanvasGlobal, setFabricCanvasGlobal] = useState(null);
  const [fabricCanvasPreview, setFabricCanvasPreview] = useState(null);
  const fabricCanvasPreviewRef = useRef(null);
  const mockupGeneratorCanvasRef = useRef(null);
  const [productCanvasActive, setProductCanvasActive] = useState(false);
  const [productColor, setProductColor] = useState({});

  const [productImgURL, setProductImgURL] = useState("");
  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
    email: "",
  });
  // const [textDetails, setTextDetails] = useState({
  //   font: "",
  //   size: "",
  //   color: "",
  //   fontWeight: "",
  // });
  const [font, setFont] = React.useState("Ayar Juno");
  const [formats, setFormats] = React.useState(() => []);
  const [textColor, setTextColor] = React.useState("#ffffff");
  const [renderCounter, setRenderCounter] = React.useState(0);
  const [artBoardSettings, setArtBoardSettings] = React.useState({
    width: 1500,
    height: 1500,
    unit: "px",
    dpi: 72,
    orientation: "portrait",
    backgroundColor: "",
    transparentBackground: false,
  });
  const [isOpenTextTransformation, setIsOpenTextTransformation] =
    React.useState(false);
  const [activeTransformation, setActiveTransformation] = useState(null);
  const [angleCurve, setAngleCurve] = useState(80); // State for slider value
  const [offset, setOffset] = useState(null); // State for slider value
  const [transformationConfirmed, setTransformationConfirmed] = useState(false);
  // const [imgWidth, setImgWidth] = useState(0);
  // const [imgHeight, setImgHeight] = useState(0);
  const [controlPoints, setControlPoints] = useState([
    { x: 50, y: 200 }, // Starting point
    { x: 150, y: 50 }, // Control point for first curve
    { x: 250, y: 200 }, // End point of first curve, start of next
    { x: 350, y: 350 }, // Control point for second curve
    { x: 450, y: 200 }, // End point of second curve
  ]);
  const [offsetX, setOffsetX] = useState(10);
  const [offsetY, setOffsetY] = useState(10);
  const [blur, setBlur] = useState(5);
  const [color, setColor] = useState("#727272");

  const [isOpenTextShading, setIsOpenTextShading] = React.useState(false);
  const [activeShading, setActiveShading] = useState(null);
  const offsetTextRef = useRef(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedObjectAttr, setSelectedObjectAttr] = useState({
    selectedObject: null,
    shading: null,
    transformation: null,
  });

  const [skewXValue, setSkewXValue] = useState(0);
  // Any additional functions to manipulate the canvas

  const [isOpenTextBorder, setIsOpenTextBorder] = React.useState(false);

  const [strokeSize, setStrokeSize] = React.useState(0);
  const [strokeColor, setStrokeColor] = React.useState("#ffffff");

  const [projectName, setProjectName] = useState("My Project");

  const [productImages, setProductImages] = useState([]);

  const [clippingObject, setClippingObject] = useState(null);
  const [clippingCheckbox, setClippingCheckbox] = useState(null);
  const [mask, setMask] = useState(null);
  const [lockCheckBox, setLockCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const productOptions = [
    {
      id: "Bella Canvas 3001",
      product: "T-Shirt",
      brand: "Bella Canvas 3001",
      icon: <TShirtIcon fontSize="large" />,
      shippingRateFirstItem: 5,
      shippingRateExtraItem: 3,
      designWidth: 3951,
      designHeight: 4800,
      imageMask:
        "https://product-images-okayartist.s3.us-east-1.amazonaws.com/generic-images/bella-canvas-3001-mockup-mask.svg",
    },
    {
      id: "Gildan 18000",
      brand: "Gildan 18000",
      product: "Sweatshirt",
      icon: <TShirtIcon fontSize="large" />,
      shippingRateFirstItem: 8,
      shippingRateExtraItem: 5,
      designWidth: 3852,
      designHeight: 4398,
      imageMask:
        "https://product-images-okayartist.s3.us-east-1.amazonaws.com/generic-images/gildan-18000-mockup-mask.svg",
    },
    {
      id: "Gildan 18500",
      brand: "Gildan 18500",
      product: "Hoodie",
      icon: <TShirtIcon fontSize="large" />,
      shippingRateFirstItem: 8,
      shippingRateExtraItem: 5,
      designWidth: 3531,
      designHeight: 2352,
      imageMask:
        "https://product-images-okayartist.s3.us-east-1.amazonaws.com/generic-images/gildan-18500-mockup-mask.svg",
    },
    {
      id: "Bella Canvas 3480",
      brand: "Bella Canvas 3480",
      product: "Tank Top",
      icon: <TShirtIcon fontSize="large" />,
      shippingRateFirstItem: 8,
      shippingRateExtraItem: 5,
      designWidth: 3210,
      designHeight: 3852,
      imageMask:
        "https://product-images-okayartist.s3.us-east-1.amazonaws.com/generic-images/bella-canvas-3480-mockup-mask.svg",
    },
    {
      id: "iPhone 15 Phone Case",
      brand: "iPhone 15 Phone Case",
      product: "Phone Case",
      icon: <TShirtIcon fontSize="large" />,
      shippingRateFirstItem: 5.5,
      shippingRateExtraItem: 3,
      designWidth: 3210,
      designHeight: 3852,
      imageMask:
        "https://product-images-okayartist.s3.us-east-1.amazonaws.com/generic-images/iPhone+15+Phone+Case+mask.svg",
    },
  ];
  const [isOpenTextGradient, setIsOpenTextGradient] = useState(null);
  const [gradientStops, setGradientStops] = useState([
    { offset: 0, color: "#ff0000" },
    { offset: 50, color: "#0000ff" },
    { offset: 100, color: "#000000" },
  ]);
  const [angle, setAngle] = useState(90); // Gradient angle in degrees
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const [selectedStopIndex, setSelectedStopIndex] = useState(null);

  const [maskArray, setMaskArray] = useState([]);
  const [selectedMaskId, setSelectedMaskId] = useState(null);

  return (
    <CanvasContext.Provider
      value={{
        fontSizing,
        setFontSizing,
        textColor,
        setTextColor,
        formats,
        setFormats,
        font,
        setFont,
        imgURL,
        setImgURL,
        saveClick,
        setSaveClick,
        canvasRef,
        canvasRefTemplate,
        setCanvasRefTemplate,
        fabricCanvas,
        setFabricCanvas,
        temp,
        setTemp,
        canvasAspectRatio,
        setCanvasAspectRatio,
        imgAspectRatio,
        setImgAspectRatio,
        scaleGlobal,
        setScaleGlobal,
        open,
        setOpen,
        productCanvasRef,
        text,
        setText,
        productColorOption,
        setProductColorOption,
        saveClickProduct,
        setSaveClickProduct,
        fabricCanvasGlobal,
        setFabricCanvasGlobal,
        fabricCanvasPreviewRef,
        productCanvasActive,
        setProductCanvasActive,
        productColor,
        setProductColor,
        productImgURL,
        setProductImgURL,
        shippingDetails,
        setShippingDetails,
        renderCounter,
        setRenderCounter,
        artBoardSettings,
        setArtBoardSettings,
        isOpenTextTransformation,
        setIsOpenTextTransformation,
        activeTransformation,
        setActiveTransformation,
        angleCurve,
        setAngleCurve,
        controlPoints,
        setControlPoints,
        skewXValue,
        setSkewXValue,
        offset,
        setOffset,
        transformationConfirmed,
        setTransformationConfirmed,
        offsetX,
        setOffsetX,
        offsetY,
        setOffsetY,
        blur,
        setBlur,
        color,
        setColor,
        isOpenTextShading,
        setIsOpenTextShading,
        activeShading,
        setActiveShading,
        offsetTextRef,
        selectedObject,
        setSelectedObject,
        isOpenTextBorder,
        setIsOpenTextBorder,
        strokeSize,
        setStrokeSize,
        strokeColor,
        setStrokeColor,
        projectName,
        setProjectName,
        mockupGeneratorCanvasRef,
        productImages,
        setProductImages,
        fabricCanvasPreview,
        setFabricCanvasPreview,
        clippingObject,
        setClippingObject,
        mask,
        setMask,
        clippingCheckbox,
        setClippingCheckbox,
        lockCheckBox,
        setLockCheckBox,
        loading,
        setLoading,
        productOptions,
        isOpenTextGradient,
        setIsOpenTextGradient,
        gradientStops,
        setGradientStops,
        angle,
        setAngle,
        colorPickerAnchor,
        setColorPickerAnchor,
        selectedStopIndex,
        setSelectedStopIndex,
        maskArray,
        setMaskArray,
        selectedMaskId,
        setSelectedMaskId,

        // imgWidth,
        // imgHeight,
        // setImgWidth,
        // setImgHeight,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
