import * as React from "react";
import { useState, useContext } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
// import TextEditingInterface from "../TextEditingInterface";
import TitleOutlinedIcon from "@mui/icons-material/TitleOutlined";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import ButtonGroup from "@mui/material/ButtonGroup";
import { Stack } from "@mui/material";
import ColorizeIcon from "@mui/icons-material/Colorize";

import { SketchPicker } from "react-color";
// import { useSelector, useDispatch } from "react-redux";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
// import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
// import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CanvasContext } from "../CanvasContext";
import TextSettings from "../DesignCanvasComponents/TextSettings";
import ImageSettings from "../DesignCanvasComponents/ImageSettings";
import TextSettingsV2 from "../DesignCanvasComponents/TextSettingsV2";

export default function CustomTextPopoverV2() {
  const {
    textDetails,
    setTextDetails,
    formats,
    setFormats,
    setFont,
    font,
    setTextColor,
    textColor,
    setFontSizing,
    text,
    setText,
    fontSizing,
    selectedObject,
    fabricCanvasGlobal,
  } = useContext(CanvasContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElColor, setAnchorElColor] = React.useState(null);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [disableTextButton, setDisableTextButton] = useState(true);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickColor = (event) => {
    setShowColorPicker(!showColorPicker);

    setAnchorElColor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseColor = () => {
    setAnchorElColor(null);
  };

  const open = Boolean(anchorEl);
  const openColor = Boolean(anchorElColor);
  const id = open ? "simple-popover" : undefined;
  const idColor = open ? "color-picker" : undefined;

  const theme = useTheme();
  const [fontType, setFontType] = useState("");
  // const [fontSize, setFontSize] = useState(20); // State to keep track of font size
  // const [textColor, setTextColor] = useState("#000000");
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleChange = (event) => {
    setFontType(event.target.value);
    setFont(event.target.value);
    // setFont(event.target.value);
  };

  const handleTextColorChange = (color) => {
    // const canvasRef = document.getElementById("canvas");
    setTextColor(color.hex);
  };
  // const [formats, setFormats] = React.useState(() => [
  //   "bold",
  //   "italic",
  //   "underlined",
  // ]);

  const handleFormat = (event, newFormats) => {
    //console.log("BREAKPOINT newformats= " + newFormats);
    setFormats(newFormats);
    // setTextDetails({ fontWeight: newFormats });
  };

  const buttons = [<Button key="one">+</Button>, <Button key="two">-</Button>];

  const [alignment, setAlignment] = React.useState("left");

  const handleChangeToggleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const children = [
    <ToggleButton value="left" key="left">
      <FormatAlignLeftIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="center" key="center">
      <FormatAlignCenterIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="right" key="right">
      <FormatAlignRightIcon fontSize="small" />
    </ToggleButton>,
    <ToggleButton value="justify" key="justify">
      <FormatAlignJustifyIcon fontSize="small" />
    </ToggleButton>,
  ];

  const control = {
    value: alignment,
    onChange: handleChangeToggleAlignment,
    exclusive: true,
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  React.useEffect(() => {
    setDisableTextButton(true);
    if (selectedObject && selectedObject.type === "i-text") {
      setDisableTextButton(false);
    }
  }, [selectedObject]);

  return (
    <div>
      {/* <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Open Popover
      </Button> */}
      <Tooltip title="Edit Text" placement="bottom" arrow disableInteractive>
        <Button
          variant="outlined"
          onClick={handleClick}
          color="primary"
          // size="small"
          disabled={disableTextButton}
        >
          {/* <TitleOutlinedIcon /> */}
          Adjust Text
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ padding: "20%" }}
      >
        <Box sx={{ p: 2 }}>
          {/* Apply padding here */}
          {/* <Typography>The content of the Popover.</Typography> */}

          <ThemeProvider theme={theme}>
            <Box
              sx={{
                transition:
                  "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
                opacity: open ? 1 : 0,
                transform: open ? "translateY(0)" : "translateY(-10px)",
              }}
              // id={id}
              // open={open}
              // anchorEl={anchorElText}
              // onClose={handleClose}
              // anchorOrigin={{
              //   vertical: "bottom",
              //   horizontal: "left",
              // }}
              // disableEnforceFocus // Allow focus on elements outside the popover
              // disableBackdropClick // Allow clicks outside the popover
            >
              {selectedObject && selectedObject.type === "i-text" ? (
                <TextSettingsV2
                  // onChange={handleTextSettingsChange}
                  // currentSettings={{
                  //   fontSize: selectedObject.fontSize || 16,
                  //   fontFamily: selectedObject.fontFamily || "Arial",
                  //   fill: selectedObject.fill || "#000000",
                  //   textAlign: selectedObject.textAlign || "left",
                  //   stroke: selectedObject.stroke || "",
                  //   strokeWidth: selectedObject.strokeWidth || 0,
                  //   opacity: (selectedObject.opacity || 1) * 100, // Convert decimal to percentage
                  // }}
                  selectedObject={selectedObject}
                  fabricCanvasGlobal={fabricCanvasGlobal}
                />
              ) : (
                <Typography sx={{ padding: 2 }}></Typography>
              )}
              {/* {selectedObject && selectedObject.type === "image" ? (
                <ImageSettings
                  // onChange={handleTextSettingsChange}
                  // currentSettings={{
                  //   fontSize: selectedObject.fontSize || 16,
                  //   fontFamily: selectedObject.fontFamily || "Arial",
                  //   fill: selectedObject.fill || "#000000",
                  //   textAlign: selectedObject.textAlign || "left",
                  //   stroke: selectedObject.stroke || "",
                  //   strokeWidth: selectedObject.strokeWidth || 0,
                  //   opacity: (selectedObject.opacity || 1) * 100, // Convert decimal to percentage
                  // }}
                  selectedObject={selectedObject}
                  fabricCanvasGlobal={fabricCanvasGlobal}
                />
              ) : (
                <Typography sx={{ padding: 2 }}></Typography>
              )} */}
            </Box>
          </ThemeProvider>
        </Box>
      </Popover>
    </div>
  );
}
