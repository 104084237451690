import React, { useContext, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import TShirtIcon from "@mui/icons-material/Checkroom";
import MugIcon from "@mui/icons-material/Coffee";
import PhoneCaseIcon from "@mui/icons-material/Smartphone";
import { styled } from "@mui/material/styles";
import { CanvasContext } from "../CanvasContext";

const ProductButton = styled(Button)(({ theme, selected }) => ({
  border: selected
    ? `2px solid ${theme.palette.primary.main}`
    : "1px solid #ccc",
  backgroundColor: selected ? theme.palette.primary.light : "white",
  color: selected ? theme.palette.primary.contrastText : "black",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  transition: "all 0.3s",
  ":hover": {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));

const ProductOptions = ({ setSelectedProduct, selectedProduct }) => {
  //   const [selectedProduct, setSelectedProduct] = useState(null);
  const { productOptions } = useContext(CanvasContext);
  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Choose a Product
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {productOptions.map((product) => (
          <Grid item key={product.id} xs={6} sm={4} md={3}>
            <ProductButton
              onClick={() => handleProductClick(product)}
              selected={selectedProduct === product.id}
            >
              {product.icon}
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                {product.product}
              </Typography>
            </ProductButton>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProductOptions;
