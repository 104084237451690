import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  palette: {
    primary: { main: "#87CEEB" },
    secondary: { main: "#FF6347" },
    background: {
      primary: "#f8f5f2",
      secondary: "#ffffff",
    },
  },
  typography: {
    h1: {
      fontSize: "2rem",
      "@media (min-width:600px)": {
        fontSize: "3rem",
      },
    },
  },
  text: {
    primary: "#87CEEB",
    secondary: "#87CEEB",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
        },
      },
    },
  },
  spacing: 8, // Default spacing
});

theme = responsiveFontSizes(theme);

export default theme;
