import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
import { fabric } from "fabric";
// import { resizeImage } from "./image-processing-tools";
import { setImageURL } from "./redux-slices/ImageSlice";
import {
  // SketchPicker,
  HuePicker,
  // PhotoshopPicker,
  // CustomPicker,
  // SliderPicker,
} from "react-color";
// import { Saturation } from "react-color/lib/components/common";

import { CanvasContext } from "./CanvasContext";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
// import { hslToRgb } from "@mui/material";

const ImageColorPanel = (props) => {
  // const { scaleGlobal, setScaleGlobal } = useContext(CanvasContext);
  const [color, setColor] = useState("hsl(0, 100%, 50%)");
  const { canvasRef } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const [saturation, setSaturation] = useState(50);
  const [hue, setHue] = useState(0);
  const [brightness, setBrightness] = useState(50);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const fabricCanvas = canvasRef.current.fabric;
  let originalImage;
  useEffect(() => {
    if (props.imageURL) {
      fabric.Image.fromURL(props.imageURL, (img) => {
        originalImage = img;
        setWidth(img.width);
        setHeight(img.height);
      });
    }
  }, [props.imageURL]);

  function normalizeHue(hue) {
    return hue / 360;
  }
  function normalizeSaturation(x, min, max) {
    return (2 * (x - min)) / (max - min) - 1;
  }

  //remove filters if the back button is clicked
  const back = () => {
    // dispatch(setImageURL(originalImageURL));
    const imageOriginal = fabricCanvas
      .getObjects()
      .find((obj) => obj.type === "image" && obj.myCustomId === "image");
    if (!imageOriginal || !(imageOriginal instanceof fabric.Image)) {
      //console.log("Image object not found");
      return;
    }
    let image = imageOriginal;
    image.filters = [];
    image.applyFilters();
    fabricCanvas.renderAll();
  };

  useEffect(() => {
    if (!fabricCanvas) return;
    // const image = fabricCanvas.getActiveObject();
    const imageOriginal = fabricCanvas
      .getObjects()
      .find((obj) => obj.type === "image" && obj.myCustomId === "image");
    if (!imageOriginal || !(imageOriginal instanceof fabric.Image)) return; // Ensure an image is selected
    let image = imageOriginal;
    image.filters = [];
    image.applyFilters();
    fabricCanvas.renderAll();
    // Apply hue rotation filter
    const hueNormalized = normalizeHue(hue);
    const hueRotationFilter = new fabric.Image.filters.HueRotation({
      rotation: hueNormalized, // Value between 0 and 1
    });
    // Apply saturation filter
    const saturationNormalized = normalizeSaturation(saturation, 0, 100);
    const saturationFilter = new fabric.Image.filters.Saturation({
      saturation: saturationNormalized, // Value between -1 (desaturated) and 1 (highly saturated), 0 is unchanged
    });
    // Apply Brightness filter
    const brightnessNormalized = normalizeSaturation(brightness, 0, 100);
    const brightnessFilter = new fabric.Image.filters.Brightness({
      brightness: brightnessNormalized, // Adjust brightness
    });

    // Add filters to image and apply
    image.filters.push(hueRotationFilter, saturationFilter, brightnessFilter);
    image.applyFilters();
    fabricCanvas.renderAll();
  }, [hue, saturation, brightness]);

  const handleHueChange = (color, event) => {
    // Update the entire color state
    const hslColor = `hsl(${color.hsl.h}, ${saturation}%, ${brightness}%)`;
    setColor(hslColor);
    setHue(Math.round(color.hsl.h));
    // //console.log("BREAKPOINT color.hsl.h= " + color.hsl.h);
  };

  const handleBlur = () => {
    if (saturation < 0) {
      setSaturation(0);
    } else if (saturation > 100) {
      setSaturation(100);
    }
  };

  const handleBlurHue = () => {
    if (hue < 0) {
      setHue(0);
    } else if (hue > 360) {
      setHue(360);
    }
  };

  const handleBlurBrighness = () => {
    if (brightness < 0) {
      setBrightness(0);
    } else if (brightness > 100) {
      setBrightness(100);
    }
  };

  const handleInputChangeHue = (event) => {
    // setSaturation(event.target.value === "" ? 0 : Number(event.target.value));
    setHue(event.target.value);
  };

  const handleInputChange = (event) => {
    // setSaturation(event.target.value === "" ? 0 : Number(event.target.value));
    setSaturation(event.target.value);
  };

  const handleInputChangeBrightness = (event) => {
    // setSaturation(event.target.value === "" ? 0 : Number(event.target.value));
    setBrightness(event.target.value);
  };

  //function to display adjusted image
  const displayImage = () => {
    const croppedDataURL = fabricCanvas.toDataURL({
      // scaleX: scaleGlobal,
      // scaleY: scaleGlobal,
      // quality: 1,
      // left: 0,
      // top: 0,
      // width: width,
      // height: height,
      // originX: "left", // Set origin to the top-left corner
      // originY: "top", // Set origin to the top-left corner
    });
    dispatch(setImageURL(croppedDataURL));
  };

  return (
    <Box>
      <Button
        onClick={() => {
          // dispatch(setBackMenu());
          back();
          dispatch(unsetGridMenuClicked());
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>
      <Box style={{ padding: "5%" }}>
        <Stack direction="column" spacing={2}>
          {/* <SketchPicker></SketchPicker> */}
          <Box>
            <Grid
              container
              spacing={2}
              // alignItems="center"
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="caption" id="input-slider" gutterBottom>
                  Hue
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  value={hue}
                  // value={0}
                  size="small"
                  margin="dense"
                  onChange={handleInputChangeHue}
                  onBlur={handleBlurHue}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 360,
                    type: "number",

                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <HuePicker color={color} width="100%" onChange={handleHueChange} />
          <Box>
            <Grid
              container
              spacing={2}
              // alignItems="center"
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="caption" id="input-slider" gutterBottom>
                  Saturation
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  value={saturation}
                  // value={0}
                  size="small"
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              backgroundImage: `linear-gradient(to right, white, ${color})`,
            }}
          >
            <Slider
              value={saturation}
              min={0}
              max={100}
              step={1}
              sx={{
                width: "100%",
              }}
              onChange={(e, newValue) => {
                setSaturation(newValue);
              }}
            ></Slider>
          </Box>

          <Box>
            <Grid
              container
              spacing={2}
              // alignItems="center"
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={2} sm={4} md={6}>
                <Typography variant="caption" id="input-slider" gutterBottom>
                  Brightness
                </Typography>
              </Grid>
              <Grid item>
                <Input
                  value={brightness}
                  // value={0}
                  size="small"
                  onChange={handleInputChangeBrightness}
                  onBlur={handleBlurBrighness}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              backgroundImage: `linear-gradient(to right, white, black)`,
            }}
          >
            <Slider
              value={brightness}
              min={0}
              max={100}
              step={1}
              sx={{
                width: "100%",
              }}
              onChange={(e, newValue) => {
                setBrightness(newValue);
              }}
            ></Slider>
          </Box>
          <Button
            onClick={() => {
              // const imageDataURL = fabricCanvas.toDataURL({
              //   // scaleX: scaleGlobal,
              //   // scaleY: scaleGlobal,
              //   // quality: 1,
              //   // left: left,
              //   // top: top,
              //   width: width,
              //   height: height,
              //   originX: "left", // Set origin to the top-left corner
              //   originY: "top", // Set origin to the top-left corner
              // });
              // dispatch(setImageURL(imageDataURL));
              displayImage();
              dispatch(unsetGridMenuClicked());
            }}
            variant="contained"
          >
            Apply
          </Button>
          <Button
            onClick={() => {
              dispatch(unsetGridMenuClicked());
              back();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
export default ImageColorPanel;
