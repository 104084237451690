import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setText } from "./redux-slices/TextSlice";
import { fabric } from "fabric";
import { CanvasContext } from "./CanvasContext";
import { useMediaQuery } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {
  setActiveTemplate,
  setImgTemplateURL,
} from "./redux-slices/ImageSlice";
import zIndex from "@mui/material/styles/zIndex";
import FloatingToolbar from "./FloatingToolbar";
import Box from "@mui/material/Box";
import ConvertImageToGrayscale from "./OpencvJS/ConvertImageToGrayscale";
import MaskImages from "./OpencvJS/MaskImages";
import { setImageURL } from "./redux-slices/ImageSlice";
import { adjustCanvasZooming } from "./Custom Hooks/CommonEffectsDesign";
// import { canvasGuidelines } from "@yassidev/fabric-extensions";
// canvasGuidelines(fabric);

const maskImageUrl =
  "https://design-files-okayartist.s3.us-east-1.amazonaws.com/thisPurrfectHumanBelongsTo/design-mask.png";
const mainImageUrl =
  "https://design-files-okayartist.s3.us-east-1.amazonaws.com/customRetro/retro-background.png";

const CanvasAreaV2 = () => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    canvasRef,
    canvasRefTemplate,
    setCanvasAspectRatio,
    setImgAspectRatio,
    setScaleGlobal,
    imgWidth,
    imgHeight,
    open,
    setOpen,
    artBoardSettings,
    setArtBoardSettings,
    fabricCanvasGlobal,
    setFabricCanvasGlobal,
  } = useContext(CanvasContext);
  // const canvasRef = useRef(null);
  const imageURL = useSelector((state) => state.image.imageURL); // Access the image URL from the Redux store
  const activeTemplate = useSelector((state) => state.image.activeTemplate); // Access the image URL from the Redux store
  const textOption = useSelector((state) => state.textState.text);
  const dispatch = useDispatch();

  const [isCanvasInitialized, setIsCanvasInitialized] = React.useState(false);

  const applyImageMask = (canvas, maskImageUrl, mainImageUrl) => {
    if (canvas && canvas.getContext()) {
      fabric.Image.fromURL(maskImageUrl, (maskImg) => {
        fabric.Image.fromURL(mainImageUrl, (mainImg) => {
          // Set the mask image as the clipPath of the main image
          mainImg.set({
            clipPath: new fabric.Image(maskImg.getElement(), {
              scaleX: maskImg.scaleX,
              scaleY: maskImg.scaleY,
              left: maskImg.left,
              top: maskImg.top,
              absolutePositioned: true, // Ensures clipping remains static
            }),
            selectable: true, // ✅ Allow selecting the masked image
            evented: true, // ✅ Enable movement, resizing, etc.
          });

          // Ensure the mask image's border is used for selection
          maskImg.set({
            selectable: true, // ✅ Allow selecting mask
            evented: true, // ✅ Ensure movement
            hasControls: true, // ✅ Ensure resizing works
          });

          // Add the mask and the main image to the canvas
          canvas.add(mainImg);
          // canvas.add(maskImg);

          // Render all changes
          canvas.renderAll();
        });
      });
    }
  };

  useEffect(() => {
    if (true) {
      // // Extend Fabric.js to save `clipPath` in JSON
      // fabric.Object.prototype.toObject = (function (toObject) {
      //   return function () {
      //     return fabric.util.object.extend(toObject.call(this), {
      //       clipPath: this.clipPath ? this.clipPath.toObject() : null, // ✅ Save clipPath properly
      //     });
      //   };
      // })(fabric.Object.prototype.toObject);
      // fabric.Object.prototype.toObject = (function (toObject) {
      //   return function () {
      //     const obj = toObject.call(this);

      //     // ✅ Ensure clipPath is stored correctly
      //     if (this.clipPath && this.clipPath.type === "image") {
      //       obj.clipPath = {
      //         ...this.clipPath.toObject(),
      //         src: this.clipPath._element
      //           ? this.clipPath._element.src
      //           : this.clipPath.src, // ✅ Use actual image src
      //       };
      //     } else {
      //       obj.clipPath = null; // ✅ Ensure consistency
      //     }

      //     return obj;
      //   };
      // })(fabric.Object.prototype.toObject);
      fabric.Text.prototype.toObject = (function (toObject) {
        return function () {
          return fabric.util.object.extend(toObject.call(this), {
            gradient: this.gradient,
            border: this.border,
          });
        };
      })(fabric.Text.prototype.toObject);

      // Initialize Fabric.js canvas
      const fabricCanvas = new fabric.Canvas(canvasRef.current, {
        width: artBoardSettings.width,
        height: artBoardSettings.height,
        // width: imgWidth,
        // height: imgHeight,
        // backgroundColor: "green",

        preserveObjectStacking: true,
        interactive: true, // Ensure interactivity is enabled
      });
      setIsCanvasInitialized(true);

      canvasRef.current.fabric = fabricCanvas; // Store the Fabric.js canvas instance

      setFabricCanvasGlobal(fabricCanvas);
      adjustCanvasZooming(fabricCanvas);
      // setFabricCanvasGlobal(fabricCanvas);

      // var clipPath = new fabric.Circle({ radius: 40, top: -40, left: -40 });
      // var rect = new fabric.Rect({ width: 200, height: 100, fill: "red" });
      // rect.clipPath = clipPath;
      // fabricCanvas.add(rect);

      return () => {
        fabricCanvas.dispose();
        dispatch(setImgTemplateURL(""));
        // window.removeEventListener("resize", resizeCanvas);
        setCanvasAspectRatio(1);
        setImgAspectRatio(1);
        // setFabricCanvasGlobal(null);
        // canvasRef.current = null;
        // dispatch(setImageURL(""));
        // setIsCanvasInitialized(false);
      };
    }
  }, [isCanvasInitialized]);

  useEffect(() => {
    if (fabricCanvasGlobal && fabricCanvasGlobal.getContext()) {
      fabricCanvasGlobal.canvasWidth = artBoardSettings.width;
      fabricCanvasGlobal.canvasHeight = artBoardSettings.height;
      // applyImageMask(fabricCanvasGlobal, maskImageUrl, mainImageUrl);
    }
  }, [fabricCanvasGlobal]);

  useEffect(() => {
    if (fabricCanvasGlobal && fabricCanvasGlobal.getContext()) {
      fabricCanvasGlobal.canvasWidth = artBoardSettings.width;
      fabricCanvasGlobal.canvasHeight = artBoardSettings.height;
      if (artBoardSettings.transparentBackground) {
        fabricCanvasGlobal.setBackgroundColor(
          "",
          fabricCanvasGlobal.renderAll.bind(fabricCanvasGlobal)
        );
      } else {
        fabricCanvasGlobal.setBackgroundColor(
          artBoardSettings.backgroundColor,
          fabricCanvasGlobal.renderAll.bind(fabricCanvasGlobal)
        );
      }
    }
  }, [artBoardSettings]);

  //update the canvas dimensions
  // useEffect(() => {
  //   if (fabricCanvasGlobal && fabricCanvasGlobal.getContext()) {
  //     // //console.log("BREAKPOINT useffect");
  //     // Update canvas size and preserve its content
  //     fabricCanvasGlobal.setWidth(artBoardSettings.width);
  //     fabricCanvasGlobal.setHeight(artBoardSettings.height);
  //     fabricCanvasGlobal.calcOffset(); // Recalculate offsets after resizing
  //     adjustCanvasZooming(fabricCanvasGlobal);
  //     fabricCanvasGlobal.renderAll();
  //   }
  // }, [artBoardSettings.width, artBoardSettings.height]);

  //addition of text feature
  useEffect(() => {
    if (textOption) {
      const fabricCanvas = canvasRef.current.fabric; // Ensure this references the Fabric.js canvas
      if (!fabricCanvas) return;

      const newText = new fabric.IText("Edit Text", {
        left: 100, // Position from the left on the canvas
        top: 100, // Position from the top on the canvas
        fontFamily: "Arial",
        fill: "white",
        fontSize: 20,
      });

      fabricCanvas.add(newText);
      fabricCanvas.bringToFront(newText);
      fabricCanvas.renderAll(); // Render to display the changes
      dispatch(setText());
    }
  }, [textOption]);

  return (
    <div
    // id="canvas"
    // style={{ position: "relative" }}
    // onClick={textOption ? addText : ""}
    >
      <Box sx={{ backgroundColor: "white" }}>
        <canvas
          id="canvas"
          ref={canvasRef}
          style={{
            // maxWidth: "100%",
            // maxHeight: "85vh",
            // border: "1px solid black",
            // backgroundColor: "white",
            display: "block",
            // marginLeft: "auto",
            // marginRight: "auto",
            // zIndex: "0",
          }}
        ></canvas>
      </Box>
      {/* <MaskImages /> */}

      {/* <TypographyCanvas /> */}
    </div>
  );
};
export default CanvasAreaV2;
