import React from "react";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  InputAdornment,
  Typography,
  FormControl,
  selectClasses,
} from "@mui/material";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";

const availableFonts = [
  // { label: "Arial", value: "Arial" },
  { label: "Shrikhand", value: "Shrikhand" },
  { label: "Freshman", value: "Freshman" },
  { label: "Neue Haas Grotesk", value: "neue-haas-grotesk-text" },
  { label: "Choc MN", value: "choc-mn" },
  { label: "LTC Broadway", value: "ltc-broadway" },
  { label: "Big Caslon CC Black", value: "big-caslon-fb" },
  { label: "Omnes Black", value: "omnes-pro" },
  { label: "Gloria Hallelujah", value: "gloria-hallelujah" },
  { label: "Classico URW Black", value: "classico-urw" },
  { label: "Usual ExtraBold", value: "usual" },
  { label: "Cooper Std Black", value: "cooper-black-std" },
  { label: "Anton", value: "anton" },
  { label: "Jeff Script", value: "jeff-script" },
  { label: "Falcon Script", value: "falcon-script" },
  { label: "Cortado", value: "cortado" },
  { label: "Gratitude Script Pro", value: "gratitude-script-pro" },
  { label: "Amador", value: "amador" },
  { label: "Sriracha", value: "sriracha" },
  { label: "Yellowtail", value: "yellowtail" },
  { label: "MrDafoe Pro", value: "mrdafoe-pro" },
  { label: "Haboro Contrast", value: "haboro-contrast-normal" },
  { label: "Miller Banner", value: "miller-banner" },
  { label: "Bebas Neue", value: "bebas-neue" },
  { label: "Novecento Slab Condensed", value: "novecento-slab-condensed" },

  { label: "Oswald", value: "oswald" },
  { label: "Funkydori", value: "funkydori" },
  // ✅ Shaded Fonts
  { label: "Industry Inc", value: "industry-inc-3d" },
  { label: "Brim Narrow Combined 1", value: "brim-narrow-combined-1" },
  { label: "Brim Narrow Combined 2", value: "brim-narrow-combined-2" },
  { label: "Anodyne", value: "anodyne" },
  { label: "Blockhead", value: "blockhead-black-face" },
  { label: "Zebrawood", value: "zebrawood-std" },
  { label: "Roma Shaded", value: "roma-shaded" },
  { label: "Wausau", value: "wausau" },
  { label: "AW Conqueror Carved", value: "aw-conqueror-carved" },
  {
    label: "Spumante Regular plus Shadow",
    value: "spumante-regular-plus-shadow",
  },
  { label: "Tomarik Display Shadow", value: "tomarik-display-shadow" },
  { label: "CCBiffBamBoomOutline", value: "ccbiffbamboomoutline" },
  { label: "Rosewood Std", value: "rosewood-std" },
  { label: "HVD Steinzeit", value: "steinzeit" },
  { label: "Mati", value: "mati" },
  { label: "Wigwag Deluxe", value: "wigwag-deluxe" },
  { label: "Flyswim", value: "flyswim" },
  { label: "HWT American Chromatic", value: "hwt-american-chromatic" },
  // ✅ Wedding Fonts
  { label: "Lindsey Signature", value: "lindsey-signature" },
  { label: "Filmotype Yale", value: "filmotype-yale" },
  { label: "P22 Allyson Pro", value: "p22-allyson-pro" },
  { label: "Krul", value: "krul" },
  { label: "Adore You Slanted", value: "adore-you-slanted" },
  { label: "La Bohemienne", value: "la-bohemienne" },
  { label: "MrSheffield Pro", value: "mrsheffield-pro" },
  { label: "DrSugiyama Pro", value: "dr-sugiyama-pro" },
  { label: "Al Fresco Bold", value: "al-fresco" },
  { label: "Cantoni Pro", value: "cantoni-pro" },
  { label: "Tangier Bold", value: "tangier" },
  { label: "Cinque Donne Bold", value: "cinque-donne" },
  // ✅ College Fonts
  { label: "Bungee", value: "bungee" },
  { label: "Monte Stella Bold", value: "monte-stella" },
  { label: "Grecian Light Face", value: "grecian-light-face" },
  { label: "Becker Gothics Stencil", value: "becker-gothics-stencil" },
  { label: "Rigid Square Bold", value: "rigid-square" },
  // ✅ Calligraphic Fonts
  { label: "Antiquarian Scribe", value: "antiquarian-scribe" },
  { label: "Medusa", value: "medusa" },
  { label: "Altesse Std", value: "altesse-std-64pt" },
  { label: "Dream Big", value: "dream-big-regular" },
  { label: "LTC Francis", value: "ltc-francis" },
  { label: "Regina", value: "regina" },
  { label: "Hazel Script Expanded", value: "hazel-script" },
  { label: "PeregroyJF", value: "peregroy-jf" },
  { label: "Wishes Display Bold", value: "wishes-display" },
  { label: "Palomino Script Rough", value: "palomino-script" },
  { label: "Casey Bold", value: "casey" },
  { label: "Timberline", value: "timberline" },
  { label: "Enchanted", value: "enchanted" },
  { label: "Storefront Pro", value: "storefront-pro" },
  { label: "Vatican Bold", value: "vatican" },
  { label: "Avalon Bold", value: "avalon" },
  { label: "P22 Sneaky Pro", value: "p22-sneaky-pro" },
  { label: "Allura", value: "allura" },
  // ✅ Clean Fonts
  { label: "Gesta", value: "gesta" },
  { label: "Komet", value: "komet" },
  { label: "Loos Normal", value: "loos-normal" },
  { label: "Indivisible", value: "indivisible" },
  { label: "Lorimer No 2", value: "lorimer-no-2" },
  { label: "Owners", value: "owners" },
  { label: "Area Normal", value: "area-normal" },
  { label: "Area Extended", value: "area-extended" },
  { label: "Forma DJR Banner", value: "forma-djr-banner" },
  { label: "Century Gothic Pro", value: "century-gothic" },
  { label: "Apparat Light", value: "apparat-light" },
  { label: "ApparatSemiCond Medium", value: "apparat-semicond" },
  // ✅ Brush Pen Fonts
  { label: "Relation", value: "relation-one" },
  { label: "LiebeGerda", value: "liebegerda" },
  { label: "Goodlife Brush", value: "goodlife-brush" },
  { label: "Scrapbooker Script", value: "scrapbooker-script" },
  { label: "Rough Love", value: "rough-love" },
  { label: "Modern Love Grunge", value: "modern-love-grunge" },
  { label: "Backstroke", value: "backstroke" },
  { label: "Cinema Script", value: "cinema-script" },
  { label: "Gelato Luxe", value: "gelato-luxe" },
  { label: "Canvas Script", value: "canvas-script" },
  { label: "Indie", value: "indie" },
  { label: "Live", value: "live" },
  { label: "Chonky", value: "chonky" },
  { label: "Botanica Sans", value: "botanica-sans" },
  { label: "Hello Bloomie Script", value: "hello-bloomie-script" },
  { label: "Lumios Brush", value: "lumios-brush" },
  { label: "Sarah Script", value: "sarah-script" },
  { label: "Coniferous Light", value: "coniferous" },
  { label: "Merengue Script", value: "merengue-script" },
  { label: "Brush Script Std Medium", value: "brush-script-std" },
  { label: "Bounce Script", value: "bounce-script" },
  { label: "Duo Paint", value: "duos-paint" },
  { label: "Kapelka New", value: "kapelka-new" },
  { label: "CornerStoreJF", value: "corner-store-jf" },
  { label: "Alpine Script", value: "alpine-script" },
  { label: "Kewl Script", value: "kewl-script" },
  { label: "Smoothy", value: "smoothy" },
  { label: "HucklebuckJF", value: "hucklebuck-jf" },
  // ✅ Rough Fonts
  { label: "Kiln Sans", value: "kiln-sans" },
  { label: "PF Fuel Decay", value: "pf-fuel-decay" },
  { label: "PF Fuel Grime", value: "pf-fuel-grime" },
  { label: "Search", value: "search" },
  { label: "Thirsty Rough Reg Two", value: "thirsty-rough-two" },
  { label: "Nexa Rust Sans Black 2", value: "nexa-rust-sans-black-2" },
  {
    label: "Nexa Rust Sans Black Shadow 2",
    value: "nexa-rust-sans-black-shad-2",
  },
  { label: "Canvas Inline Heavy", value: "canvas-inline" },
  { label: "Veneer ThreeRegular", value: "veneer-three" },
  { label: "SantElia Rough Bold Two", value: "santelia-rough-two" },
  { label: "Pulpo Rust 75", value: "pulpo-rust-75" },
  { label: "Citrus Gothic", value: "citrus-gothic" },
  { label: "Battery Park", value: "battery-park" },
  { label: "Calder Dark Grit Shadow", value: "calder-dark-grit-shadow" },
  { label: "Cherry Blossoms", value: "cherry-blossoms-regular" },
  { label: "Cheddar Gothic Rough", value: "cheddar-gothic-rough" },
  { label: "HWT American Shopworn", value: "hwt-american-shopworn" },
  { label: "Burnaby Stencil", value: "burnaby-stencil" },
  { label: "Scarlet Wood", value: "scarlet-wood" },
  { label: "Fredericka the Greatest", value: "fredericka-the-greatest" },
  { label: "Strenuous Cast", value: "strenuous-cast" },
  { label: "Charcuterie Etched", value: "charcuterie-etched" },
  // ✅ Cursive Fonts
  { label: "Blithe", value: "blithe" },
  { label: "Adventures Unlimited Script", value: "adventures-unlimited" },
  { label: "Emily Austin", value: "emily-austin" },
  { label: "Ballare", value: "ballare" },
  { label: "Mina", value: "mina" },
  { label: "Shelby", value: "shelby" },
  { label: "Sadey Ann", value: "sadey-ann" },
  { label: "Beloved Script", value: "beloved-script" },
  { label: "Luxus Brut", value: "luxus-brut" },
  // ✅ Blackletter Fonts
  { label: "Bradley DJR", value: "bradley-djr" },
  { label: "Gandur New", value: "gandur-new" },
  { label: "Rotunda Veneta", value: "rotunda-veneta" },
  { label: "Sepian", value: "sepian" },
  { label: "LeKick Brush", value: "lekick-brush" },
  { label: "Carol Gothic", value: "carol-gothic" },
  { label: "SabbathBlack OT", value: "sabbath-black" },
  { label: "Luke", value: "luke-thin-400" },
  { label: "Blonde Fraktur", value: "blonde-fraktur" },
  // { label: "", value: "" },
  // { label: "", value: "" },
  // { label: "", value: "" },
  // { label: "", value: "" },
  // { label: "", value: "" },
  // { label: "", value: "" },
];

const TextProperties = ({
  currentSettings,
  onSettingChange,
  onInputChange,
  selectedObject,
  setCurrentSetting,
}) => {
  const [localSettings, setLocalSettings] = React.useState(currentSettings);

  // Sync local state with the prop
  React.useEffect(() => {
    setLocalSettings(currentSettings);
  }, [currentSettings]);

  // React.useEffect(() => {
  //   setCurrentSetting(localSettings);
  // }, [localSettings]);

  const handleInputChange = (key, value) => {
    onInputChange(key, value); // Call parent function to update state
    setLocalSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    // setCurrentSetting((prevSettings) => ({
    //   ...prevSettings,
    //   [key]: value,
    // }));
  };

  const handleApplySettings = () => {
    onSettingChange(localSettings); // Apply settings to Fabric.js object
  };

  const [fontFamily, setFontFamily] = React.useState("Roboto");
  const [fontWeight, setFontWeight] = React.useState("Bold");
  const [fontSize, setFontSize] = React.useState(108);
  const [letterSpacing, setLetterSpacing] = React.useState(0);
  const [lineHeight, setLineHeight] = React.useState(40);
  const [textAlign, setTextAlign] = React.useState("center");
  const [formats, setFormats] = React.useState(() => [
    currentSettings.fontWeight,
    currentSettings.fontStyle,
    currentSettings.underline ? "underlined" : "",
  ]);

  React.useEffect(() => {
    setFormats([
      currentSettings.fontWeight,
      currentSettings.fontStyle,
      currentSettings.underline ? "underlined" : "",
    ]);
  }, [formats]);

  const handleFormat = (event, newFormats) => {
    // //console.log("BREAKPOINT formats= " + newFormats);
    // onInputChange(key, value);

    newFormats.includes("bold")
      ? onInputChange("fontWeight", "bold")
      : onInputChange("fontWeight", "normal");

    newFormats.includes("italic")
      ? onInputChange("fontStyle", "italic")
      : onInputChange("fontStyle", "normal");

    newFormats.includes("underlined")
      ? onInputChange("underline", true)
      : onInputChange("underline", false);
    // newFormats.includes("italic") ? "italic" : "normal",
    //   newFormats.includes("underline"),
    setFormats(newFormats);
  };

  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
      }}
    >
      {/* Header */}
      <Typography
        variant="subtitle1"
        sx={{ fontWeight: "bold", marginBottom: 1 }}
      >
        Text
      </Typography>

      {/* Font Settings */}
      <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
        {/* Font Family */}
        <FormControl fullWidth>
          <Select
            value={localSettings.fontFamily}
            onChange={(e) => handleInputChange("fontFamily", e.target.value)}
            displayEmpty
          >
            {availableFonts.map((font) => (
              <MenuItem
                key={font.value}
                value={font.value}
                style={{ fontFamily: font.value }}
              >
                {font.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Font Weight */}
        <FormControl fullWidth>
          <Select
            value={localSettings.fontWeight || "normal"}
            onChange={(e) => handleInputChange("fontWeight", e.target.value)}
            displayEmpty
          >
            <MenuItem value="bold">Bold</MenuItem>
            <MenuItem value="normal">Regular</MenuItem>
            {/* <MenuItem value={10}>Light</MenuItem> */}
          </Select>
        </FormControl>
      </Box>

      {/* Font Size, Letter Spacing, Line Height */}
      <Box sx={{ display: "flex", gap: 1, marginBottom: 2 }}>
        {/* Font Size */}
        <TextField
          label="Tt"
          type="number"
          value={Math.floor(localSettings.fontSize)}
          onChange={(e) =>
            handleInputChange("fontSize", parseInt(e.target.value))
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">px</InputAdornment>,
          }}
          size="small"
        />

        {/* Letter Spacing */}
        <TextField
          label="AV"
          type="number"
          value={localSettings.charSpacing}
          onChange={(e) =>
            handleInputChange("charSpacing", parseInt(e.target.value))
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          size="small"
        />

        {/* Line Height */}
        <TextField
          label="AA"
          type="number"
          value={localSettings.lineHeight}
          onChange={(e) =>
            handleInputChange("lineHeight", parseFloat(e.target.value))
          }
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputProps: { step: 0.1 },
          }}
          size="small"
        />
      </Box>

      {/* Text Alignment */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <ToggleButtonGroup
          value={localSettings.textAlign}
          exclusive
          onChange={(e, value) => handleInputChange("textAlign", value)}
          size="small"
        >
          <ToggleButton value="left">
            <FormatAlignLeftIcon />
          </ToggleButton>
          <ToggleButton value="center">
            <FormatAlignCenterIcon />
          </ToggleButton>
          <ToggleButton value="right">
            <FormatAlignRightIcon />
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Text Transform and Font Features */}
        <ToggleButtonGroup
          //   value={localSettings.fontStyle}
          value={formats}
          onChange={handleFormat}
          //   exclusive
          //   onChange={(e, value) => handleInputChange("fontStyle", value)}
          size="small"
          aria-label="text formatting"
        >
          <ToggleButton value="bold" aria-label="bold">
            <FormatBoldIcon />
          </ToggleButton>
          <ToggleButton value="italic" aria-label="italic">
            <FormatItalicIcon />
          </ToggleButton>
          <ToggleButton value="underlined" aria-label="underlined">
            <FormatUnderlinedIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default TextProperties;
