import React, { useEffect, useRef, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSelector, useDispatch } from "react-redux";
// import { setBackMenu } from "./redux-slices/BackMenuSlice";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { unsetGridMenuClicked } from "./redux-slices/GridMenuClickedSlice";
// import ImageCropper from "./ImageCropper";
import { fabric } from "fabric";
import { CanvasContext } from "./CanvasContext";
import { setImageURL } from "./redux-slices/ImageSlice";
// import { cropImage } from "./image-processing-tools";
import { ThemeProvider } from "@emotion/react";
import theme from "../theme";
// import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const ImageCropPanel = (props) => {
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { canvasRef, temp } = useContext(CanvasContext);
  //console.log(
  //   "BREAKPOINT IN ImageCropPanel------------------------------------"
  // );
  //console.log(
  //   "BREAKPOINT inside image crop panel temp :>:>:>:>:>:>:>:>:>:>:>:>:>" + temp
  // );
  const [ratio, setRatio] = React.useState("Freeform");
  const [orientation, setOrientation] = React.useState("Portrait");
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [aspectRatio, SetAspectRatio] = React.useState(true);
  const [scaleWidth, setScaleWidth] = useState(Number(0));
  const [scaleHeight, setScaleHeight] = useState(0);
  const [rect, setRect] = useState(null);
  const { scaleGlobal, setScaleGlobal } = useContext(CanvasContext);

  const [croppedLeft, setCroppedLeft] = useState(0);
  const [croppedTop, setCroppedTop] = useState(0);
  const [croppedWidth, setCroppedWidth] = useState(0);
  const [croppedHeight, setCroppedHeight] = useState(0);
  const [objModified, setObjModified] = useState(false);
  // var cropLeft, cropTop, cropWidth, cropHeight;
  //image upload
  useEffect(() => {
    if (props.imageURL) {
      fabric.Image.fromURL(props.imageURL, (img) => {
        setWidth(img.width);
        setHeight(img.height);
        if (img.width >= img.height) setScaleWidth(img.width / img.height);
        else setScaleHeight(img.height / img.width);
      });
    }
  }, [props.imageURL]);

  const handleChange = (event) => {
    setRatio(event.target.value);
  };
  const handleChangeOrientation = (event, orientation) => {
    setOrientation(orientation);
  };

  const dispatch = useDispatch();
  // const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // const fabricCanvas = new fabric.Canvas("canvas");
  const fabricCanvas = canvasRef.current.fabric;
  //console.log(
  //   "BREAKPOINT after creating the fabric.Canvas------------------------------------"
  // );
  useEffect(() => {
    //console.log(
    //   "BREAKPOINT inside rect adding useEffect------------------------------------"
    // );
    addCropRect();
  }, []);

  //function cropping the image
  const displayCroppedImage = (left, top, width, height) => {
    removeCropRect();
    const croppedDataURL = fabricCanvas.toDataURL({
      // scaleX: scaleGlobal,
      // scaleY: scaleGlobal,
      quality: 1,
      left: left,
      top: top,
      width: width,
      height: height,
      originX: "left", // Set origin to the top-left corner
      originY: "top", // Set origin to the top-left corner
    });
    dispatch(setImageURL(croppedDataURL));
  };
  //event listeners are implemented
  useEffect(() => {
    const updateRectangleState = () => {
      setObjModified(true);
      const cropRect = fabricCanvas.getActiveObject();
      if (cropRect && cropRect.type === "rect") {
        const rectBounds = cropRect.getBoundingRect();
        const canvasScale = fabricCanvas.getZoom();
        // cropRect.set({ scaleX: 1, scaleY: 1 });
        const scaledWidth = rectBounds.width;
        const scaledHeight = rectBounds.height;
        const scaledLeft = rectBounds.left;
        const scaledTop = rectBounds.top;

        setCroppedWidth(scaledWidth);
        setCroppedHeight(scaledHeight);
        setCroppedLeft(scaledLeft);
        setCroppedTop(scaledTop);

        setWidth(Math.round(scaledWidth));
        setHeight(Math.round(scaledHeight));

        // //console.log(
        //   "BREAKPOINT updateRectangleState " +
        //     `croppedLeft =${croppedLeft}, croppedTop= ${croppedTop}, croppedWidth= ${croppedWidth}, croppedHeight=${croppedHeight}`
        // );
      }
    };
    if (fabricCanvas) {
      fabricCanvas.on("object:modified", updateRectangleState);

      return () => {
        fabricCanvas.off("object:modified", updateRectangleState);
      };
    }
  }, [fabricCanvas]);

  // New useEffect to handle actions after state update
  useEffect(() => {
    // Ensure all necessary states are updated
    if (croppedWidth && croppedHeight && croppedLeft && croppedTop) {
      //console.log("States updated, now performing crop");
      // perform your cropping operation here
      //console.log(
      //   "BREAKPOINT updateRectangleState " +
      //     `croppedLeft =${croppedLeft}, croppedTop= ${croppedTop}, croppedWidth= ${croppedWidth}, croppedHeight=${croppedHeight}`
      // );
    }
  }, [croppedWidth, croppedHeight, croppedLeft, croppedTop]);

  function addCropRect() {
    if (!fabricCanvas || fabricCanvas.getObjects("rect").length > 0) return;
    if (!fabricCanvas) return;
    const cropRect = new fabric.Rect({
      left: 50,
      top: 50,
      fill: "rgba(0,0,0,0.3)",
      width: 200,
      height: 200,
      cornerColor: "blue",
      cornerSize: 8,
      // hasRotatingPoint: false,
      // transparentCorners: false,
      isCropRect: true, // Custom property to identify the cropping rectangle
      originX: "left", // Set origin to the top-left corner
      originY: "top", // Set origin to the top-left corner
    });

    // cropRect.set({ scaleX: scaleGlobal, scaleY: scaleGlobal });
    fabricCanvas.add(cropRect);
    fabricCanvas.setActiveObject(cropRect);
    // setRect(cropRect);
    setCroppedWidth(cropRect.width);
    setCroppedHeight(cropRect.height);
    setCroppedLeft(cropRect.left);
    setCroppedTop(cropRect.top);

    setWidth(cropRect.width);
    setHeight(cropRect.height);
  }

  // Function to remove the cropping rectangle
  function removeCropRect() {
    if (!fabricCanvas) return;

    // Find the cropping rectangle on the canvas
    const cropRect = fabricCanvas
      .getObjects("rect")
      .find((obj) => obj.isCropRect);

    // If found, remove it
    if (cropRect) {
      fabricCanvas.remove(cropRect);
    }
  }

  // Function to handle cropping
  function cropImageFabric() {
    if (!fabricCanvas) return;
    const cropRect = fabricCanvas.getActiveObject();
    // cropRect.set({ scaleX: 1, scaleY: 1 });

    // Check if the cropping rectangle is selected
    if (cropRect && cropRect.type === "rect") {
      // const left = cropRect.left;
      // const top = cropRect.top;
      // const width = cropRect.width;
      // const height = cropRect.height;
      if (!objModified) {
        // cropLeft = cropRect.left;
        // cropTop = cropRect.top;
        // cropWidth = cropRect.width;
        // cropHeight = cropRect.height;
        removeCropRect();
        // setCroppedLeft(croppedLeft);
        // setCroppedTop(croppedTop);
        // setCroppedWidth(croppedWidth);
        // setCroppedHeight(croppedHeight);
        displayCroppedImage(
          croppedLeft,
          croppedTop,
          croppedWidth,
          croppedHeight
        );
      }

      // removeCropRect();
      //console.log(
      //   "BREAKPOINT before cropImage POST request " +
      //     `croppedLeft =${croppedLeft}, croppedTop= ${croppedTop}, croppedWidth= ${croppedWidth}, croppedHeight=${croppedHeight}`
      // );
      // cropImage(
      //   props.imageURL,
      //   croppedLeft,
      //   croppedTop,
      //   croppedWidth,
      //   croppedHeight
      // )
      //   .then((url) => {
      //     dispatch(setImageURL(url));
      //   })
      //   .catch((error) => {
      //     //console.log("Error processing the image: " + error);
      //   });

      // Optionally remove the cropping rectangle after cropping
      fabricCanvas.remove(cropRect);

      // Crop the image using the dimensions of the cropping rectangle

      // dispatch(setImageURL(croppedDataURL));
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Button
        onClick={() => {
          removeCropRect();
          // dispatch(setBackMenu());

          dispatch(unsetGridMenuClicked());
        }}
      >
        <ArrowBackIcon></ArrowBackIcon>
      </Button>

      <Box style={{ padding: "5%" }}>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <FormControl sx={{ xs: 1, m: 1 }}>
            <InputLabel id="demo-simple-select-helper-label">Ratio</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              defaultValue="Freeform"
              value={ratio}
              label="Ratio"
              onChange={handleChange}
            >
              <MenuItem value={"Freeform"}>Freeform</MenuItem>
              <MenuItem value={"Original Ratio"}>Original Ratio</MenuItem>
              <MenuItem value={"Golden Ratio"}>Golden Ratio</MenuItem>
              <MenuItem value={"Square"}>Square 1x1</MenuItem>
            </Select>
            {/* <FormHelperText>With label + helper text</FormHelperText> */}
          </FormControl>
          {!matchesXS && (
            <ToggleButtonGroup
              size="small"
              sx={{ m: 1 }}
              color="primary"
              value={orientation}
              exclusive
              onChange={handleChangeOrientation}
              aria-label="Platform"
              orientation="vertical"
            >
              <ToggleButton value="Portrait">Portrait</ToggleButton>
              <ToggleButton value="Landscape">Landscape</ToggleButton>
            </ToggleButtonGroup>
          )}

          <FormControl sx={{ m: 1 }} variant="outlined">
            <FormHelperText id="outlined-weight-helper-text">
              Width
            </FormHelperText>
            <OutlinedInput
              id="outlined-adornment-weight"
              type="number"
              endAdornment={<InputAdornment position="end">px</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              value={width}
              // onChange={(e) => {
              //   if (aspectRatio) {
              //     setWidth(e.target.value);
              //     if (scaleWidth !== 0) {
              //       setHeight(Math.round(e.target.value / scaleWidth));
              //     } else {
              //       setHeight(Math.round(e.target.value * scaleHeight));
              //     }
              //   } else {
              //     setWidth(e.target.value);
              //   }
              //   rect.set({ width: e.target.value });
              //   rect.set({ scaleX: scaleGlobal, scaleY: scaleGlobal });
              //   fabricCanvas.renderAll();
              //   // //console.log("BREAKPOINT rect width= " + e.target.value);
              // }}
            />
          </FormControl>
          <FormControl sx={{ m: 1 }} variant="outlined">
            <FormHelperText id="outlined-weight-helper-text">
              Height
            </FormHelperText>
            <OutlinedInput
              id="outlined-adornment-weight"
              type="number"
              endAdornment={<InputAdornment position="end">px</InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              value={height}
              onChange={(e) => {
                // if (aspectRatio) {
                //   setHeight(e.target.value);
                //   if (scaleWidth !== 0) {
                //     setWidth(Math.round(e.target.value * scaleWidth));
                //   } else {
                //     setWidth(Math.round(e.target.value / scaleHeight));
                //   }
                // } else {
                //   setHeight(e.target.value);
                // }
                rect.set({ height: e.target.value });
                rect.set({ scaleX: scaleGlobal, scaleY: scaleGlobal });
                fabricCanvas.renderAll();
              }}
            />
          </FormControl>
          <FormGroup sx={{ m: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  value={aspectRatio}
                  onChange={() => {
                    SetAspectRatio(!aspectRatio);
                  }}
                  defaultChecked
                />
              }
              label="Lock Aspect Ratio"
            />
          </FormGroup>
          {/* <ImageCropper /> */}
          <Stack direction="column" spacing={2}>
            <Button
              onClick={() => {
                if (!objModified) cropImageFabric();
                else {
                  //console.log(
                  //   "BREAKPOINT Button Click" +
                  //     `croppedLeft =${croppedLeft}, croppedTop= ${croppedTop}, croppedWidth= ${croppedWidth}, croppedHeight=${croppedHeight}`
                  // );
                  displayCroppedImage(
                    croppedLeft,
                    croppedTop,
                    croppedWidth,
                    croppedHeight
                  );
                }
              }}
              // onClick={cropImageFabric}
              variant="contained"
            >
              Apply
            </Button>
            <Button
              onClick={() => {
                dispatch(unsetGridMenuClicked());
                removeCropRect();
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </ThemeProvider>
  );
};
export default ImageCropPanel;
