import React, { useState, useContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ImageList from "@mui/material/ImageList";
import { fabric } from "fabric";
import { Checkbox, FormControlLabel, useMediaQuery } from "@mui/material";
import { CanvasContext } from "./CanvasContext";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTheme } from "@mui/material";
import CropLandscapeIcon from "@mui/icons-material/CropLandscape";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import { adjustCanvasZoomingArtboard } from "./Custom Hooks/CommonEffectsDesign";
import { CheckBox } from "@mui/icons-material";

const CanvasMenuItemProject = () => {
  const theme = useTheme();
  const {
    fabricCanvasGlobal,
    projectName,
    setProjectName,
    artBoardSettings,
    setArtBoardSettings,
    productOptions,
  } = useContext(CanvasContext);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // State to manage project name and edit mode
  //   const [projectName, setProjectName] = useState("My Project");
  const [isEditing, setIsEditing] = useState(false);
  const [unit, setUnit] = useState("px");
  const [orientation, setOrientation] = useState("landscape");

  const [isEditingSettings, setIsEditingSettings] = useState(false);
  const [productType, setProductType] = useState(null);

  // Local state to manage inputs for all settings
  const [localSettings, setLocalSettings] = useState({
    width: artBoardSettings.width,
    height: artBoardSettings.height,
    unit: artBoardSettings.unit,
    dpi: artBoardSettings.dpi,
    orientation: artBoardSettings.orientation,
    backgroundColor: artBoardSettings.backgroundColor,
    transparentBackground: artBoardSettings.transparentBackground,
  });

  useEffect(() => {
    setLocalSettings({
      width: artBoardSettings.width,
      height: artBoardSettings.height,
      unit: artBoardSettings.unit,
      dpi: artBoardSettings.dpi,
      orientation: artBoardSettings.orientation,
      backgroundColor: artBoardSettings.backgroundColor,
      transparentBackground: artBoardSettings.transparentBackground,
    });
  }, [artBoardSettings]);

  const handleInputChange = (field, value) => {
    setLocalSettings({
      ...localSettings,
      [field]: value,
    });
  };

  const handleConfirmSettings = () => {
    // Update global artBoardSettings and Fabric.js canvas
    setArtBoardSettings(localSettings);

    if (fabricCanvasGlobal && fabricCanvasGlobal.getContext()) {
      const { width, height } = localSettings;

      // Update Fabric.js canvas dimensions
      fabricCanvasGlobal.setWidth(width);
      fabricCanvasGlobal.setHeight(height);

      fabricCanvasGlobal.canvasWidth = width;
      fabricCanvasGlobal.canvasHeight = height;
      // Adjust canvas zoom to fit within the viewport
      adjustCanvasZoomingArtboard(fabricCanvasGlobal, width, height);

      // Re-render the canvas to apply changes
      fabricCanvasGlobal.renderAll();
    }

    // Exit edit mode
    setIsEditingSettings(false);
    //console.log(
    //   "BREAKPOINT fabricCanvasGlobal= " +
    //     JSON.stringify(fabricCanvasGlobal, null, 2)
    // );
    //console.log(
    //   "BREAKPOINT fabricCanvasGlobal.width= " + fabricCanvasGlobal.canvasWidth
    // );
  };

  useEffect(() => {
    if (productType) {
      const selectedProduct = productOptions.find(
        (product) => product.product === productType
      );
      const width = selectedProduct.designWidth;
      const height = selectedProduct.designHeight;
      setArtBoardSettings({
        ...artBoardSettings,
        width: width,
        height: height,
      });
      handleInputChange("width", width);
      handleInputChange("height", height);
    }
    // if (productType === "T-shirt") {
    //   const width = 3951;
    //   const height = 4800;
    //   setArtBoardSettings({
    //     ...artBoardSettings,
    //     width: width,
    //     height: height,
    //   });
    //   handleInputChange("width", width);
    //   handleInputChange("height", height);
    //   // handleConfirmSettings(width, height);
    // } else if (productType === "Sweatshirt") {
    //   const width = 3852;
    //   const height = 4398;
    //   setArtBoardSettings({
    //     ...artBoardSettings,
    //     width: width,
    //     height: height,
    //   });
    //   handleInputChange("width", width);
    //   handleInputChange("height", height);
    //   // handleConfirmSettings(width, height);
    // } else if (productType === "Hoodie") {
    //   const width = 3531;
    //   const height = 2352;
    //   setArtBoardSettings({
    //     ...artBoardSettings,
    //     width: width,
    //     height: height,
    //   });
    //   handleInputChange("width", width);
    //   handleInputChange("height", height);
    //   // handleConfirmSettings(width, height);
    // }
  }, [productType]);

  const handleEditSettings = () => {
    // Enter edit mode
    setIsEditingSettings(true);
  };
  //   const [width, setWidth] = useState(1200);
  //   const [height, setHeight] = useState(1200);
  const [dpi, setDpi] = useState(72);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleOrientationChange = (event, newOrientation) => {
    if (newOrientation !== null) {
      setOrientation(newOrientation);
    }
  };

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Box sx={{ color: "white", m: 2 }}>
      <Box
        sx={{
          maxWidth: 400,
          margin: "auto",
          p: 3,
          border: "1px solid #ddd",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          {/* Editable project name */}
          {isEditing ? (
            <TextField
              value={projectName}
              onChange={handleProjectNameChange}
              variant="outlined"
              size="small"
              autoFocus
              sx={{ marginRight: 1 }}
            />
          ) : (
            <h3 style={{ margin: 0, color: theme.text.primary }}>
              {projectName}
            </h3>
          )}
          <IconButton
            onClick={toggleEditMode}
            size="small"
            aria-label={isEditing ? "Save project name" : "Edit project name"}
          >
            {isEditing ? <CheckIcon /> : <EditIcon />}
          </IconButton>
        </Box>
        <Typography variant="h5" sx={{ mb: 2, color: theme.text.primary }}>
          Settings
        </Typography>
        <Grid container spacing={2}>
          {isEditingSettings ? (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="unit-label" shrink>
                    Select Product Size
                  </InputLabel>
                  <Select
                    labelId="unit-label"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                    label="Select Product" // ✅ Ensure label is explicitly set
                  >
                    <MenuItem value="T-Shirt">T-Shirt</MenuItem>
                    <MenuItem value="Sweatshirt">Sweatshirt</MenuItem>
                    <MenuItem value="Hoodie">Hoodie</MenuItem>
                    <MenuItem value="Tank Top">Tank Top</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {/* Width and Height */}
              <Grid item xs={6}>
                <TextField
                  label="Width"
                  type="number"
                  value={localSettings.width}
                  onChange={(e) =>
                    handleInputChange("width", parseInt(e.target.value, 10))
                  }
                  InputProps={{ endAdornment: <span>px</span> }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Height"
                  type="number"
                  value={localSettings.height}
                  onChange={(e) =>
                    handleInputChange("height", parseInt(e.target.value, 10))
                  }
                  InputProps={{ endAdornment: <span>px</span> }}
                  fullWidth
                />
              </Grid>

              {/* Unit */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="unit-label">Unit</InputLabel>
                  <Select
                    labelId="unit-label"
                    value={localSettings.unit}
                    onChange={(e) => handleInputChange("unit", e.target.value)}
                  >
                    <MenuItem value="px">px</MenuItem>
                    <MenuItem value="in">in</MenuItem>
                    <MenuItem value="cm">cm</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* DPI */}
              <Grid item xs={6}>
                <TextField
                  label="DPI"
                  type="number"
                  value={localSettings.dpi}
                  onChange={(e) =>
                    handleInputChange("dpi", parseInt(e.target.value, 10))
                  }
                  fullWidth
                />
              </Grid>

              {/* Background Color */}
              <Grid item xs={6}>
                <TextField
                  label="Background Color"
                  type="color"
                  value={localSettings.backgroundColor}
                  onChange={(e) =>
                    handleInputChange("backgroundColor", e.target.value)
                  }
                  fullWidth
                  disabled={localSettings.transparentBackground} // Disable if transparent mode is enabled
                />
              </Grid>

              {/* Transparent Background Toggle */}
              <Grid item xs={6} sx={{ color: "black" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={localSettings.transparentBackground}
                      onChange={(e) =>
                        handleInputChange(
                          "transparentBackground",
                          e.target.checked
                        )
                      }
                    />
                  }
                  label="Transparent Background"
                />
              </Grid>

              {/* Orientation */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 1, color: theme.text.primary }}
                >
                  Orientation
                </Typography>
                <ToggleButtonGroup
                  value={localSettings.orientation}
                  exclusive
                  onChange={(e, newOrientation) =>
                    handleInputChange("orientation", newOrientation)
                  }
                  fullWidth
                >
                  <ToggleButton value="portrait">
                    <CropPortraitIcon />
                  </ToggleButton>
                  <ToggleButton value="landscape">
                    <CropLandscapeIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              {/* Confirm Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleConfirmSettings}
                >
                  Confirm
                </Button>
              </Grid>
            </>
          ) : (
            <>
              {/* Display Current Settings */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Width: {artBoardSettings.width}px
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Height: {artBoardSettings.height}px
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Unit: {artBoardSettings.unit}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  DPI: {artBoardSettings.dpi}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Orientation: {artBoardSettings.orientation}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Background Color:
                  {!artBoardSettings.transparentBackground ? (
                    <> {artBoardSettings.backgroundColor}</>
                  ) : (
                    " Transparent"
                  )}
                </Typography>
              </Grid>
              {/* Edit Button */}
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={handleEditSettings}
                >
                  Edit
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {/* <Stack
          direction="column"
          spacing={2}
          sx={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Button onClick={addText} variant="contained">
            Add Text
          </Button>
        </Stack> */}
      <ImageList
        sx={{ width: "100%", height: "auto" }}
        cols={!matchesXS ? 2 : 6}
      >
        {/* Placeholder for images */}
      </ImageList>
    </Box>
  );
};

export default CanvasMenuItemProject;
