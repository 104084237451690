import React, { useState, useContext } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
  Button,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CanvasContext } from "../CanvasContext";
import { fabric } from "fabric";
import { debounce } from "lodash";

const TextShadingComponent = ({ selectedObject, fabricCanvasGlobal }) => {
  const {
    offsetX,
    setOffsetX,
    offsetY,
    setOffsetY,
    blur,
    setBlur,
    color,
    setColor,
    isOpenTextShading,
    setIsOpenTextShading,
    activeShading,
    setActiveShading,
    offsetTextRef,
  } = useContext(CanvasContext);
  // const [isOpen, setIsOpen] = useState(true);
  // const [offsetX, setOffsetX] = useState(10);
  // const [offsetY, setOffsetY] = useState(-90);
  // const [blur, setBlur] = useState(5);
  // const [color, setColor] = useState("#727272");
  // const [opacity, setOpacity] = useState(100);
  const [outlineWidth, setOutlineWidth] = useState(0);
  // const [offsetText, setOffsetText] = useState(null);

  // Main text object (black)
  // const mainText = new fabric.IText("Headline", {
  //   left: 200,
  //   top: 150,
  //   fontSize: 300,
  //   fontFamily: "Roboto",
  //   fill: "black",
  //   selectable: false,
  // });

  // // Offset text object (red outline)
  // const offsetText = new fabric.IText("Headline", {
  //   left: mainText.left + 5, // Slightly offset position
  //   top: mainText.top + 5, // Slightly offset position
  //   fontSize: 300,
  //   fontFamily: "Roboto",
  //   fill: "", // No fill for the background text
  //   stroke: "red", // Set the stroke color
  //   strokeWidth: 3, // Outline thickness
  //   selectable: false,
  //   // strokeLineCap: "round",
  //   strokeLineJoin: "miter",
  //   // scaleY: 0.95,
  //   // scaleX: 0.95,
  //   // strokeMiterLimit: 10,
  //   // clipPath: clipText, // Set the larger text as the clipPath
  // });

  // Add both objects to the canvas
  // fabricCanvasGlobal.add(offsetText); // Add the offset text first

  // fabricCanvasGlobal.add(mainText); // Add the main text on top
  // fabricCanvasGlobal.add(clipText); // Add the offset text first

  // fabricCanvasGlobal.add(shadowText);

  //block shading function
  const blockShadow = () => {
    const shadowColor = color; // Color of the shadow
    const shadowLayers = Math.abs(offsetX); // Number of shadow layers
    const shadowOffsetX = offsetX >= 0 ? 0.5 : -0.5; // Horizontal offset for each layer
    const shadowOffsetY = offsetY >= 0 ? 0.5 : -0.5; // Vertical offset for each layer

    // Create an array to hold shadow layers
    const shadowObjects = [];

    // Generate shadow layers
    for (let i = 0; i < shadowLayers; i++) {
      const shadowText = new fabric.IText(selectedObject.text, {
        left: selectedObject.left + i * shadowOffsetX, // Gradual horizontal offset
        top: selectedObject.top + i * shadowOffsetY, // Gradual vertical offset
        fontSize: selectedObject.fontSize,
        fontFamily: selectedObject.fontFamily,
        fill: shadowColor, // Fill color for the shadow
        selectable: false, // Shadow is not interactive
      });

      shadowObjects.push(shadowText);
    }
    const allObjects = [...shadowObjects];
    const textWithShadowGroup = new fabric.Group(allObjects, {
      // left: selectedObject.left,
      // top: selectedObject.top,
      selectable: false,
    });
    return textWithShadowGroup;
  };

  // for offsetX, offsetY, blur, color
  React.useEffect(() => {
    if (
      selectedObject.shadow != null &&
      isOpenTextShading &&
      activeShading === "Drop Shadow"
    ) {
      updateShadow({
        color: color,
        blur: blur,
        offsetX: offsetX,
        offsetY: offsetY,
        affectStroke: false,
      });
    } else if (isOpenTextShading && activeShading === "Line Shadow") {
      // Update the existing offsetText object
      const offsetText = offsetTextRef.current;
      if (offsetText != null) {
        offsetText.set({
          left: selectedObject.left + offsetX / 10,
          top: selectedObject.top + offsetY / 10,
        });
        //custom shading properties of selected object
        selectedObject.set({
          shading: {
            shadingOption: "Line Shadow",
            offsetX: offsetX,
            offsetY: offsetY,
            blur: blur,
            color: color,
            isOpenTextShading: true,
            offsetText: offsetText,
          },
        });
      }
    } else if (isOpenTextShading && activeShading === "Block Shadow") {
      // Update the existing offsetText
      const offsetText = offsetTextRef.current;

      fabricCanvasGlobal.remove(offsetText);

      const textWithShadowGroup = blockShadow();
      // Group shadow layers and main text

      // Add the group to the canvas
      offsetTextRef.current = textWithShadowGroup;
      fabricCanvasGlobal.add(textWithShadowGroup);
      textWithShadowGroup.sendToBack();

      //custom shading properties of selected object
      selectedObject.set({
        shading: {
          shadingOption: "Block Shadow",
          offsetX: offsetX,
          offsetY: offsetY,
          blur: blur,
          color: color,
          isOpenTextShading: true,
          offsetText: textWithShadowGroup,
        },
      });
    }

    // selectedObject.set({
    //   shadow: {
    //     color: color, // Shadow color
    //     blur: blur, // Blur level
    //     offsetX: offsetX, // Horizontal offset
    //     offsetY: offsetY, // Vertical offset
    //     affectStroke: false, // Whether to apply shadow to strokes
    //     // nonScaling: true,
    //   },
    // });
    fabricCanvasGlobal.requestRenderAll();
  }, [
    offsetX,
    offsetY,
    blur,
    color,
    outlineWidth,
    selectedObject.text,
    // selectedObject.left,
    // selectedObject.top,
    selectedObject.fontSize,
    selectedObject.fontFamily,
    selectedObject.fontWeight,
  ]);

  //for handleObject moving and modifying
  React.useEffect(() => {
    if (!selectedObject) return;

    const handleObjectMoving = (e) => {
      const activeObject = e.target;

      if (
        activeObject === selectedObject &&
        offsetTextRef.current &&
        activeShading === "Line Shadow"
      ) {
        const offsetText = offsetTextRef.current;

        offsetText.set({
          left: activeObject.left + offsetX / 10,
          top: activeObject.top + offsetY / 10,
          text: activeObject.text,
        });
      } else if (
        activeObject === selectedObject &&
        offsetTextRef.current &&
        activeShading === "Block Shadow"
      ) {
        const offsetText = offsetTextRef.current;

        offsetText.set({
          left: activeObject.left,
          top: activeObject.top,
          text: activeObject.text,
        });
      }
      fabricCanvasGlobal.requestRenderAll();
    };

    // Attach the event listener for `object:moving`
    fabricCanvasGlobal.on("object:moving", handleObjectMoving);
    fabricCanvasGlobal.on("object:modified", handleObjectMoving);

    return () => {
      // Cleanup the event listener when the component unmounts
      fabricCanvasGlobal.off("object:moving", handleObjectMoving);
      fabricCanvasGlobal.off("object:modified", handleObjectMoving);
    };
  }, [selectedObject, offsetTextRef, fabricCanvasGlobal, offsetX, offsetY]);

  //for selected object's font, font size, font type and text
  React.useEffect(() => {
    if (!selectedObject) return;

    // Create the offsetText if it doesn't exist
    if (offsetTextRef.current && activeShading === "Line Shadow") {
      // Update the existing offsetText
      const offsetText = offsetTextRef.current;

      fabricCanvasGlobal.remove(offsetText);

      offsetText.set({
        text: selectedObject.text, // Update the text
        left: selectedObject.left + offsetX / 10, // Adjust position
        top: selectedObject.top + offsetY / 10,
        fontSize: selectedObject.fontSize, // Sync font size
        fontFamily: selectedObject.fontFamily, // Sync font family
        stroke: color, // Sync stroke color
        strokeWidth: 3, // Adjust if necessary
        selectable: false,
      });
      // offsetTextRef.current = offsetText; // Store reference
      fabricCanvasGlobal.add(offsetText);
      offsetText.sendToBack();
    } else if (offsetTextRef.current && activeShading === "Block Shadow") {
      // Remove the existing group (if any) from the canvas
      // if (offsetTextRef.current) {
      //   fabricCanvasGlobal.remove(offsetTextRef.current); // Remove the previous group
      //   offsetTextRef.current = null; // Clear the reference to avoid stale state
      // }
      // // Create the new block shadow group
      // const textWithShadowGroup = blockShadow(); // Your blockShadow function should return the new group
      // // Add the new group to the canvas
      // offsetTextRef.current = textWithShadowGroup; // Update the reference to the new group
      // fabricCanvasGlobal.add(offsetTextRef.current);
      // offsetTextRef.current.sendToBack(); // Send the group to the back if necessary
      // fabricCanvasGlobal.requestRenderAll(); // Re-render the canvas
    }
    fabricCanvasGlobal.requestRenderAll();

    // fabricCanvasGlobal.on("object:moving", handleObjectMoving);
    return () => {
      // fabricCanvasGlobal.off("object:moving", handleObjectMoving);
    };
  }, [
    selectedObject.text,
    // selectedObject.left,
    // selectedObject.top,
    selectedObject.fontSize,
    selectedObject.fontFamily,
    selectedObject.fontWeight,
    color,
  ]);

  const handleToggle = () => {
    setIsOpenTextShading(!isOpenTextShading);
  };

  // React.useEffect(() => {
  //   if (isOpenTextShading) handleShadingClick(activeShading);
  // }, [activeShading]);

  //for active shading option and if the shading option is open for text
  React.useEffect(() => {
    if (!isOpenTextShading) {
      selectedObject.set({
        // path: null,
        // skewX: 0,
        // skewY: 0,
        // scaleX: 1,
        // scaleY: 1,
        shadow: null,
        shading: null,
      });
      const offsetText = offsetTextRef.current;
      fabricCanvasGlobal.remove(offsetText);
      offsetTextRef.current = null;
      // pathRef.current = null; // Reset the path in the ref
      setActiveShading(null);
      // setIsChildListenerActive(false);
    } else if (isOpenTextShading && activeShading == null) {
      // setIsChildListenerActive(false);
    } else if (isOpenTextShading && activeShading != null) {
      // setIsChildListenerActive(true);
      // if (fabricCanvasGlobal.getActiveObject() == null)
      // selectedObject.path.set({ stroke: "" });
    }
    fabricCanvasGlobal.requestRenderAll();
  }, [isOpenTextShading, activeShading]);

  // for offset text of selected object.
  // React.useEffect(() => {}, [selectedObject.shading.offsetText]);

  const handleShadingClick = (option) => {
    if (!selectedObject) return;
    setActiveShading(option);

    if (option === "Drop Shadow") {
      const offsetText = offsetTextRef.current;
      fabricCanvasGlobal.remove(offsetText);
      offsetTextRef.current = null;
      selectedObject.set({
        shadow: {
          color: color, // Shadow color
          blur: blur, // Blur level
          offsetX: offsetX, // Horizontal offset
          offsetY: offsetY, // Vertical offset
          affectStroke: false, // Whether to apply shadow to strokes
          // nonScaling: true,
        },
      });

      //custom shading properties of selected object
      selectedObject.set({
        shading: {
          shadingOption: "Drop Shadow",
          offsetX: offsetX,
          offsetY: offsetY,
          blur: blur,
          color: color,
          isOpenTextShading: true,
        },
      });

      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Line Shadow") {
      const offsetText = offsetTextRef.current;
      fabricCanvasGlobal.remove(offsetText);
      offsetTextRef.current = null;

      selectedObject.set({ shadow: null });
      if (!offsetTextRef.current) {
        // Create the offsetText object if it doesn't exist
        const offsetText = new fabric.IText(selectedObject.text, {
          left: selectedObject.left + 5, // Slightly offset position
          top: selectedObject.top + 5, // Slightly offset position
          fontSize: selectedObject.fontSize,
          fontFamily: selectedObject.fontFamily,
          fill: "", // No fill for the background text
          stroke: color, // Set the stroke color
          strokeWidth: 3, // Outline thickness
          selectable: false,
          strokeLineJoin: "miter",
        });

        fabricCanvasGlobal.add(offsetText);
        offsetText.sendToBack();
        offsetTextRef.current = offsetText; // Store reference to the created object

        //custom shading properties of selected object
        selectedObject.set({
          shading: {
            shadingOption: "Line Shadow",
            offsetX: offsetX,
            offsetY: offsetY,
            blur: blur,
            color: color,
            isOpenTextShading: true,
            offsetText: offsetText,
          },
        });
      } else {
        // Update the existing offsetText object
        const offsetText = offsetTextRef.current;

        offsetText.set({
          text: selectedObject.text, // Update text if changed
          left: selectedObject.left + 5, // Update position
          top: selectedObject.top + 5,
          fontSize: selectedObject.fontSize, // Update font size
          fontFamily: selectedObject.fontFamily, // Update font family
          stroke: color, // Update stroke color
        });
        //custom shading properties of selected object
        selectedObject.set({
          shading: {
            shadingOption: "Line Shadow",
            offsetX: offsetX,
            offsetY: offsetY,
            blur: blur,
            color: color,
            isOpenTextShading: true,
            offsetText: offsetText,
          },
        });
      }

      fabricCanvasGlobal.requestRenderAll(); // Trigger re-render
    } else if (option === "Block Shadow") {
      const offsetText = offsetTextRef.current;
      fabricCanvasGlobal.remove(offsetText);
      offsetTextRef.current = null;

      selectedObject.set({ shadow: null });

      const textWithShadowGroup = blockShadow();
      // Group shadow layers and main text

      // Add the group to the canvas

      fabricCanvasGlobal.add(textWithShadowGroup);
      textWithShadowGroup.sendToBack();
      offsetTextRef.current = textWithShadowGroup;

      //custom shading properties of selected object
      selectedObject.set({
        shading: {
          shadingOption: "Block Shadow",
          offsetX: offsetX,
          offsetY: offsetY,
          blur: blur,
          color: color,
          isOpenTextShading: true,
          offsetText: textWithShadowGroup,
        },
      });

      // Re-render the canvas
      fabricCanvasGlobal.requestRenderAll();
    } else if (option === "Detailed Shadow") {
    }
  };

  const updateShadow = debounce((shadow) => {
    selectedObject.set({ shadow });
    selectedObject.set({
      shading: {
        shadingOption: activeShading,
        offsetX: shadow.offsetX,
        offsetY: shadow.offsetY,
        blur: shadow.blur,
        color: shadow.color,
        isOpenTextShading: true,
      },
    });
    fabricCanvasGlobal.requestRenderAll();
  }, 100); // Update every 100ms

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 400,
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      {/* Header with Toggle */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Text Shading</Typography>
        <IconButton onClick={handleToggle}>
          {isOpenTextShading ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>

      {/* Content (conditionally rendered) */}
      {isOpenTextShading && (
        <>
          {/* Shading Modes */}
          <Grid container spacing={1} sx={{ marginBottom: 2 }}>
            {[
              "Drop Shadow",
              "Line Shadow",
              "Block Shadow",
              // "Detailed Shadow",
            ].map((option, index) => (
              <Grid item xs={3} key={index}>
                <Button
                  variant={activeShading === option ? "text" : "outlined"}
                  disabled={activeShading === option ? true : false}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    padding: "8px",
                  }}
                  onClick={() => {
                    handleShadingClick(option);
                  }}
                >
                  {option}
                </Button>
              </Grid>
            ))}
          </Grid>

          {/* Color Picker */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              type="color"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              sx={{ width: 60 }}
            />
            <Typography>{color}</Typography>
            {/* <Typography>{opacity}%</Typography> */}
          </Box>

          {/* Offset Slider */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            Offset-X
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Slider
              value={offsetX}
              onChange={(e, value) => setOffsetX(value)}
              min={-100}
              max={100}
              sx={{ flex: 1 }}
            />
            <TextField
              type="number"
              value={offsetX}
              onChange={(e) => setOffsetX(parseInt(e.target.value) || 0)}
              InputProps={{
                endAdornment: <InputAdornment position="end">°</InputAdornment>,
              }}
              size="small"
              sx={{ width: 80 }}
            />
          </Box>

          {/* Angle Slider */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            Offset-Y
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Slider
              value={offsetY}
              onChange={(e, value) => setOffsetY(value)}
              min={-100}
              max={100}
              sx={{ flex: 1 }}
            />
            <TextField
              type="number"
              value={offsetY}
              onChange={(e) => setOffsetY(parseInt(e.target.value) || 0)}
              InputProps={{
                endAdornment: <InputAdornment position="end">°</InputAdornment>,
              }}
              size="small"
              sx={{ width: 80 }}
            />
          </Box>

          {/* Blur Slider */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            Blur
          </Typography>
          <Slider
            value={blur}
            onChange={(e, value) => setBlur(value)}
            min={0}
            max={50}
            sx={{ marginBottom: 2 }}
          />

          {/* Outline Width */}
          <Typography variant="subtitle2" sx={{ marginBottom: 1 }}>
            Outline Width
          </Typography>
          <Slider
            value={outlineWidth}
            onChange={(e, value) => setOutlineWidth(value)}
            min={0}
            max={10}
            sx={{ marginBottom: 2 }}
          />
        </>
      )}
    </Box>
  );
};

export default TextShadingComponent;
