import React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import TextSettings from "./TextSettings";
import { Box } from "@mui/material";
import ImageSettings from "./ImageSettings";
const TextSettingsPopover = ({
  selectedObject,
  // handleTextSettingsChange,
  anchorElText,
  setAnchorEl,
  setSelectedObject,
  setAnchorElText,
  fabricCanvasGlobal,
  setLoading,
}) => {
  //   const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setAnchorEl(null);
    // setSelectedObject(null);
    // setAnchorElText(null);
  };

  //   const open = Boolean(anchorElText);
  const open = selectedObject ? true : false;
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      {/* Text Settings Popover */}
      <Box
        sx={{
          transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
          opacity: open ? 1 : 0,
          transform: open ? "translateY(0)" : "translateY(-10px)",
        }}
        // id={id}
        // open={open}
        // anchorEl={anchorElText}
        // onClose={handleClose}
        // anchorOrigin={{
        //   vertical: "bottom",
        //   horizontal: "left",
        // }}
        // disableEnforceFocus // Allow focus on elements outside the popover
        // disableBackdropClick // Allow clicks outside the popover
      >
        {selectedObject && selectedObject.type === "i-text" ? (
          <TextSettings
            // onChange={handleTextSettingsChange}
            // currentSettings={{
            //   fontSize: selectedObject.fontSize || 16,
            //   fontFamily: selectedObject.fontFamily || "Arial",
            //   fill: selectedObject.fill || "#000000",
            //   textAlign: selectedObject.textAlign || "left",
            //   stroke: selectedObject.stroke || "",
            //   strokeWidth: selectedObject.strokeWidth || 0,
            //   opacity: (selectedObject.opacity || 1) * 100, // Convert decimal to percentage
            // }}
            selectedObject={selectedObject}
            fabricCanvasGlobal={fabricCanvasGlobal}
          />
        ) : (
          <Typography sx={{ padding: 2 }}></Typography>
        )}
        {selectedObject && selectedObject.type === "image" ? (
          <ImageSettings
            // onChange={handleTextSettingsChange}
            // currentSettings={{
            //   fontSize: selectedObject.fontSize || 16,
            //   fontFamily: selectedObject.fontFamily || "Arial",
            //   fill: selectedObject.fill || "#000000",
            //   textAlign: selectedObject.textAlign || "left",
            //   stroke: selectedObject.stroke || "",
            //   strokeWidth: selectedObject.strokeWidth || 0,
            //   opacity: (selectedObject.opacity || 1) * 100, // Convert decimal to percentage
            // }}
            selectedObject={selectedObject}
            fabricCanvasGlobal={fabricCanvasGlobal}
            setLoading={setLoading}
          />
        ) : (
          <Typography sx={{ padding: 2 }}></Typography>
        )}
      </Box>
    </>
  );
};

export default TextSettingsPopover;
