import api from "../axiosConfig";
const baseURL = process.env.REACT_APP_API_BASE_URL;

//saves canvas and preview image of canvas
// export const saveCanvas = (canvas, designName, designId) => {
//   // const designName = prompt("Enter a name for your design:");
//   const canvasData = canvas.toJSON();
//   const imageData = canvas.toDataURL("image/png");

//   const payload = {
//     name: designName,
//     canvas_json: JSON.stringify(canvasData),
//     image_preview: imageData,
//   };

//   fetch("http://localhost:8000/api/designs/", {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(payload),
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       if (data.message) {
//         alert("Design saved successfully!");
//       } else {
//         console.error(data);
//       }
//     });
// };
// Saves canvas and preview image of canvas
// export const saveCanvas = (canvas, designName, designId) => {
//   const canvasData = canvas.toJSON();
//   const imageData = canvas.toDataURL("image/png");

//   // Add extra properties to the canvas JSON
//   const extendedCanvasData = {
//     ...canvasData,
//     extraProperties: {
//       canvasWidth: canvas.canvasWidth,
//       canvasHeight: canvas.canvasHeight,
//       // originalWidth: canvas.originalWidth || canvas.getWidth(),
//       // originalHeight: canvas.originalHeight || canvas.getHeight(),
//       // viewportTransform: canvas.viewportTransform,
//       // backgroundColor: canvas.backgroundColor,
//     },
//   };

//   const payload = {
//     name: designName,
//     canvas_json: JSON.stringify(extendedCanvasData),
//     image_preview: imageData,
//   };

//   // Determine whether to create (POST) or update (PUT)
//   const url = designId
//     ? `http://localhost:8000/api/designs/${designId}/`
//     : "http://localhost:8000/api/designs/";
//   const method = designId ? "PUT" : "POST";

//   fetch(url, {
//     method: method,
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(payload),
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       if (data.message) {
//         alert(
//           designId
//             ? "Design updated successfully!"
//             : "Design saved successfully!"
//         );
//       } else {
//         console.error(data);
//       }
//     })
//     .catch((error) => {
//       console.error("Error saving design:", error);
//     });
// };

export const uploadImageToS3 = async (blobUrl, designId) => {
  try {
    ////console.log("Uploading image to S3..."); // ✅ Debugging log

    // Fetch the image blob
    const response = await fetch(blobUrl);
    if (!response.ok) throw new Error("Failed to fetch image blob.");

    const blob = await response.blob();

    // Generate a unique filename
    const filename = `image_${Date.now()}.png`;

    // Create a FormData object
    const formData = new FormData();
    formData.append("file", blob, filename);
    formData.append("design_id", designId); // Pass design ID to backend

    ////console.log("Sending FormData:", formData); // ✅ Debugging log

    // // Upload to the backend API, which uploads to S3
    // const uploadResponse = await fetch(`${baseURL}/api/upload/`, {
    //   method: "POST",
    //   body: formData,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //   },
    // });
    const uploadResponse = await api.post("/api/upload/", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Required for file uploads
      },
    });
    const result = uploadResponse.data;
    // const result = await uploadResponse.json();
    ////console.log("S3 Upload Result:", result); // ✅ Debugging log

    // if (!uploadResponse.ok) throw new Error(result.error || "Upload failed.");
    if (uploadResponse.status !== 201)
      throw new Error(result.error || "Upload failed.");

    return result.s3_url; // Return the S3 URL of the uploaded image
  } catch (error) {
    console.error("Error uploading image to S3:", error);
    return null;
  }
};

export const saveCanvas = async (canvas, designName, designId) => {
  const canvasData = canvas.toJSON();
  // const canvasData = canvas.toJSON(["clipPath"]); // ✅ Explicitly save clipPath

  const imageData = canvas.toDataURL("image/png");

  // 1️⃣ **First, save the design WITHOUT images** (if designId is missing)
  if (!designId) {
    const tempPayload = {
      name: designName,
      canvas_json: JSON.stringify(canvasData),
      image_preview: imageData,
    };

    try {
      // const response = await fetch(`${baseURL}/api/designs/`, {
      //   method: "POST",
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(tempPayload),
      // });
      const response = await api.post("/api/designs/", tempPayload);
      const data = response.data;
      // const data = await response.json();

      if (data.designId) {
        designId = data.designId; // ✅ Now we have a valid designId
      } else {
        console.error("Error creating design:", data);
        return null;
      }
    } catch (error) {
      console.error("Error saving design:", error);
      return null;
    }
  }

  // 2️⃣ **Now upload images to S3 and replace local blob URLs**
  // await Promise.all(
  //   canvasData.objects.map(async (obj) => {
  //     if (obj.type === "image" && obj.src.startsWith("blob:")) {
  //       const s3Url = await uploadImageToS3(obj.src, designId);
  //       obj.src = s3Url; // ✅ Replace local blob with S3 URL
  //     }
  //   })
  // );
  // ✅ Convert Blob URLs to Permanent URLs
  await Promise.all(
    canvasData.objects.map(async (obj) => {
      if (
        obj.clipPath &&
        obj.clipPath.src &&
        obj.clipPath.src.startsWith("blob:")
      ) {
        obj.clipPath.src = await uploadImageToS3(obj.clipPath.src, designId); // ✅ Upload & Replace URL
      }
      if (obj.type === "image" && obj.src.startsWith("blob:")) {
        obj.src = await uploadImageToS3(obj.src, designId); // ✅ Upload & Replace URL
      }
    })
  );

  const extendedCanvasData = {
    ...canvasData,
    extraProperties: {
      canvasWidth: canvas.canvasWidth,
      canvasHeight: canvas.canvasHeight,
    },
  };

  // 3️⃣ **Finally, update the design with correct image URLs**
  const finalPayload = {
    name: designName,
    canvas_json: JSON.stringify(extendedCanvasData),
    image_preview: imageData,
  };

  try {
    // const url = `${baseURL}/api/designs/${designId}/`; // ✅ Use the correct designId
    // const method = "PUT"; // ✅ Always update the design now
    // const response = await fetch(url, {
    //   method: method,
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(finalPayload),
    // });

    const response = await api.put(`/api/designs/${designId}/`, finalPayload);
    const data = response.data;
    // const data = await response.json();

    if (data.message) {
      // alert("Design saved successfully!");
      return designId; // ✅ Return designId properly
    } else {
      console.error(data);
      return null;
    }
  } catch (error) {
    console.error("Error updating design:", error);
    return null;
  }
};

//loads designs to canvas
export const loadDesign = (canvas) => {
  const loadCanvas = (canvasData) => {
    canvas.loadFromJSON(canvasData, () => {
      canvas.renderAll(); // Render the canvas after loading
      // alert("Design loaded successfully!");
    });
  };

  // Fetch saved design JSON from the backend
  fetch(`${baseURL}/api/designs/<design-id>/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      loadCanvas(JSON.parse(data.canvas_json));
    });
};

export const backgroundRemover = (
  fabricCanvasGlobal,
  selectedObject,
  setLoading,
  setError
) => {
  const handleRemoveBackground = async (retries = 10, delay = 2000) => {
    setLoading(true);

    if (!selectedObject || selectedObject.type !== "image") {
      console.error("No image selected or object is not an image.");
      setLoading(false);
      return;
    }

    let imageUrl = selectedObject.getSrc();

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const formData = new FormData();
      formData.append("image", blob, "image.png");

      const result = await fetch(
        `https://rembg-app-lh3hdxifra-ue.a.run.app/remove-bg`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!result.ok) {
        throw new Error(`HTTP error! Status: ${result.status}`);
      }

      const outputBlob = await result.blob();
      const newImageURL = URL.createObjectURL(outputBlob);

      selectedObject.setSrc(newImageURL, () => {
        fabricCanvasGlobal.renderAll();
      });

      setLoading(false);
    } catch (error) {
      if (retries > 0) {
        console.warn(
          `Retrying background removal... (${retries} retries left)`
        );

        // Wait before retrying
        await new Promise((resolve) => setTimeout(resolve, delay));

        // Recursive retry with reduced attempts
        return handleRemoveBackground(retries - 1, delay); // Increases delay slightly to avoid rate limit
      } else {
        console.error("Background removal failed after all retries.", error);
        // setError(true);
        setLoading(false);
      }
    }
  };
  handleRemoveBackground();
};

export const deleteImageFromS3 = async (s3_url, token) => {
  try {
    // const response = await fetch(
    //   `${baseURL}/delete-s3-image/`,
    //   { s3_url: s3_url },
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );
    const response = await api.post("/delete-s3-image/", { s3_url });
    return response.data;
  } catch (error) {
    console.error("Error deleting image from S3:", error);
    throw error;
  }
};
