import React, { useState } from "react";
import { TextField, Chip, Box, Stack, Typography } from "@mui/material";

const DynamicImagesArray = ({ approvedProductImg, setApprovedProductImg }) => {
  //   const [items, setItems] = useState(approvedProductImg);
  const [inputValue, setInputValue] = useState("");

  // Handle input change
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  // Add item to the array
  const handleAddItem = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      if (!approvedProductImg.includes(inputValue.trim())) {
        setApprovedProductImg([...approvedProductImg, inputValue.trim()]);
      }
      setInputValue(""); // Clear input field
    }
  };

  // Remove item when clicked
  const handleDeleteItem = (itemToDelete) => {
    setApprovedProductImg(
      approvedProductImg.filter((item) => item !== itemToDelete)
    );
  };

  return (
    <Box sx={{ width: 500, padding: 2 }}>
      {/* Input Field */}
      {/* <TextField
        fullWidth
        variant="outlined"
        label="Add Item"
        placeholder="Type and press Enter"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleAddItem} // Capture "Enter" key event
      /> */}

      {/* Display Items as Chips */}
      <Typography variant="h6" gutterBottom>
        Product Images:
      </Typography>
      {approvedProductImg.length === 0 ? (
        <Typography variant="caption" gutterBottom>
          Add at least 1 image
        </Typography>
      ) : (
        <></>
      )}
      <Stack direction="row" spacing={1} sx={{ mt: 2, flexWrap: "wrap" }}>
        {approvedProductImg.map((item, index) => (
          <Chip
            key={index}
            label={item.imgTitle}
            onDelete={() => handleDeleteItem(item)}
            color="primary"
            variant="outlined"
            sx={{ cursor: "pointer" }}
          />
        ))}
      </Stack>
    </Box>
  );
};

export default DynamicImagesArray;
