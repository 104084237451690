import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../Footer";
import { motion, AnimatePresence } from "framer-motion";
import { pageVariants, pageTransition } from "../Animations";
import { Helmet } from "react-helmet";
import MockupGenerator from "./MockupGenerator";
import ProductOptions from "./ProductOptions";
import ProductForm from "./ProductForm";
import { useParams } from "react-router-dom";
import { CanvasContext } from "../CanvasContext";
import api from "../axiosConfig";

const PublishProductPage = () => {
  const { productOptions } = useContext(CanvasContext);
  const { productType, designId } = useParams();
  const [selectedProduct, setSelectedProduct] = React.useState(
    productOptions.find((product) => product.product === productType)
  );
  const [approvedProductImg, setApprovedProductImg] = React.useState([]);
  const [isDesignSizeCorrect, setIsDesignSizeCorrect] = React.useState(null);
  const [fetchedProduct, setFetchedProduct] = useState(null);
  const [backDropLoading, setBackdropLoading] = useState(false);

  const fetchProductByDesign = async (designId, productType) => {
    try {
      const response = await api.get("api/publish-product/", {
        params: { designId, productType }, // ✅ Pass query parameters
      });

      // //console.log("✅ Product Data:", response.data);
      return response.data; // ✅ Return the product data
    } catch (error) {
      console.error(
        "❌ Error fetching product:",
        error.response?.data || error
      );
      return null; // ✅ Return null if no product is found
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchProductByDesign(designId, productType); // ✅ Wait for the async function to resolve
      //console.log("✅ Product Data:", data);
      setFetchedProduct(data);
    };

    fetchData(); // ✅ Call the async function inside useEffect
  }, [designId, productType]); // ✅ Re-run effect when these values change

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        // backgroundColor: "orange",
      }}
    >
      <Helmet>
        <title>Publish Design | Okay Artist</title>
        <meta
          name="description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          name="keywords"
          content="about okay artist, custom designs, mission"
        />
        <meta property="og:title" content="About Us | Okay Artist" />
        <meta
          property="og:description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta property="og:url" content="https://www.okayartist.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Okay Artist" />
        <meta
          name="twitter:description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>
      <Header />

      <Container
        id="container"
        sx={{
          //   backgroundColor: "red",

          mt: "5%",
          mb: "5%",
        }}
      >
        {/* <MockupGenerator /> */}
        <ProductForm
          selectedProduct={selectedProduct}
          approvedProductImg={approvedProductImg}
          setApprovedProductImg={setApprovedProductImg}
          setBackdropLoading={setBackdropLoading}
          fetchedProduct={fetchedProduct}
        />

        <Backdrop
          sx={{
            color: "#000000",
            position: "fixed", // Covers entire screen
            top: 0,
            left: 0,
            // width: "100vw", // Cover viewport width
            height: "100vh", // Cover viewport height
            zIndex: 1300, // Ensure it overlays everything
            backgroundColor: "rgba(210, 242,255, 0.5)",
          }}
          open={backDropLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>

      <Footer></Footer>
    </Box>
  );
};

export default PublishProductPage;
