import React, { useState, useContext, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ImageList from "@mui/material/ImageList";
import { fabric } from "fabric";
import { useMediaQuery } from "@mui/material";
import { CanvasContext } from "./CanvasContext";
import {
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useTheme } from "@mui/material";
import CropLandscapeIcon from "@mui/icons-material/CropLandscape";
import CropPortraitIcon from "@mui/icons-material/CropPortrait";
import { adjustCanvasZoomingArtboard } from "./Custom Hooks/CommonEffectsDesign";
import { useNavigate, useParams } from "react-router-dom";
import {
  uploadImageToS3,
  saveCanvas,
} from "./DesignCanvasComponents/design-processing-tools";

const CanvasMenuItemPublish = () => {
  const theme = useTheme();
  const {
    fabricCanvasGlobal,
    projectName,
    setProjectName,
    artBoardSettings,
    setArtBoardSettings,
    setLoading,
    productOptions,
  } = useContext(CanvasContext);
  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { designId } = useParams();

  // State to manage project name and edit mode
  //   const [projectName, setProjectName] = useState("My Project");
  const [isEditing, setIsEditing] = useState(false);
  const [unit, setUnit] = useState("px");
  const [orientation, setOrientation] = useState("landscape");

  const [isEditingSettings, setIsEditingSettings] = useState(false);
  const [productType, setProductType] = useState(null);
  // Local state to manage inputs for all settings
  const [localSettings, setLocalSettings] = useState({
    width: artBoardSettings.width,
    height: artBoardSettings.height,
    unit: artBoardSettings.unit,
    dpi: artBoardSettings.dpi,
    orientation: artBoardSettings.orientation,
  });

  useEffect(() => {
    setLocalSettings({
      width: artBoardSettings.width,
      height: artBoardSettings.height,
      unit: artBoardSettings.unit,
      dpi: artBoardSettings.dpi,
      orientation: artBoardSettings.orientation,
    });
  }, [artBoardSettings]);

  const handleInputChange = (field, value) => {
    setLocalSettings({
      ...localSettings,
      [field]: value,
    });
  };

  const handleConfirmSettings = (width, height) => {
    // Update global artBoardSettings and Fabric.js canvas
    // setArtBoardSettings(localSettings);

    if (fabricCanvasGlobal && fabricCanvasGlobal.getContext()) {
      //   const { width, height } = localSettings;

      // Update Fabric.js canvas dimensions
      fabricCanvasGlobal.setWidth(width);
      fabricCanvasGlobal.setHeight(height);

      fabricCanvasGlobal.canvasWidth = width;
      fabricCanvasGlobal.canvasHeight = height;
      // Adjust canvas zoom to fit within the viewport
      adjustCanvasZoomingArtboard(fabricCanvasGlobal, width, height);

      // Re-render the canvas to apply changes
      fabricCanvasGlobal.renderAll();
    }

    // Exit edit mode
    setIsEditingSettings(false);
    // //console.log(
    //   "BREAKPOINT fabricCanvasGlobal= " +
    //     JSON.stringify(fabricCanvasGlobal, null, 2)
    // );
    // //console.log(
    //   "BREAKPOINT fabricCanvasGlobal.width= " + fabricCanvasGlobal.canvasWidth
    // );
  };

  useEffect(() => {
    //console.log("BREAKPOINT productType= " + productType);
    if (productType) {
      const selectedProduct = productOptions.find(
        (product) => product.product === productType
      );
      //console.log("BREAKPOINT selectedProduct= " + selectedProduct);
      const width = selectedProduct.designWidth;
      const height = selectedProduct.designHeight;
      setArtBoardSettings({
        ...artBoardSettings,
        width: width,
        height: height,
      });
      handleConfirmSettings(width, height);
    }

    // if (productType === "T-Shirt") {
    //   const width = 3951;
    //   const height = 4800;
    //   setArtBoardSettings({
    //     ...artBoardSettings,
    //     width: width,
    //     height: height,
    //   });
    //   handleConfirmSettings(width, height);
    // } else if (productType === "Sweatshirt") {
    //   const width = 3852;
    //   const height = 4398;
    //   setArtBoardSettings({
    //     ...artBoardSettings,
    //     width: width,
    //     height: height,
    //   });
    //   handleConfirmSettings(width, height);
    // } else if (productType === "Hoodie") {
    //   const width = 3531;
    //   const height = 2352;
    //   setArtBoardSettings({
    //     ...artBoardSettings,
    //     width: width,
    //     height: height,
    //   });
    //   handleConfirmSettings(width, height);
    // }
  }, [productType]);

  // const saveCanvas = async (canvas, designName, designId) => {
  //   const canvasData = canvas.toJSON();
  //   const imageData = canvas.toDataURL("image/png");

  //   // Upload images to S3 and replace local blob URLs
  //   await Promise.all(
  //     canvasData.objects.map(async (obj) => {
  //       if (obj.type === "image" && obj.src.startsWith("blob:")) {
  //         const s3Url = await uploadImageToS3(obj.src, designId);
  //         obj.src = s3Url; // Replace local blob with S3 URL
  //       }
  //     })
  //   );

  //   const extendedCanvasData = {
  //     ...canvasData,
  //     extraProperties: {
  //       canvasWidth: canvas.canvasWidth,
  //       canvasHeight: canvas.canvasHeight,
  //     },
  //   };

  //   const payload = {
  //     name: designName,
  //     canvas_json: JSON.stringify(extendedCanvasData),
  //     image_preview: imageData,
  //   };

  //   const url = designId
  //     ? `http://localhost:8000/api/designs/${designId}/`
  //     : "http://localhost:8000/api/designs/";
  //   const method = designId ? "PUT" : "POST";

  //   try {
  //     const response = await fetch(url, {
  //       method: method,
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });

  //     const data = await response.json();

  //     if (data.message && data.designId) {
  //       alert(
  //         designId
  //           ? "Design updated successfully!"
  //           : "Design saved successfully!"
  //       );
  //       return data.designId; // ✅ Return designId properly
  //     } else {
  //       console.error(data);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error saving design:", error);
  //     return null;
  //   }
  // };

  const handlePublishClick = async () => {
    setLoading(true);
    const design_id = await saveCanvas(
      fabricCanvasGlobal,
      projectName,
      designId
    ); // ✅ Await the function
    setLoading(false);
    if (design_id) {
      // //console.log("BREAKPOINT design_id= " + design_id);
      navigate(`/publish/${productType}/${design_id}`); // ✅ Now this will be a valid designId, not a Promise
    } else if (designId) {
      // //console.log("BREAKPOINT designId= " + designId);
      navigate(`/publish/${productType}/${designId}`);
    } else {
      console.error("Failed to save design.");
    }
  };

  //   const [width, setWidth] = useState(1200);
  //   const [height, setHeight] = useState(1200);
  const [dpi, setDpi] = useState(72);

  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };

  const handleOrientationChange = (event, newOrientation) => {
    if (newOrientation !== null) {
      setOrientation(newOrientation);
    }
  };

  const handleProjectNameChange = (event) => {
    setProjectName(event.target.value);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <Box sx={{ color: "white", m: 2 }}>
      <Box
        sx={{
          maxWidth: 400,
          margin: "auto",
          p: 3,
          border: "1px solid #ddd",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 2,
          }}
        >
          {/* Editable project name */}
          {isEditing ? (
            <TextField
              value={projectName}
              onChange={handleProjectNameChange}
              variant="outlined"
              size="small"
              autoFocus
              sx={{ marginRight: 1 }}
            />
          ) : (
            <h3 style={{ margin: 0, color: theme.text.primary }}>
              {projectName}
            </h3>
          )}
          <IconButton
            onClick={toggleEditMode}
            size="small"
            aria-label={isEditing ? "Save project name" : "Edit project name"}
          >
            {isEditing ? <CheckIcon /> : <EditIcon />}
          </IconButton>
        </Box>
        <Typography variant="h5" sx={{ mb: 2, color: theme.text.primary }}>
          Publish Design
        </Typography>
        <Grid container spacing={2}>
          {isEditingSettings ? (
            <></>
          ) : (
            <>
              {/* Display Current Settings */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Width: {artBoardSettings.width}px
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Height: {artBoardSettings.height}px
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Unit: {artBoardSettings.unit}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  DPI: {artBoardSettings.dpi}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  sx={{ color: theme.text.primary }}
                >
                  Orientation: {artBoardSettings.orientation}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel id="unit-label" shrink>
                    Select Product
                  </InputLabel>
                  <Select
                    labelId="unit-label"
                    value={productType}
                    onChange={(e) => setProductType(e.target.value)}
                    label="Select Product" // ✅ Ensure label is explicitly set
                    required
                  >
                    <MenuItem value="T-Shirt">T-Shirt</MenuItem>
                    <MenuItem value="Sweatshirt">Sweatshirt</MenuItem>
                    <MenuItem value="Hoodie">Hoodie</MenuItem>
                    <MenuItem value="Tank Top">Tank Top</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Edit Button */}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handlePublishClick}
                  disabled={!productType ? true : false}
                >
                  Publish
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {/* <Stack
          direction="column"
          spacing={2}
          sx={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <Button onClick={addText} variant="contained">
            Add Text
          </Button>
        </Stack> */}
      <ImageList
        sx={{ width: "100%", height: "auto" }}
        cols={!matchesXS ? 2 : 6}
      >
        {/* Placeholder for images */}
      </ImageList>
    </Box>
  );
};

export default CanvasMenuItemPublish;
