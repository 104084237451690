import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
} from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { CssBaseline } from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "./AuthenticationContext";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import api from "./axiosConfig"; // Import the configured Axios instance
import axiosInstance from "./axiosInstance";
import { Helmet } from "react-helmet";

export default function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // For forgot password component
  const [isDialogOpen, setDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const userData = {
      username: username,
      password: password,
    };

    try {
      // const response = await axiosInstance.post("/auth/jwt/create/", userData);
      const response = await api.post("/auth/jwt/create/", userData);
      setAlert(false);
      const { access, refresh } = response.data;
      localStorage.setItem("accessToken", access);
      localStorage.setItem("refreshToken", refresh);
      api.defaults.headers.common["Authorization"] = `Bearer ${access}`;
      setIsAuthenticated({ isAuthenticated: true });

      // Get user group
      // const profile = await getUserGroup(data.access);
      const profile = await api.get("/me/group/");
      const userType = profile.data.user_type;
      // //console.log("BREAKPOINT group= " + userType);
      localStorage.setItem("userType", userType);

      // Redirect based on role
      if (userType === "Designers") {
        navigate("/designer-dashboard");
      } else if (userType === "Admin-product") {
        navigate("/dashboard");
      } else {
        navigate("/customer-dashboard");
      }
      // navigate("/");
    } catch (error) {
      setAlert(true);
      console.error(
        "Error during user login:",
        error.response ? error.response.data : error
      );
    }
    setLoading(false); // Stop loading after the response is received
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Helmet>
          <title>Login to Okay Artist</title>
          <meta
            name="description"
            content="Login to Okay Artist to access your account and explore custom design products. Sign in now to manage your profile and orders."
          />
          <meta
            name="keywords"
            content="Okay Artist login, sign in, account access"
          />
          <meta property="og:title" content="Login to Okay Artist" />
          <meta
            property="og:description"
            content="Sign in to your Okay Artist account to manage orders, explore custom designs, and enjoy personalized services."
          />
          <meta property="og:url" content="https://www.okayartist.com/login" />
          <meta
            property="og:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Login to Okay Artist" />
          <meta
            name="twitter:description"
            content="Access your Okay Artist account for custom design products and services."
          />
          <meta
            name="twitter:image"
            content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
          />
        </Helmet>
        <CssBaseline />
        <Header />
        <Container
          component="main"
          sx={{ mt: 8, mb: 2, flexGrow: 1 }}
          maxWidth={"xs"}
        >
          <div
            style={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form onSubmit={handleSubmit} style={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              {alert && (
                <Alert severity="error">Invalid username or password</Alert>
              )}

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading}
                endIcon={loading ? <CircularProgress size={24} /> : null}
              >
                {!loading ? "Sign In" : "Signing In..."}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Box>
                    <Button variant="text" onClick={handleOpenDialog}>
                      Forgot Password?
                    </Button>
                    <ForgotPasswordDialog
                      open={isDialogOpen}
                      onClose={handleCloseDialog}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Button>
                    <Link
                      to="/signup"
                      style={{ color: "inherit", textDecoration: "inherit" }}
                    >
                      Sign up
                    </Link>
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Container>
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
