import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Stack,
  Typography,
  Container,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import MockupGenerator from "./MockupGenerator";
import DynamicImagesArray from "./DynamicImagesArray";
import { useNavigate, useParams } from "react-router-dom";
import api from "../axiosConfig";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const ProductForm = ({
  approvedProductImg,
  setApprovedProductImg,
  selectedProduct,
  fetchedProduct,
  setBackdropLoading,
}) => {
  const navigate = useNavigate();

  const { designId } = useParams();
  const [formData, setFormData] = useState({
    // listingId: "",
    brand: selectedProduct.brand,
    description: fetchedProduct ? fetchedProduct.description : "",
    // designId: "",
    featuredImageTitle: "",
    featuredImageUrl: "",
    product: selectedProduct.product,
    title: fetchedProduct ? fetchedProduct.title : "",
    shippingRateFirstItem: selectedProduct.shippingRateFirstItem,
    shippingRateExtraItem: selectedProduct.shippingRateExtraItem,
    productMockups: approvedProductImg,
    designId: designId,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      description: fetchedProduct ? fetchedProduct.description : "",
      title: fetchedProduct ? fetchedProduct.title : "",
    });
  }, [fetchedProduct]);

  useEffect(() => {
    setFormData({ ...formData, productMockups: approvedProductImg });
  }, [approvedProductImg]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSubmit = (event) => {
  //   setBackdropLoading(true);
  //   // //console.log("BREAKPOINT formData= " + JSON.stringify(formData));
  //   event.preventDefault();
  //   fetch(`${baseURL}/api/publish-product/`, {
  //     method: "POST",
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(formData),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setBackdropLoading(false);
  //       navigate("/designer-dashboard");
  //       // alert("Product submitted successfully!");
  //       // //console.log(data);
  //     })
  //     .catch((error) => {
  //       // console.error("Error submitting product:", error);
  //       // alert("Failed to submit product.");
  //     });
  // };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setBackdropLoading(true);

    try {
      if (fetchedProduct) {
        const response = await api.put("/api/publish-product/", formData);
        //console.log("✅ Product submitted:", response.data);
        setBackdropLoading(false);
        navigate("/designer-dashboard");
      } else {
        const response = await api.post("/api/publish-product/", formData);
        //console.log("✅ Product submitted:", response.data);
        setBackdropLoading(false);
        navigate("/designer-dashboard");
      }

      // setBackdropLoading(false);
      // navigate("/designer-dashboard");

      // alert("Product submitted successfully!");
      // //console.log("✅ Product submitted:", response.data);
    } catch (error) {
      setBackdropLoading(false);

      console.error(
        "❌ Error submitting product:",
        error.response?.data || error
      );
      alert("Failed to submit product.");
    }
  };

  return (
    <Box>
      <Box>
        <MockupGenerator
          selectedProduct={selectedProduct}
          approvedProductImg={approvedProductImg}
          setApprovedProductImg={setApprovedProductImg}
        />
      </Box>
      <Container
        component="form"
        onSubmit={handleSubmit}
        sx={{
          //   maxWidth: 600,
          //   mx: "auto",
          p: 2,
          mt: "1%",
          border: "1px solid #ccc",
          borderRadius: 2,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Add Product
        </Typography>
        {/* <TextField
          fullWidth
          label="Listing ID"
          name="listingId"
          value={formData.listingId}
          onChange={handleChange}
          margin="normal"
          required
        /> */}
        {/* <TextField
          fullWidth
          label="Brand"
          name="brand"
          value={formData.brand}
          onChange={handleChange}
          margin="normal"
          required
        /> */}
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          multiline
          rows={4}
          required
        />
        {/* <TextField
          fullWidth
          label="Design ID"
          name="designId"
          value={formData.designId}
          onChange={handleChange}
          margin="normal"
          required
        /> */}
        {/* <TextField
          fullWidth
          label="Product"
          name="product"
          value={formData.product}
          onChange={handleChange}
          margin="normal"
          required
        /> */}
        <TextField
          fullWidth
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          margin="normal"
          required
        />
        {/* <Autocomplete
          multiple
          options={shirtColors}
          getOptionDisabled={(option) =>
            formData.productColor.length >= 3 &&
            !formData.productColor.includes(option)
          } // Disable options if 3 are already selected
          getOptionLabel={(option) => option}
          onChange={(event, value) => {
            setFormData({ ...formData, productColor: value }); // Update selected colors
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Shirt Colors"
              placeholder="Select up to 3 colors"
            />
          )}
        /> */}
        <DynamicImagesArray
          approvedProductImg={approvedProductImg}
          setApprovedProductImg={setApprovedProductImg}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={approvedProductImg.length === 0}
          >
            Submit
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProductForm;
