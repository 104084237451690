import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import Stack from "@mui/material/Stack";
import { Grid, Tooltip } from "@mui/material";
import logo from "../assets/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "./AuthenticationContext";
import { useMediaQuery } from "@mui/material";
import MobileMenu from "./MobileMenu";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import { Badge } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function Header() {
  const userType = localStorage.getItem("userType");
  const pages = [
    "Home",
    userType === "Designers" ? "Design" : null,
    // "Pricing",

    // "Product",
    "Catalog",
    "About",
    "Contact",
  ].filter(Boolean);

  const matchesXS = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const itemsQuantity = useSelector((state) => state.basket.itemsQuantity);

  const navigate = useNavigate();

  const [anchorElProfile, setAnchorElProfile] = React.useState(null);
  const handleMenuOpen = (event) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleMenuClose = (value) => {
    setAnchorElProfile(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLogout = async () => {
    // Optional: URL to your custom logout or token blacklist endpoint
    // const logoutEndpoint = "http://localhost:8000/auth/logout/";
    // const refreshToken = localStorage.getItem("refreshToken");

    // try {
    //   // Optional: Invalidate the refresh token on the server side
    //   // Only needed if your backend supports refresh token blacklisting
    //   await axios.post(
    //     logoutEndpoint,
    //     {
    //       refresh: refreshToken,
    //     },
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         // Include the access token in the request if your endpoint requires authentication
    //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //       },
    //     }
    //   );

    //   //console.log("Logout successful.");
    // } catch (error) {
    //   console.error("Error during logout:", error);
    // }

    // Clear tokens from localStorage (always do this)
    setIsAuthenticated(false);
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userType");
    // //console.log(isAuthenticated);
    // const accessToken = localStorage.getItem("accessToken");
    // //console.log(accessToken);

    // Update application state or context to reflect that the user is logged out
    // e.g., setAuthState({ isAuthenticated: false });

    // Redirect user to the login page or home page
    // This step depends on your routing setup
    // If using React Router, you might use `useNavigate()` to programmatically navigate
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenuOpen(open);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              justifyContent="space-between"
              alignItems="center"
            >
              {!matchesXS && (
                <Grid
                  item
                  xs={1}
                  sm={2}
                  md={3}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    variant="square"
                    // sx={{ height: "10vh", display: { xs: "block", md: "none" } }}
                    style={{
                      height: "15vh",
                      width: "auto",
                      paddingLeft: "30%",
                      paddingBottom: "5%",
                      paddingTop: "5%",
                      cursor: "pointer", // Add this line for hover effect
                    }}
                  />
                </Grid>
              )}
              {/* Menu and logo in mobile dimensions */}
              {matchesXS && (
                <Grid item xs={2} sm={4} md={6}>
                  <Box
                    sx={{
                      display: { xs: "flex", md: "flex" },
                      alignItems: "center",
                    }}
                  >
                    {/* <img src={logo} alt="Logo" style={{ height: "10vh" }} /> */}

                    <Box
                      sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
                    >
                      <MenuIcon onClick={toggleDrawer(true)} />
                    </Box>
                    <img
                      src={logo}
                      alt="Logo"
                      variant="square"
                      // sx={{ height: "10vh", display: { xs: "block", md: "none" } }}
                      style={{
                        height: "15vh",
                        width: "auto",
                        paddingTop: "5%",
                        paddingBottom: "5%",
                      }}
                      onClick={() => {
                        navigate("/");
                      }}
                    />
                  </Box>
                </Grid>
              )}

              {/* Menus in desktop dimensions */}
              {!matchesXS && (
                <Grid item xs={2} sm={4} md={6}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
                  >
                    {pages.map((page) => (
                      <Link
                        to={`/${page.toLowerCase()}`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        <Button
                          key={page}
                          onClick={handleCloseNavMenu}
                          sx={{
                            my: 2,
                            color: "text.secondary",
                            display: "block",
                          }}
                        >
                          {page}
                        </Button>
                      </Link>
                    ))}
                  </Stack>
                </Grid>
              )}

              {/* Login, logout and signup buttons  */}
              {!matchesXS && (
                <Grid
                  item
                  xs={1}
                  sm={2}
                  md={3}
                  sx={{
                    display: { xs: "none", md: "flex" },
                    justifyContent: "flex-end",
                  }}
                >
                  {isAuthenticated ? (
                    <>
                      <IconButton
                        onClick={handleMenuOpen}
                        sx={{ mr: 1, color: "text.primary" }}
                      >
                        <AccountCircleIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorElProfile}
                        open={Boolean(anchorElProfile)}
                        onClose={handleMenuClose}
                      >
                        {/* <Link to={"/user-profile"}> */}
                        <MenuItem
                          onClick={() => {
                            handleMenuClose();
                            if (userType === "Designers")
                              navigate("/designer-dashboard");
                            else navigate("/customer-dashboard");
                          }}
                        >
                          Profile
                        </MenuItem>
                        {/* </Link> */}
                        {/* <MenuItem onClick={handleMenuClose}>
                          My Account
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose}>Settings</MenuItem> */}
                      </Menu>
                      {/* <Button variant="contained" onClick={handleLogout}>
                        Logout
                      </Button> */}
                      <Tooltip title="Logout" placement="bottom">
                        <IconButton
                          sx={{ mr: 1, color: "text.primary" }}
                          onClick={handleLogout}
                        >
                          <LogoutIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Link to={"/login"}>
                        <Button
                          variant="outlined"
                          sx={{ mr: 1, color: "text.primary" }}
                        >
                          Login
                        </Button>
                      </Link>
                      <Link to={"/signup"}>
                        <Button
                          variant="contained"
                          sx={{ mr: 1, color: "text.primary" }}
                        >
                          Signup
                        </Button>
                      </Link>
                    </>
                  )}
                  <Link to="/purchase">
                    <Tooltip title="Basket">
                      <IconButton sx={{ mr: 1, color: "text.primary" }}>
                        <Badge badgeContent={itemsQuantity} color="primary">
                          <ShoppingBasketIcon />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>
                </Grid>
              )}

              {/* Login, logout and signup buttons for mobile dimensions  */}
              {matchesXS && (
                <Grid
                  item
                  xs={2}
                  sm={4}
                  md={6}
                  sx={{
                    display: { xs: "flex", sm: "flex", md: "none" },
                    justifyContent: "flex-end",
                  }}
                >
                  {isAuthenticated ? (
                    <>
                      <IconButton
                        onClick={handleMenuOpen}
                        sx={{ mr: 1, color: "text.primary" }}
                      >
                        <AccountCircleIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorElProfile}
                        open={Boolean(anchorElProfile)}
                        onClose={handleMenuClose}
                      >
                        {/* <Link to={"/user-profile"}> */}
                        <MenuItem
                          onClick={() => {
                            handleMenuClose();
                            if (userType === "Designers")
                              navigate("/designer-dashboard");
                            else navigate("/customer-dashboard");
                          }}
                        >
                          Profile
                        </MenuItem>
                        {/* </Link> */}
                        <MenuItem onClick={handleMenuClose}>
                          My Account
                        </MenuItem>
                        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
                      </Menu>
                      {/* <Button variant="contained" onClick={handleLogout}>
                        Logout
                      </Button> */}
                      <Tooltip title="Logout" placement="bottom">
                        <IconButton
                          sx={{ mr: 1, color: "text.primary" }}
                          onClick={handleLogout}
                        >
                          <LogoutIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Login">
                        <Link to={"/login"}>
                          <IconButton
                            variant="outlined"
                            sx={{ mr: 1, color: "text.primary" }}
                          >
                            <LoginIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Sign Up">
                        <Link to={"/signup"}>
                          <IconButton
                            variant="contained"
                            sx={{ mr: 1, color: "text.primary" }}
                          >
                            <PersonAddIcon />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </>
                  )}
                  <Link to="/purchase">
                    <Tooltip title="Basket">
                      <IconButton sx={{ mr: 1, color: "text.primary" }}>
                        <Badge badgeContent={itemsQuantity} color="primary">
                          <ShoppingBasketIcon fontSize="medium" />
                        </Badge>
                      </IconButton>
                    </Tooltip>
                  </Link>
                </Grid>
              )}

              <MobileMenu
                menuOpen={menuOpen}
                toggleDrawer={toggleDrawer}
              ></MobileMenu>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default Header;
