import React, { useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import UserProfileDetails from "./UserProfileDetails";
import PasswordManagement from "./PasswordManagement";
import Orders from "./Orders";
import AddressBook from "./AddressBook";
import Favorites from "./Favorites";
import GeneralSettings from "./GeneralSettings";
import Header from "../Header";
import Footer from "../Footer";
import Designs from "./Designs";

import theme from "../../theme";
import { ThemeProvider } from "@mui/material/styles";
import UserProducts from "./UserProducts";

const CustomerDashboard = () => {
  const [selectedSection, setSelectedSection] = useState("Profile");

  const renderSection = () => {
    switch (selectedSection) {
      case "Profile":
        return <UserProfileDetails />;
      case "Password Management":
        return <PasswordManagement />;
      case "Orders":
        return <Orders />;
      case "Address Book":
        return <AddressBook />;
      case "Favorites":
        return <Favorites />;
      case "General Settings":
        return <GeneralSettings />;
      //   case "Designs":
      //     return <Designs />;
      //   case "Products":
      //     return <UserProducts />;
      default:
        return <UserProfileDetails />;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Box sx={{ flexGrow: 1, mt: 4 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Paper elevation={3}>
                <List component="nav">
                  <ListItem
                    button
                    selected={selectedSection === "Profile"}
                    onClick={() => setSelectedSection("Profile")}
                  >
                    <ListItemText primary="Profile" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    selected={selectedSection === "Password Management"}
                    onClick={() => setSelectedSection("Password Management")}
                  >
                    <ListItemText primary="Password Management" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    selected={selectedSection === "Orders"}
                    onClick={() => setSelectedSection("Orders")}
                  >
                    <ListItemText primary="Orders" />
                  </ListItem>
                  <Divider />
                  {/* <ListItem
                    button
                    selected={selectedSection === "Designs"}
                    onClick={() => setSelectedSection("Designs")}
                  >
                    <ListItemText primary="Designs" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    selected={selectedSection === "Products"}
                    onClick={() => setSelectedSection("Products")}
                  >
                    <ListItemText primary="Products" />
                  </ListItem>
                  <Divider /> */}
                  {/* <ListItem
                    button
                    selected={selectedSection === "Address Book"}
                    onClick={() => setSelectedSection("Address Book")}
                  >
                    <ListItemText primary="Address Book" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    selected={selectedSection === "Favorites"}
                    onClick={() => setSelectedSection("Favorites")}
                  >
                    <ListItemText primary="Favorites" />
                  </ListItem>
                  <Divider />
                  <ListItem
                    button
                    selected={selectedSection === "General Settings"}
                    onClick={() => setSelectedSection("General Settings")}
                  >
                    <ListItemText primary="General Settings" />
                  </ListItem> */}
                  <Divider />
                </List>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper elevation={3} sx={{ p: 2 }}>
                {renderSection()}
              </Paper>
            </Grid>
          </Grid>
        </Box>
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default CustomerDashboard;
