import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Grid,
  Card,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import MockupGenerator from "../MockupGenerator";
import api from "../axiosConfig";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const EditProduct = () => {
  const userType = localStorage.getItem("userType");
  const { listingId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { productData } = location.state || {};

  const [product, setProduct] = useState(productData || null);
  const [title, setTitle] = useState(productData?.title || "");
  const [description, setDescription] = useState(
    productData?.description || ""
  );
  const [loading, setLoading] = useState(!productData);
  const [updating, setUpdating] = useState(false);
  const [approvedProductImg, setApprovedProductImg] = useState([]);

  // ✅ Fetch product details from backend if not passed via state
  useEffect(() => {
    if (!productData) {
      const fetchProduct = async () => {
        try {
          // const response = await fetch(`${baseURL}/api/product/${listingId}/`, {
          //   headers: {
          //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          //   },
          // });

          // if (response.ok) {
          //   const data = await response.json();
          //   setProduct(data);
          //   setTitle(data.title);
          //   setDescription(data.description);
          // } else {
          //   console.error("Failed to fetch product details.");
          // }
          const response = await api.get(`/api/product/${listingId}/`);

          setProduct(response.data);
          setTitle(response.data.title);
          setDescription(response.data.description);
        } catch (error) {
          console.error("Error fetching product details:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchProduct();
    }
  }, [listingId, productData]);

  // ✅ Handle updating product details
  const handleUpdateProduct = async () => {
    setUpdating(true);
    try {
      // const response = await fetch(
      //   `${baseURL}/api/product/${listingId}/update`,
      //   {
      //     method: "PUT",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //     },
      //     body: JSON.stringify({ title, description }),
      //   }
      // );

      // if (response.ok) {
      //   alert("Product updated successfully!");
      // } else {
      //   console.error("Failed to update product.");
      // }
      const response = await api.put(`/api/product/${listingId}/update`, {
        title,
        description,
      });
      if (userType === "Designers") {
        navigate("/designer-dashboard");
      } else {
        navigate("/customer-dashboard");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    } finally {
      setUpdating(false);
    }
  };

  // ✅ Handle deleting the product
  const handleDeleteProduct = async () => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;

    try {
      // const response = await fetch(
      //   `${baseURL}/api/product/${listingId}/delete`,
      //   {
      //     method: "DELETE",
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //     },
      //   }
      // );

      // if (response.ok) {
      //   alert("Product deleted successfully!");
      //   if (userType === "Designers") navigate("/designer-dashboard");
      //   else navigate("/customer-dashboard");
      // } else {
      //   console.error("Failed to delete product.");
      // }
      await api.delete(`/api/product/${listingId}/delete`);

      // alert("✅ Product deleted successfully!");

      if (userType === "Designers") {
        navigate("/designer-dashboard");
      } else {
        navigate("/customer-dashboard");
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", mt: 4 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>
        Edit Product
      </Typography>

      <TextField
        fullWidth
        label="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        multiline
        rows={4}
        label="Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        sx={{ mb: 2 }}
      />

      {/* ✅ Product Images Preview */}
      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
        Product Images
      </Typography>
      <Grid container spacing={2}>
        {product.images?.map((img, index) => (
          <Grid item xs={6} sm={4} key={index}>
            <Card>
              <CardMedia
                component="img"
                src={img.img}
                alt={`Product image ${index}`}
              />
              <CardContent>
                <Typography variant="body2">Image {index + 1}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* ✅ Mockup Generator for Adding Images */}
      <Typography variant="h6" sx={{ mt: 3 }}>
        Generate New Mockups
      </Typography>
      {/* <MockupGenerator
        selectedProduct={product}
        approvedProductImg={approvedProductImg}
        setApprovedProductImg={setApprovedProductImg}
      /> */}

      {/* ✅ Action Buttons */}
      <Box sx={{ display: "flex", gap: 2, mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleUpdateProduct}
          disabled={updating}
        >
          {updating ? "Saving..." : "Save Changes"}
        </Button>
        <Button variant="outlined" color="error" onClick={handleDeleteProduct}>
          Delete Product
        </Button>
      </Box>
    </Box>
  );
};

export default EditProduct;
