import React, { useEffect, useState } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import PaletteIcon from "@mui/icons-material/Palette";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"; // Bring Forward

const FloatingMenuComponent = ({
  selectedObject,
  fabricCanvasGlobal,
  offsetTextRef,
}) => {
  const [menuPosition, setMenuPosition] = useState(null);

  // Function to calculate the menu position
  const updateMenuPosition = () => {
    if (selectedObject && fabricCanvasGlobal) {
      const boundingRect = selectedObject.getBoundingRect();
      const canvasRect = fabricCanvasGlobal
        .getElement()
        .getBoundingClientRect();

      setMenuPosition({
        left: canvasRect.left + boundingRect.left + boundingRect.width / 2,
        top: canvasRect.top + boundingRect.top + boundingRect.height, // Place above the object
      });
    }
  };

  // Update menu position when the object is resized or moved
  useEffect(() => {
    updateMenuPosition();

    const handleObjectModified = () => {
      updateMenuPosition();
    };

    const handleObjectScaling = () => {
      updateMenuPosition();
    };

    fabricCanvasGlobal.on("object:modified", handleObjectModified);
    fabricCanvasGlobal.on("object:scaling", handleObjectScaling);
    fabricCanvasGlobal.on("object:moving", handleObjectModified);

    return () => {
      fabricCanvasGlobal.off("object:modified", handleObjectModified);
      fabricCanvasGlobal.off("object:scaling", handleObjectScaling);
      fabricCanvasGlobal.off("object:moving", handleObjectModified);
    };
  }, [selectedObject, fabricCanvasGlobal]);

  // Function to duplicate the selected object
  const handleDuplicate = () => {
    if (selectedObject) {
      selectedObject.clone((clonedObject) => {
        clonedObject.set({
          left: selectedObject.left + 20, // Offset to avoid overlap
          top: selectedObject.top + 20, // Offset to avoid overlap
          selectable: true,
          shading: selectedObject.shading,
          //   offset: null,
        });
        fabricCanvasGlobal.add(clonedObject);
        fabricCanvasGlobal.setActiveObject(clonedObject);
        fabricCanvasGlobal.requestRenderAll();
      });
    }
  };
  const handleDeleteClick = () => {
    fabricCanvasGlobal.remove(selectedObject);
    if (selectedObject.offset != null) {
      fabricCanvasGlobal.remove(selectedObject.offset);
      offsetTextRef.current = null;
    }
  };

  // Function to bring object forward
  const handleBringForward = () => {
    if (selectedObject) {
      fabricCanvasGlobal.bringForward(selectedObject);
      fabricCanvasGlobal.requestRenderAll();
    }
  };

  // Function to send object backward
  const handleSendBackward = () => {
    if (selectedObject) {
      fabricCanvasGlobal.sendBackwards(selectedObject);
      fabricCanvasGlobal.requestRenderAll();
    }
  };

  // Do not render the menu if the menuPosition is not yet calculated
  if (!menuPosition) return null;

  return (
    <Paper
      elevation={3}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "8px",
        borderRadius: "24px",
        position: "absolute",
        left: `${menuPosition.left}px`,
        top: `${menuPosition.top}px`,
        transform: "translateX(-50%)", // Center horizontally
        backgroundColor: "#ffffff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 1000, // Ensure it appears above the canvas
      }}
    >
      {/* Individual Menu Items */}
      {/* <IconButton>
        <AutoFixHighIcon />
      </IconButton> */}
      {/* <IconButton>
        <PaletteIcon />
      </IconButton> */}
      <IconButton>
        <ContentCopyIcon onClick={handleDuplicate} />
      </IconButton>
      {/* <IconButton>
        <ArrowDownwardIcon />
      </IconButton> */}

      {/* Bring Forward Button */}
      <IconButton onClick={handleBringForward}>
        <ArrowUpwardIcon />
      </IconButton>

      {/* Send Backward Button */}
      <IconButton onClick={handleSendBackward}>
        <ArrowDownwardIcon />
      </IconButton>
      <IconButton onClick={handleDeleteClick}>
        <DeleteOutlineIcon />
      </IconButton>
      {/* <IconButton>
        <TextFieldsIcon />
      </IconButton> */}
      {/* <IconButton>
        <MoreHorizIcon />
      </IconButton> */}
    </Paper>
  );
};

export default FloatingMenuComponent;
