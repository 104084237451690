// components/Designs.js
import React, {
  Suspense,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";
import { useParams } from "react-router-dom";
import { fabric } from "fabric";
import {
  adjustCanvasModalZooming,
  adjustCanvasToFitWindow,
} from "../../Custom Hooks/CommonEffectsDesign";
import { CanvasContext } from "../../CanvasContext";
import { Button } from "@mui/material";
import { setDesignFileURL } from "../../redux-slices/ImageSlice";
import WebFont from "webfontloader";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const DesignLoaderV2 = ({
  designName,
  productBrand,
  canvasWidth,
  canvasHeight,
  canvasZoomRationDesktop,
  canvasZoomRationMobile,
  canvasZoomRationDesktopXL,
  canvasZoomRationTablet,
  setLoading,
  modalRef,
  localCanvas,
  setLocalCanvas,
  canvasImage,
  setCanvasImage,

  ...props
}) => {
  const {
    productCanvasRef,
    selectedObject,
    setSelectedObject,
    setFabricCanvasGlobal,
    fabricCanvasGlobal,
    setClippingObject,
  } = useContext(CanvasContext);
  const { listingId } = useParams();
  const [design, setDesign] = useState(null);
  const isFetched = useRef(false);
  const [isCanvasInitialized, setIsCanvasInitialized] = React.useState(false);
  // const [text, setText] = useState("Custom Text");

  // const [localCanvas, setLocalCanvas] = useState(null);

  // Fetching design of a product by listingId
  useEffect(() => {
    // //console.log("BREAKPOINT listingId=" + listingId);
    const fetchDesign = async () => {
      try {
        const response = await fetch(
          `${baseURL}/api/product/design/${listingId}`
        );

        if (!response.ok) {
          if (response.status === 404) {
            console.warn("Design not found for this product.");
            setDesign(null); // Handle missing design gracefully
            return;
          }
          throw new Error(`Failed to fetch design. Status: ${response.status}`);
        }

        const data = await response.json();
        // //console.log("BREAKPOINT data:", data);
        setDesign(data);
      } catch (error) {
        console.error("Error fetching design:", error);
      }
    };

    if (!isFetched.current && listingId) {
      fetchDesign();
      isFetched.current = true;
    }
  }, [listingId]); // Depend on listingId

  useEffect(() => {
    if (design != null) {
      const canvasData = JSON.parse(design.canvas_json);
      const width = canvasData.extraProperties.canvasWidth;
      const height = canvasData.extraProperties.canvasHeight;

      // ✅ Initialize Fabric.js canvas
      const fabricCanvas = new fabric.Canvas(productCanvasRef.current, {
        width: width,
        height: height,
        preserveObjectStacking: true,
        interactive: true,
        // selection: true,
        enableRetinaScaling: true,
        // backgroundColor: "rgba(0,0,0,0)",
        // backgroundColor: "green",
        // evented: true,
      });
      setIsCanvasInitialized(true);
      setLocalCanvas(fabricCanvas);
      setFabricCanvasGlobal(fabricCanvas);
      adjustCanvasToFitWindow(fabricCanvas, width, height);
    }
  }, [design]);

  useEffect(() => {
    if (design != null && localCanvas && localCanvas.getContext()) {
      // //console.log("BREAKPOINT design= " + JSON.stringify(design, null, 2));
      const canvasData = JSON.parse(design.canvas_json);
      const width = canvasData.extraProperties.canvasWidth;
      const height = canvasData.extraProperties.canvasHeight;

      // ✅ Find all image objects in canvasData (No need to replace URLs)
      const imageObjects = canvasData.objects.filter(
        (obj) => obj.type === "image"
      );

      // ✅ Ensure all images have crossOrigin to prevent tainted canvas issues
      imageObjects.forEach((imgObj) => {
        if (imgObj.src) {
          imgObj.crossOrigin = "anonymous"; // Prevents CORS issues
        }
      });

      // ✅ Initialize Fabric.js canvas
      // const fabricCanvas = new fabric.Canvas(productCanvasRef.current, {
      //   width: width,
      //   height: height,
      //   preserveObjectStacking: true,
      //   interactive: true,
      //   // selection: true,
      //   enableRetinaScaling: true,
      //   // backgroundColor: "rgba(0,0,0,0)",
      //   // backgroundColor: "green",
      //   // evented: true,
      // });
      // setIsCanvasInitialized(true);
      // setLocalCanvas(fabricCanvas);
      // setFabricCanvasGlobal(fabricCanvas);
      // adjustCanvasToFitWindow(fabricCanvas, width, height);

      canvasData.objects.forEach((obj) => {
        if (obj.type === "image" && obj.src) {
          obj.crossOrigin = "anonymous"; // Allow cross-origin image loading
        }
        if (obj.clipPath) {
          fabric.util.enlivenObjects([obj.clipPath], (clipPaths) => {
            const clipObj = clipPaths[0];

            //console.log(
            //   "BREAKPOINT clipObj= " + JSON.stringify(clipObj, null, 2)
            // );

            if (clipObj) {
              // clipObj.set({
              //   absolutePositioned: true, // Keep clip path in place
              //   crossOrigin: "anonymous", // ✅ Ensure CORS is enabled
              // });
              // clipObj.crossOrigin = "anonymous";
              // clipObj.absolutePositioned = true;
              obj.clipPath.crossOrigin = "anonymous";
              obj.clipPath.absolutePositioned = true;
              obj.clipPath = clipObj;
              // obj.set({ clipPath: clipObj });
              // setClippingObject(clipObj);

              // if (obj.clipPath) {
              //   setMask(true);
              // } else {
              //   setMask(false);
              // }

              // if (obj.isClippingMask) {
              //   setClippingCheckbox(true);
              // } else {
              //   setClippingCheckbox(false);
              // }
            }
            // //console.log(
            //   "BREAKPOINT obj.clipPath= " +
            //     JSON.stringify(obj.clipPath, null, 2)
            // );
            // fabricCanvas.renderAll();
          });
        }
      });

      //console.log(
      //   "BREAKPOINT canvasData= " + JSON.stringify(canvasData, null, 2)
      // );
      const extractFontsFromCanvas = (canvasJson) => {
        const fonts = new Set();
        canvasJson.objects.forEach((obj) => {
          if (obj.type === "i-text" && obj.fontFamily) {
            fonts.add(obj.fontFamily);
          }
        });
        return Array.from(fonts);
      };
      const fontsToLoad = extractFontsFromCanvas(canvasData);

      if (fontsToLoad.length === 0) {
        loadCanvas(canvasData);
      } else {
        WebFont.load({
          google: {
            families: fontsToLoad,
          },
          active: () => {
            loadCanvas(canvasData); // Only load canvas after fonts are ready
          },
        });
      }

      function loadCanvas(canvasJson) {
        localCanvas.loadFromJSON(canvasJson, () => {
          localCanvas.renderAll();
        });
      }

      // ✅ Load the JSON into Fabric.js
      // localCanvas.loadFromJSON(canvasData, () => {
      //   localCanvas.renderAll();
      // });

      // fabricCanvas.setBackgroundColor(
      //   "transparent",
      //   fabricCanvas.renderAll.bind(fabricCanvas)
      // );

      // ✅ Event Listener for Canvas Changes
      const updateCanvasImage = (e) => {
        const imageData = localCanvas.toDataURL({
          format: "png",
          multiplier: 1, // Adjust resolution
        });

        const img = new Image();
        img.src = imageData;

        // img.onload = () => {
        //   //console.log("Image Width:", img.width);
        //   //console.log("Image Height:", img.height);
        // };

        setCanvasImage(imageData);
      };

      const handleObjectSelected = (e) => {
        const activeObject = e.selected[0];
        setSelectedObject(activeObject);
      };

      const handleObjectDeselected = () => {
        setSelectedObject(null);
        // setAnchorElText(null);
      };

      updateCanvasImage();
      localCanvas.on("object:modified", updateCanvasImage);
      localCanvas.on("object:added", updateCanvasImage);
      localCanvas.on("object:removed", updateCanvasImage);

      localCanvas.on("selection:created", handleObjectSelected);
      localCanvas.on("selection:updated", handleObjectSelected);
      localCanvas.on("selection:cleared", handleObjectDeselected);

      return () => {
        localCanvas.dispose();
        localCanvas.off("object:modified", updateCanvasImage);
        localCanvas.off("object:added", updateCanvasImage);
        localCanvas.off("object:removed", updateCanvasImage);

        localCanvas.off("selection:created", handleObjectSelected);
        localCanvas.off("selection:updated", handleObjectSelected);
        localCanvas.off("selection:cleared", handleObjectDeselected);
      };
    }
  }, [localCanvas]);

  //load design from json file
  // useEffect(() => {
  //   if (canvasJson && fabricCanvasGlobal && fabricCanvasGlobal.getContext()) {
  //     //console.log(
  //       "BREAKPOINT canvasJson= " + JSON.stringify(canvasJson, null, 2)
  //     );
  //     // const canvas = new fabric.Canvas("fabricCanvas");

  //     // Load the JSON into the Fabric.js canvas
  //     localCanvas.loadFromJSON(canvasJson, () => {
  //       localCanvas.renderAll();
  //     });
  //   }
  // }, [canvasJson, localCanvas]);

  //generates image for products
  // const generateImageFromCanvas = () => {
  //   if (fabricCanvasGlobal) {
  //     // fabricCanvasGlobal.set({ scaleX: 1, scaleY: 1 });
  //     // Generate the image as a Data URL (Base64 string)
  //     const dataURL = fabricCanvasGlobal.toDataURL({
  //       format: "png", // Output format: "png" or "jpeg"
  //       // quality: 1.0, // Quality for "jpeg" (0-1), not applicable for "png"
  //       multiplier: 3, // Scale factor (e.g., 2 for double size)
  //     });

  //     // //console.log("Canvas width:", localCanvas.width);
  //     // //console.log("Canvas height:", localCanvas.height);
  //     // //console.log("Retina scaling factor:", localCanvas.getRetinaScaling());
  //     // //console.log("BREAKPOINT dataURL= " + dataURL);

  //     // Optional: Download the image
  //     const link = document.createElement("a");
  //     link.href = dataURL;
  //     link.download = "canvas-image";
  //     link.click();
  //     // if (approvedProductImg.length < 3)
  //     //   setApprovedProductImg((prevApprovedProductImg) => [
  //     //     ...prevApprovedProductImg,
  //     //     { imgURL: dataURL, imgTitle: productColor.title },
  //     //   ]);
  //     return dataURL; // You can use this URL to display or store the image
  //   } else {
  //     console.error("Canvas is not available.");
  //   }
  // };
  // const generateImageFromCanvas = () => {
  //   if (fabricCanvasGlobal) {
  //     // ✅ Remove viewport transformations (prevents scaling issues)
  //     const originalTransform = fabricCanvasGlobal.viewportTransform;
  //     fabricCanvasGlobal.viewportTransform = [1, 0, 0, 1, 0, 0];

  //     // ✅ Ensure canvas is set to full size
  //     fabricCanvasGlobal.setZoom(1); // Reset zoom before export
  //     fabricCanvasGlobal.renderAll(); // Force refresh

  //     const dataURL = fabricCanvasGlobal.toDataURL({
  //       format: "png",
  //       multiplier: 1, // Keep at 1 to preserve original resolution
  //       // width: 3900, // Ensure full width
  //       // height: 4800, // Ensure full height
  //       width: fabricCanvasGlobal.extraProperties.canvasWidth,
  //       height: fabricCanvasGlobal.extraProperties.canvasHeight,
  //       enableRetinaScaling: false,
  //     });

  //     // ✅ Restore the original viewport transform after export
  //     fabricCanvasGlobal.viewportTransform = originalTransform;
  //     fabricCanvasGlobal.renderAll(); // Re-render to restore UI

  //     // ✅ Debugging Logs
  //     // //console.log("Canvas Export Width:", fabricCanvasGlobal.width);
  //     // //console.log("Canvas Export Height:", fabricCanvasGlobal.height);
  //     // //console.log("Exported Data URL:", dataURL.substring(0, 100)); // Check first 100 chars

  //     // ✅ Trigger download
  //     // const link = document.createElement("a");
  //     // link.href = dataURL;
  //     // link.download = "canvas-full-size.png";
  //     // link.click();

  //     return dataURL;
  //   } else {
  //     console.error("Canvas is not available.");
  //   }
  // };

  return (
    <>
      {/* <Button
        variant="outlined"
        onClick={generateImageFromCanvas}
        // disabled={approvedProductImg.length >= 3}
      >
        Add Product Image
      </Button>{" "} */}
    </>
  );
};

export default DesignLoaderV2;
