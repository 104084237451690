import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  product: {
    id: "",
    productType: "",
    listingId: "",
    quantity: 1,
    size: "",
    color: "",
    // unitPrice: 0,
    totalPrice: 0,
    mockupImageURL: "",
    designURL: "",
    listing: {},
  },
  // product: {},
  listing: {
    title: "",
    description: "",
    brand: "",
    listingId: "",
    unitPrice: [],
    sizes: [],
    shippingRates: {},
  }, // This will store each item with its properties
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setBasketProduct: (state, action) => {
      const { name, value } = action.payload;
      if (state.product.hasOwnProperty(name)) {
        // //console.log("inside productSlice= " + name);
        state.product[name] = value;
      } else {
        // //console.log("BREAKPOINT property in ProductSlice not found");
      }
    },
    setListing: (state, action) => {
      const { name, value } = action.payload;
      if (state.listing.hasOwnProperty(name)) {
        // //console.log("inside productSlice= " + name);
        state.listing[name] = value;
      } else {
        // //console.log("BREAKPOINT property in ProductSlice not found");
      }
    },
    initializeProduct: (state) => {
      state.product.id = uuidv4(); // Generate a unique ID using UUID
    },
    clearUpProduct: (state) => {
      state.product.id = "";
      state.product.quantity = 0;
      state.product.size = "";
      state.product.totalPrice = 0;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setBasketProduct,
  setListing,
  initializeProduct,
  clearUpProduct,
} = productSlice.actions;

export default productSlice.reducer;
