import { Box, Button } from "@mui/material";
import React, { useContext, useEffect, useState, useRef } from "react";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import { CanvasContext } from "../CanvasContext";
import { setProductPreviewMockupURL } from "../redux-slices/ImageSlice";
import { useLocation, useParams } from "react-router-dom";
import { adjustCanvasZooming } from "../Custom Hooks/CommonEffectsDesign";
import ProductColorOptions from "../Design Templates/ProductColorOptions";
import api from "../axiosConfig";

// const imageMask =
//   "https://product-images-okayartist.s3.us-east-1.amazonaws.com/generic-images/bella-canvas-3001-mockup-mask.svg";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const MockupGenerator = ({
  selectedProduct,
  approvedProductImg,
  setApprovedProductImg,
}) => {
  const [design, setDesign] = useState(null);
  const [img, setImg] = useState(null);
  const [imgProduct, setImgProduct] = useState();
  const [localCanvas, setLocalCanvas] = useState(null);
  const [currentProductImage, setCurrentProductImage] = useState(null);
  const isFetched = useRef(false);

  const location = useLocation();
  const { canvasJson } = location.state || {};
  const {
    mockupGeneratorCanvasRef,
    fabricCanvasGlobal,
    setFabricCanvasGlobal,
    productColorOption,
    productImages,
    productColor,
  } = useContext(CanvasContext);
  const dispatch = useDispatch();
  const { designId } = useParams();
  //console.log("BREAKPOINT productColor.title= " + productColor.title);

  //   const designImage = fabricCanvasGlobal.toDataURL("image/png");

  //   useEffect(() => {
  //     const imageUrl = encodeURIComponent(imageDesignBG);
  //     const fetchUrl = `${baseURL}/api/fetch-image/?image_url=${imageUrl}`;

  //     fetch(fetchUrl, {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "image/png",
  //       },
  //     })
  //       .then((response) => response.blob())
  //       .then((imageBlob) => {
  //         const imageObjectURL = URL.createObjectURL(imageBlob);
  //         setImg(imageObjectURL);
  //       })
  //       .catch((error) => console.error("Error fetching image:", error));
  //   }, []);

  //fetching image function for CORS purpose
  const fetchImage = async (image) => {
    try {
      const imageUrl = encodeURIComponent(image);
      const fetchUrl = `${baseURL}/api/fetch-image/?image_url=${imageUrl}`;

      const response = await fetch(fetchUrl, {
        method: "GET",
        headers: {
          "Content-Type": "image/png",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      const imageBlob = await response.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);

      //console.log("Fetched image imageObjectURL:", imageObjectURL);
      return imageObjectURL; // Return the image URL for further use
    } catch (error) {
      console.error("Error fetching image:", error);
      throw error;
    }
  };

  //fetching design of user
  useEffect(() => {
    // Fetch designs from the backend
    const fetchDesign = async () => {
      try {
        // const response = await fetch(
        //   `http://localhost:8000/api/design/${designId}`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        //     },
        //   }
        // );
        // if (response.ok) {
        //   const data = await response.json();
        //   //console.log("BREAKPOINT data= " + JSON.stringify(data, null, 2));
        //   setDesign(data);
        // } else {
        //   console.error("Failed to fetch designs");
        // }
        const response = await api.get(`/api/design/${designId}`);

        // //console.log(
        //   "BREAKPOINT data= ",
        //   JSON.stringify(response.data, null, 2)
        // );
        setDesign(response.data);
      } catch (error) {
        console.error("Error fetching designs:", error);
      } finally {
        // setLoading(false);
      }
    };

    if (!isFetched.current) {
      fetchDesign();
      isFetched.current = true; // Set to true to prevent further fetches
    }
  }, [designId]);

  //loads images to canvas
  function loadImage(url) {
    return new Promise((resolve, reject) => {
      fabric.Image.fromURL(
        url,
        (img) => {
          resolve(img);
        },
        {
          //   crossOrigin: "anonymous", // Allow cross-origin loading
          onError: (err) => reject(err),
        }
      );
    });
  }

  //add design image to canvas
  useEffect(() => {
    const loadAndAddDesign = async () => {
      // if (design != null) setImgDesignState(fetchImage(design.image_preview));
      // setImgProduct()
      //console.log("BREAKPOINT design= " + JSON.stringify(design, null, 2));
      // let imageDesign;
      // if (design != null) imageDesign = fetchImage(design.image_preview);
      // //console.log("BREAKPOINT imgDesignState= " + imgDesignState);
      if (design != null && localCanvas && localCanvas.getContext()) {
        // Fetch the image from the backend
        const imgDesignURL = await fetchImage(design.image_preview);
        Promise.all([loadImage(imgDesignURL)])
          .then(([imgDesign]) => {
            imgDesign.set({
              // left: 1000, // Optional: Set initial position
              // top: 1000,
              selectable: true, // Allow selection
              evented: true, // Allow events like dragging
              // clipPath: clipRectangle, // Set the rectangle as the clipPath
            });

            fabric.loadSVGFromURL(
              selectedProduct.imageMask,
              function (objects, options) {
                var svgObj = fabric.util.groupSVGElements(objects, options);
                svgObj.set({
                  // left: 2250 - imgDesign.getScaledWidth() / 2 + 100,
                  // (fabricCanvas.width / zoom - img1.width * img1.scaleX) / 2,
                  //   top: 200,
                  //   left: props.canvasWidth / 2 - imgDesign.getScaledWidth() / 2,
                  //   scaleX: designRatio,
                  //   scaleY: designRatio, // Adjust scale as needed
                  originX: "left",
                  originY: "top",
                  absolutePositioned: true,
                });

                // Calculate scaling factor
                const imgWidth = imgDesign.width;
                const imgHeight = imgDesign.height;
                const svgWidth = svgObj.width;
                const svgHeight = svgObj.height;

                // Calculate scaling factors for width and height
                const scaleX = svgWidth / imgWidth;
                const scaleY = svgHeight / imgHeight;

                // Use the smaller scaling factor to maintain aspect ratio
                const scale = Math.min(scaleX, scaleY);

                imgDesign.clipPath = svgObj;

                imgDesign.set({
                  left: svgObj.left,
                  top: svgObj.top,
                  scaleX: scale,
                  scaleY: scale,
                });
                localCanvas.renderAll();
              }
            );

            localCanvas.add(imgDesign);
            localCanvas.setActiveObject(imgDesign);

            localCanvas.renderAll();
          })
          .catch((error) => {
            console.error("Error loading images:", error);
          });
        //   localCanvas.add(design.image_preview);
        localCanvas.renderAll();
      }
    };
    loadAndAddDesign();
  }, [design]);

  //adds product mockup image
  useEffect(() => {
    const loadAndAddProduct = async () => {
      if (
        productColorOption != null &&
        localCanvas &&
        localCanvas.getContext()
      ) {
        //console.log("BREAKPOINT productColorOption= " + productColorOption);
        const imgProductURL = await fetchImage(productColorOption);
        // Load the new image
        Promise.all([loadImage(imgProductURL)])
          .then(([ImgProduct]) => {
            // Remove the existing product image if any
            if (currentProductImage) {
              localCanvas.remove(currentProductImage);
            }

            // Add the new image to the canvas
            ImgProduct.set({
              left: 1000 - ImgProduct.getScaledWidth() / 2, // Optional: Adjust position as needed
              top: 1000 - ImgProduct.getScaledHeight() / 2,
              selectable: false,
              evented: false,
              shadow: {
                color: "rgba(0, 0, 0, 0.5)", // Shadow color
                blur: 10, // Shadow blur radius
                offsetX: 5, // Horizontal offset
                offsetY: 5, // Vertical offset
              },
            });

            localCanvas.add(ImgProduct);
            localCanvas.sendToBack(ImgProduct);
            localCanvas.renderAll();

            // Update the reference to the current product image
            setCurrentProductImage(ImgProduct);
          })
          .catch((error) => {
            console.error("Error loading images:", error);
          });
      }
    };
    loadAndAddProduct();
  }, [productColorOption]);

  //create canvas initial
  useEffect(() => {
    const fabricCanvas = new fabric.Canvas(mockupGeneratorCanvasRef.current, {
      width: 2000,
      height: 2000,
      enableRetinaScaling: false,
      preserveObjectStacking: true,
      interactive: true,
      backgroundColor: "#d8f1fe",
      stroke: "rgba(255,255,255,0.5)",
      strokeWidth: 5,
      shadow: "rgba(0,0,0,0.6) 5px 5px 5px",
    });
    // setFabricCanvasGlobal(fabricCanvas);
    setLocalCanvas(fabricCanvas);
    adjustCanvasZooming(fabricCanvas);
    // fabricCanvas.add(design.image_preview);
    fabricCanvas.renderAll();

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  //generates image for products
  const generateImageFromCanvas = () => {
    if (localCanvas) {
      // Generate the image as a Data URL (Base64 string)
      const dataURL = localCanvas.toDataURL({
        format: "jpeg", // Output format: "png" or "jpeg"
        quality: 1.0, // Quality for "jpeg" (0-1), not applicable for "png"
        multiplier: 2.5, // Scale factor (e.g., 2 for double size)
      });

      setImg(dataURL);
      //console.log("Canvas width:", localCanvas.width);
      //console.log("Canvas height:", localCanvas.height);
      //console.log("Retina scaling factor:", localCanvas.getRetinaScaling());
      //console.log("BREAKPOINT dataURL= " + dataURL);

      // Optional: Download the image
      // const link = document.createElement("a");
      // link.href = dataURL;
      // link.download = "canvas-image.jpeg";
      // link.click();
      if (approvedProductImg.length < 3)
        setApprovedProductImg((prevApprovedProductImg) => [
          ...prevApprovedProductImg,
          { imgURL: dataURL, imgTitle: productColor.title },
        ]);
      return dataURL; // You can use this URL to display or store the image
    } else {
      console.error("Canvas is not available.");
    }
  };

  //saves mockups when the product color changes
  //   useEffect(() => {}, [productColors]);

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "relative",
          // zIndex: 100, // Ensure canvas is above other elements
          // backgroundColor: "blue",
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            //   position: "absolute",
            //   left: 0,
            //   top: "10%",
            overflow: "auto",
            height: "50vh",
            //   zIndex: 2,
          }}
        >
          <ProductColorOptions
            // listingId={"custom-vintage-photo-tshirtbellacanvas3001"}
            productBrand={selectedProduct.brand}
          />
        </Box>

        {/* <Button onClick={handleButtonClick}>click</Button> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 1,
          }}
        >
          {/* <Box sx={{ position: "absolute", left: 0, top: 0 }}> */}
          <Button
            variant="outlined"
            onClick={generateImageFromCanvas}
            disabled={approvedProductImg.length >= 3}
          >
            Add Product Image
          </Button>
          {/* </Box> */}

          <canvas
            style={{ display: "block" }}
            ref={mockupGeneratorCanvasRef}
          ></canvas>
        </Box>
      </Box>
      {/* <img src={img} /> */}
    </Box>
  );
};

export default MockupGenerator;
