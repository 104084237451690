import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  Slider,
  IconButton,
  TextField,
  InputAdornment,
  Grid,
  Button,
  Popover,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CanvasContext } from "../CanvasContext";
import { fabric } from "fabric";
import { debounce } from "lodash";
import { SketchPicker } from "react-color";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const TextGradientComponent = ({ selectedObject, fabricCanvasGlobal }) => {
  const {
    // isOpenTextBorder,
    // setIsOpenTextBorder,
    isOpenTextGradient,
    setIsOpenTextGradient,
    strokeSize,
    setStrokeSize,
    strokeColor,
    setStrokeColor,
    gradientStops,
    setGradientStops,
    angle,
    setAngle,
    colorPickerAnchor,
    setColorPickerAnchor,
    selectedStopIndex,
    setSelectedStopIndex,
  } = useContext(CanvasContext);

  // const [gradientStops, setGradientStops] = useState([
  //   { offset: 0, color: "#ff0000" },
  //   { offset: 50, color: "#0000ff" },
  //   { offset: 100, color: "#000000" },
  // ]);
  // const [angle, setAngle] = useState(90); // Gradient angle in degrees
  // const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  // const [selectedStopIndex, setSelectedStopIndex] = useState(null);

  React.useEffect(() => {
    if (!selectedObject) return;
    if (isOpenTextGradient) updateShadow(strokeSize, strokeColor);

    // fabricCanvasGlobal.requestRenderAll();

    // fabricCanvasGlobal.on("object:moving", handleObjectMoving);
    return () => {
      // fabricCanvasGlobal.off("object:moving", handleObjectMoving);
    };
  }, [strokeColor, strokeSize]);

  const handleToggle = () => {
    setIsOpenTextGradient(!isOpenTextGradient);
  };

  // React.useEffect(() => {
  //   if (isOpenTextShading) handleShadingClick(activeShading);
  // }, [activeShading]);

  React.useEffect(() => {
    if (!isOpenTextGradient) {
      selectedObject.set({
        // path: null,
        // skewX: 0,
        // skewY: 0,
        // scaleX: 1,
        // scaleY: 1,
        fill: "#000000",
        // stroke: "",
        // strokeWidth: 0,
        gradient: null,
      });

      // setIsChildListenerActive(false);
    } else {
      updateShadow(strokeSize, strokeColor);
    }
    fabricCanvasGlobal.requestRenderAll();
  }, [isOpenTextGradient]);

  useEffect(() => {
    if (isOpenTextGradient) applyGradient();
  }, [angle, gradientStops]);

  const updateShadow = debounce((strokeSize, strokeColor) => {
    // selectedObject.set({ stroke: strokeColor, strokeWidth: strokeSize });
    applyGradient();
    //custom shading properties of selected object
    selectedObject.set({
      gradient: {
        angle: angle,
        gradientStops: gradientStops,
        isOpenTextGradient: true,
      },
    });
    fabricCanvasGlobal.requestRenderAll();
  }, 100); // Update every 100ms

  // Handle color picker open
  const handleColorPickerOpen = (event, index) => {
    setColorPickerAnchor(event.currentTarget);
    setSelectedStopIndex(index);
  };

  // Handle color picker close
  const handleColorPickerClose = () => {
    setColorPickerAnchor(null);
    setSelectedStopIndex(null);
  };

  // Handle color change
  const handleColorChange = (color) => {
    if (selectedStopIndex !== null) {
      const newStops = [...gradientStops];
      newStops[selectedStopIndex].color = color.hex;
      setGradientStops(newStops);
    }
  };

  // Handle offset change
  const handleOffsetChange = (index, newOffset) => {
    const newStops = [...gradientStops];
    newStops[index].offset = newOffset;
    setGradientStops(newStops);
  };

  // Add new color stop
  const addColorStop = () => {
    if (gradientStops.length < 5) {
      setGradientStops([...gradientStops, { offset: 50, color: "#ffffff" }]);
    }
  };

  // Remove color stop
  const removeColorStop = (index) => {
    if (gradientStops.length > 2) {
      setGradientStops(gradientStops.filter((_, i) => i !== index));
    }
  };

  // Apply gradient to fabric.js text
  const applyGradient = async () => {
    if (selectedObject && fabricCanvasGlobal) {
      const radians = (angle * Math.PI) / 180; // Convert degrees to radians
      // const x2 = 0.5 + 0.5 * Math.cos(radians);
      // const y2 = 0.5 + 0.5 * Math.sin(radians);
      const x2 = Math.abs(Math.cos(radians)); // Controls horizontal spread
      const y2 = Math.abs(Math.sin(radians)); // Controls vertical spread
      // const x2 = angle % 180 === 0 ? 1 : 0; // 1 for horizontal, 0 for vertical
      // const y2 = angle % 180 === 0 ? 0 : 1; // 1 for vertical, 0 for horizontal
      //console.log("BREAKPOINT x2= " + x2 + ", y= " + y2);

      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "percentage",
        coords: { x1: 0, y1: 0, x2, y2 },
        colorStops: gradientStops.map((stop) => ({
          offset: stop.offset / 100,
          color: stop.color,
        })),
      });
      await document.fonts.load(`1em ${selectedObject.fontFamily}`);
      selectedObject.setCoords();
      selectedObject.set("fill", gradient);

      fabricCanvasGlobal.renderAll();
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 400,
        padding: 2,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      {/* Header with Toggle */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6">Gradient</Typography>
        <IconButton onClick={handleToggle}>
          {isOpenTextGradient ? <RemoveIcon /> : <AddIcon />}
        </IconButton>
      </Box>

      {/* Content (conditionally rendered) */}
      {isOpenTextGradient && (
        <>
          <Typography variant="h6">Gradient Picker</Typography>

          {/* Gradient Bar with Draggable Stops */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              p: 1,
              background: `linear-gradient(${angle}deg, ${gradientStops
                .map((stop) => `${stop.color} ${stop.offset}%`)
                .join(", ")})`,
              height: "30px",
              borderRadius: "5px",
              position: "relative",
            }}
          >
            {gradientStops.map((stop, index) => (
              <Box
                key={index}
                sx={{
                  position: "absolute",
                  left: `${stop.offset}%`,
                  transform: "translateX(-50%)",
                }}
              >
                <IconButton
                  size="small"
                  onClick={(event) => handleColorPickerOpen(event, index)}
                  sx={{
                    background: stop.color,
                    border: "2px solid white",
                    width: "16px",
                    height: "16px",
                  }}
                />
              </Box>
            ))}
          </Box>

          {/* Color Stops Controls */}
          {gradientStops.map((stop, index) => (
            <Grid
              container
              spacing={2}
              alignItems="center"
              key={index}
              sx={{ mt: 2 }}
            >
              <Grid item xs={6}>
                <Typography variant="body2">Offset: {stop.offset}%</Typography>
                <Slider
                  value={stop.offset}
                  min={0}
                  max={100}
                  step={1}
                  onChange={(e, value) => handleOffsetChange(index, value)}
                />
              </Grid>

              <Grid item xs={2}>
                <IconButton
                  onClick={() => removeColorStop(index)}
                  disabled={gradientStops.length <= 2}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          {/* Add Color Stop Button */}
          <IconButton
            onClick={addColorStop}
            disabled={gradientStops.length >= 5}
            sx={{ mt: 2 }}
          >
            <AddCircleOutlineIcon color="primary" />
          </IconButton>

          {/* Gradient Angle Input */}
          <TextField
            label="Angle"
            type="number"
            value={angle}
            onChange={(e) => setAngle(parseInt(e.target.value))}
            sx={{ mt: 2 }}
            InputProps={{
              endAdornment: <Typography>°</Typography>,
            }}
          />

          {/* Apply Gradient Button */}
          {/* <button
            onClick={applyGradient}
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#007BFF",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              width: "100%",
            }}
          >
            Apply Gradient
          </button> */}

          {/* Color Picker Popover */}
          <Popover
            open={Boolean(colorPickerAnchor)}
            anchorEl={colorPickerAnchor}
            onClose={handleColorPickerClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <SketchPicker
              color={
                selectedStopIndex !== null
                  ? gradientStops[selectedStopIndex].color
                  : "#ffffff"
              }
              onChange={handleColorChange}
            />
          </Popover>
        </>
      )}
    </Box>
  );
};

export default TextGradientComponent;
