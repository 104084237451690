import React from "react";
import Header from "../Header";
import { Box, Container, Stack, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Footer from "../Footer";
import { motion, AnimatePresence } from "framer-motion";
import { pageVariants, pageTransition } from "../Animations";
import { Helmet } from "react-helmet";
import MockupGenerator from "./MockupGenerator";
import ProductOptions from "./ProductOptions";
import ProductForm from "./ProductForm";
import EditProduct from "./EditProduct";

const EditProductPage = () => {
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [approvedProductImg, setApprovedProductImg] = React.useState([]);
  const [isDesignSizeCorrect, setIsDesignSizeCorrect] = React.useState(null);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        // backgroundColor: "orange",
      }}
    >
      <Helmet>
        <title>Publish Design | Okay Artist</title>
        <meta
          name="description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          name="keywords"
          content="about okay artist, custom designs, mission"
        />
        <meta property="og:title" content="About Us | Okay Artist" />
        <meta
          property="og:description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          property="og:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
        <meta property="og:url" content="https://www.okayartist.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us | Okay Artist" />
        <meta
          name="twitter:description"
          content="Learn more about Okay Artist and our mission to provide unique custom designs."
        />
        <meta
          name="twitter:image"
          content="https://product-images-okayartist.s3.amazonaws.com/generic-images/logo-for-meta-tags.jpg"
        />
      </Helmet>
      <Header />

      <Container
        id="container"
        sx={{
          //   backgroundColor: "red",

          mt: "5%",
          mb: "5%",
        }}
      >
        <EditProduct />
      </Container>

      <Footer></Footer>
    </Box>
  );
};

export default EditProductPage;
